export const prescription = {
    state: {
        prescriptions: [],
        patientPrescription: {
            DoktorID: {},
            HastaID: {},
            Oyunlar: [],
            ReceteTarihi: null,
            ReceteAciklama: null,
            AktifMi: null,
            PasifTarihi: null,
        },
        loading: false
    },
    reducers: {
        setLoading(state, loading) {
            return {
                ...state,
                loading: loading
            }
        },
        setPrescription(state, payload) {
            return {
                ...state,
                patientPrescription: {
                    ...state.patientPrescription,
                    ...payload
                }
            }
        },
        setPrescriptions(state, payload) {
            state.prescriptions = payload;
            return { ...state }
        },
        clearPrescriptions(state) {
            return {
                ...state,
                prescriptions: [],
                patientPrescription: {
                    DoktorID: {},
                    HastaID: {},
                    Oyunlar: [],
                    ReceteTarihi: null,
                    ReceteAciklama: null,
                    AktifMi: null,
                    PasifTarihi: null,
                },
                loading: false
            }
        },
    },
};