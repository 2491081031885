export const patient = {
    state: {
        patients: [],
        userPatient: {
            TCNo: "",
            Sifre: "",
            AdSoyad: null,
            DogumTarihi: null,
            Cinsiyet: null,
            Yakinlik: null,
            Adres: null,
            Telefon: null,
        },
        loading: false
    },
    reducers: {
        setTCNo(state, TCNo) {
            state.userPatient.TCNo = TCNo;
            return { ...state }
        },
        setSifre(state, Sifre) {
            state.userPatient.Sifre = Sifre;
            return { ...state }
        },
        setAdSoyad(state, AdSoyad) {
            state.userPatient.AdSoyad = AdSoyad;
            return { ...state }
        },
        setDogumTarihi(state, DogumTarihi) {
            state.userPatient.DogumTarihi = DogumTarihi;
            return { ...state }
        },
        setCinsiyet(state, Cinsiyet) {
            state.userPatient.Cinsiyet = Cinsiyet;
            return { ...state }
        },
        setYakinlik(state, Yakinlik) {
            state.userPatient.Yakinlik = Yakinlik;
            return { ...state }
        },
        setAdres(state, Adres) {
            state.userPatient.Adres = Adres;
            return { ...state }
        },
        setTelefon(state, Telefon) {
            state.userPatient.Telefon = Telefon;
            return { ...state }
        },
        setLoading(state, loading) {
            return {
                ...state,
                loading: loading
            }
        },
        setPatient(state, payload) {
            return {
                ...state,
                userPatient: {
                    ...state.userPatient,
                    ...payload
                }
            }
        },
        setPatients(state, payload) {
            state.patients = payload;
            return { ...state }
        },
        clearPatients(state) {
            return {
                ...state,
                patients: [],
                patient: null,
                loading: false
            }
        },
        clearPatient(state) {
            return {
                ...state,
                userPatient: {
                    TCNo: "",
                    Sifre: "",
                    AdSoyad: null,
                    DogumTarihi: null,
                    Cinsiyet: null,
                    Yakinlik: null,
                    Adres: null,
                    Telefon: null,
                },
            }
        },
    },
};