import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

/* Helpers */
import 'moment/locale/tr';

/* Services */
import AuthService from "../../services/auth.service";

/* Ant Design */
import { Spin, } from 'antd';

const ParentRegisterVerify = (props) => {
    const [loadingVerify, setLoadingVerify] = useState(false);

    const confirmVerify = (token) => {
        console.log(token);
        setLoadingVerify(true);
        AuthService.confirmParentVerify(token).then(
            res => {
                setLoadingVerify(false);
                toast.success(res.data.message, { autoClose: 3000 });
                toast.success("Yönlendiriliyorsunuz, Lütfen Bekleyiniz!", { autoClose: 3000 });
                setTimeout(() => {
                    props.history.push("/logins");
                }, 3000);
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                if (resMessage.includes("Yenilenen aktivasyon mailiniz")) {
                    setLoadingVerify(false);
                    toast.warning(resMessage, { autoClose: 3000 });
                    setTimeout(() => {
                        props.history.push("/");
                    }, 3000);
                } else {
                    setLoadingVerify(false);
                    toast.error(resMessage, { autoClose: 3000 });
                    setTimeout(() => {
                        props.history.push("/");
                    }, 3000);
                }
            }
        )
    }

    useEffect(() => {
        const query = new URLSearchParams(props.location.search);
        let token = query.get('token');

        setLoadingVerify(true);
        confirmVerify(token);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {loadingVerify &&
                <div style={{ textAlign: 'center' }}>
                    <Spin />
                </div>
            }
        </div>
    )
}


const mapState = (state) => ({
});

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(ParentRegisterVerify);