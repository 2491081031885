export default function authHeader() {
    const parent = JSON.parse(localStorage.getItem('parent'));
    const doctor = JSON.parse(localStorage.getItem('doctor'));

    if (parent && parent.accessToken) {
        return { 'x-access-token': parent.accessToken };
    }
    else if (doctor && doctor.accessToken){
        return { 'x-access-token': doctor.accessToken };
    }
    else {
        return {};
    }
}
