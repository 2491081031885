import React, { Component } from "react";
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import moment from 'moment';

/* for "axios" request cancelation */
import axios from 'axios';

/* Services */
import DoctorService from "../../../services/doctor.service";

/* Ant Design */
import { Typography, Table, Row, Col, Button, Space, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

/* for "axios" request cancelation check variable */
let source;

class myPatients extends Component {
    constructor(props) {
        super(props);
        this.getPatients = this.getPatients.bind(this);

        this.state = {
            patients: [],
            loadingPatients: false
        };
    }

    patientColumns = [
        /* TCNo */
        {
            title: 'T.C. Numarası',
            dataIndex: 'TCNo',
            key: 'TCNo',
            responsive: ["xs", "sm", "md", "lg"]
        },
        /* AdSoyad */
        {
            title: 'Ad - Soyad',
            dataIndex: 'AdSoyad',
            key: 'AdSoyad',
            responsive: ["xs", "sm", "md", "lg"]
        },
        /* Cinsiyet */
        {
            title: 'Cinsiyet',
            dataIndex: 'Cinsiyet',
            key: 'Cinsiyet',
            responsive: ["md", "lg"]
        },
        /* Age */
        {
            title: 'Yaş',
            dataIndex: 'Age',
            key: 'Age',
            render: (Age) => (
                ((Math.floor(Age / 12) !== 0) && (Math.floor(Age / 12) + " Yıl ")) + Math.floor(Age) % 12 + " Ay"
            ),
            responsive: ["lg"]
        },
        /* Ebeveyn AdSoyad */
        {
            title: 'Ebeveyn Ad - Soyad',
            dataIndex: 'EbeveynAdSoyad',
            key: 'EbeveynAdSoyad',
            responsive: ["md", "lg"]
        },
        /* Yakinlik */
        {
            title: 'Yakınlık',
            dataIndex: 'Yakinlik',
            key: 'Yakinlik',
            responsive: ["md", "lg"]
        },
        /* Telefon */
        {
            title: 'Telefon',
            dataIndex: 'Telefon',
            key: 'Telefon',
            render: (Telefon) => (
                Telefon = [Telefon.slice(0, 3), " ", Telefon.slice(3, 6), " ", Telefon.slice(6, 9), " ", Telefon.slice(9, 11), " ", Telefon.slice(11, 13)].join('')
            ),
            responsive: ["lg"]
        },
        /* Actions */
        {
            title: 'İşlemler',
            dataIndex: '',
            key: 'x',
            render: (text, record) => (
                <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
                    <Row gutter={{ xs: 32, sm: 24, md: 16, lg: 8 }}>
                        {/* Reçete Görüntüle */}
                        <Col className="gutter-row">
                            <Button
                                block
                                type="primary"
                                ghost
                                style={{ whiteSpace: "normal", height: 'auto', marginBottom: '10px' }}
                                onClick={
                                    () => {
                                        this.props.history.push("/doctor/patients/actions/my-patients/" + record.key + "/prescriptions"/* record.AdSoyad.replace(/\s/g, "+") */);
                                    }
                                }
                            >
                                Reçeteler
                            </Button>
                        </Col>
                        {/* Rapor Görüntüle */}
                        <Col className="gutter-row">
                            <Button
                                block
                                type="primary"
                                ghost
                                style={{ whiteSpace: "normal", height: 'auto', marginBottom: '10px' }}
                                onClick={
                                    () => {
                                        this.props.history.push("/doctor/patients/actions/my-patients/" + record.key + "/reports");
                                    }
                                }
                            >
                                Raporlar
                            </Button>
                        </Col>
                        {/* Hasta Aktivite Geçmişi Görünütle */}
                        <Col className="gutter-row">
                            <Button
                                block
                                type="primary"
                                ghost
                                style={{ whiteSpace: "normal", height: 'auto', marginBottom: '10px' }}
                                onClick={
                                    () => {
                                        this.props.history.push("/doctor/patients/actions/my-patients/" + record.key + "/activity-history");
                                    }
                                }
                            >
                                Aktivite Geçmişi
                            </Button>
                        </Col>
                        {/* Daha Fazla */}
                        <Col className="gutter-row">
                            <Dropdown
                                trigger={['click']}
                                overlay={
                                    <Menu>
                                        {/* Teşhis Görüntüle */}
                                        <Menu.Item key="2">
                                            <Button
                                                block
                                                type="primary"
                                                ghost
                                                style={{ whiteSpace: "normal", height: 'auto', marginBottom: '10px' }}
                                                onClick={
                                                    () => {
                                                        this.props.history.push("/doctor/patients/actions/my-patients/" + record.key + "/diagnosis");
                                                    }
                                                }
                                            >
                                                Teşhis
                                            </Button>
                                        </Menu.Item>
                                        {/* Hasta Görüşü Görüntüle */}
                                        <Menu.Item key="3">
                                            <Button
                                                block
                                                type="primary"
                                                ghost
                                                style={{ whiteSpace: "normal", height: 'auto', marginBottom: '10px' }}
                                                onClick={
                                                    () => {
                                                        this.props.history.push("/doctor/patients/actions/my-patients/" + record.key + "/opinions");
                                                    }
                                                }
                                            >
                                                Hasta Görüşleri
                                            </Button>
                                        </Menu.Item>
                                    </Menu>
                                }
                            >
                                <Button
                                    block
                                    type="primary"
                                    ghost
                                    style={{ whiteSpace: "normal", height: 'auto', marginBottom: '10px' }}
                                    onClick={e => e.preventDefault()}
                                >
                                    Daha Fazla <DownOutlined />
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>
                </Space>
            ),
            responsive: ["xs", "sm", "md", "lg"]
        },
    ];

    componentDidMount() {
        source = axios.CancelToken.source();

        this.setState({ loadingPatients: true });
        this.getPatients(source.token);
    }

    getPatients(cancelToken) {
        DoctorService.getPatients(cancelToken)
            .then(
                res => {
                    res.data?.forEach(patient => {
                        patient.key = patient._id;
                        patient.Age = moment().diff(patient.DogumTarihi, 'months', false);
                        patient.EbeveynAdSoyad = patient.EbeveynID?.AdSoyad;
                        patient.Telefon = "+90" + patient.EbeveynID?.Telefon;
                    });
                    this.setState({ patients: res.data, loadingPatients: false })
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (resMessage !== 'Cancel') {
                        toast.error(resMessage);
                        this.setState({ loadingPatients: false });
                    }
                }
            )
    }

    componentWillUnmount() {
        if (source) {
            source.cancel();
        }
    }

    render() {
        return (
            <div>
                <Typography.Title style={{ textAlign: "center" }}>Hastalarım</Typography.Title>
                {this.state.patients &&
                    <Typography.Title level={5} style={{ textAlign: 'end', marginTop: '1vh' }}><b>{this.state.patients.length}</b> Adet Kayda Ulaşıldı!</Typography.Title>
                }
                <Table
                    className='doctorPatientsTable'
                    columns={this.patientColumns}
                    dataSource={this.state.patients}
                    pagination={{ pageSize: 10, hideOnSinglePage: true }}
                    loading={this.state.loadingPatients}
                />
            </div>
        )
    }
}

const mapState = (state) => ({
    doctor: state.doctor,
});
const mapDispatch = (dispatch) => ({
    doctorDispatch: dispatch.doctor
});

export default connect(mapState, mapDispatch)(myPatients)