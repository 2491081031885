import authHeader from './auth-header';
import http from "../http-common";

class ParentService {
    /* api/parent */
    getParentBoard() {
        return http.get("/parent", { headers: authHeader() });
    }

    /* /patient/:_id */
    getParentPatient(_id, cancelToken) {
        return http.get("/parent/patient/" + _id, { headers: authHeader(), cancelToken: cancelToken });
    }

    /* /patients */
    getParentPatients(cancelToken) {
        return http.get("/parent/patients", { headers: authHeader(), cancelToken: cancelToken });
    }

    /* patient/delete/:_id */
    deleteParentPatient(_id) {
        return http.get("/parent/patient/delete/" + _id, { headers: authHeader() });
    }

    /* patient/:_id/doctor */
    getParentPatientDoctor(_id, cancelToken) {
        return http.get("/parent/patient/" + _id + "/doctor", { headers: authHeader(), cancelToken: cancelToken });
    }

    /* patient/:patientId/doctor/delete */
    doctorDelete(patientId) {
        return http.get("/parent/patient/" + patientId + "/doctor/delete", { headers: authHeader() });
    }

    /* patient/:patientId/prescriptions */
    getPatientPrescriptions(patientId, cancelToken) {
        return http.get("/parent/patient/" + patientId + "/prescriptions", { headers: authHeader(), cancelToken: cancelToken });
    }

    /* patient/prescription/:prescriptionId */
    getPrescription(prescriptionId, cancelToken) {
        return http.get("/parent/patient/prescription/" + prescriptionId, { headers: authHeader(), cancelToken: cancelToken });
    }

    /* patient/games */
    getParentGames(cancelToken) {
        return http.get("/parent/games", { headers: authHeader(), cancelToken: cancelToken });
    }

    /* patient/store/games */
    getParentUnpaidGames(query, cancelToken) {
        let sendQuery = "";
        query.page && (sendQuery += ('?page=' + query.page));
        query.limit && (sendQuery += ('&limit=' + query.limit));
        query.category && (sendQuery += ('&category=' + query.category));
        query.exerciseType && (sendQuery += ('&exerciseType=' + query.exerciseType));
        return http.get("/parent/store/games" + sendQuery, { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/patient/store/games/categories-and-exercise-types */
    getGamesCategoriesAndExerciseTypes(cancelToken) {
        return http.get("/parent/store/games/categories-and-exercise-types", { headers: authHeader(), cancelToken: cancelToken });
    }

    /* patient/store/game/:gameId/buy */
    buyGame(gameId) {
        return http.get("/parent/store/game/" + gameId + "/buy", { headers: authHeader() });
    }

    /* patient/game/:gameId/detail &&  patient/store/game/:gameId/detail */
    getGameDetail(gameId, cancelToken) {
        return http.get("/parent/game/" + gameId + "/detail", { headers: authHeader(), cancelToken: cancelToken });
    }

    /* patient/:patientId/requests */
    getpatientDoctorAssignReqs(patientId) {
        return http.get("/parent/patient/" + patientId + "/req", { headers: authHeader() });
    }

    /* patient/doctors */
    getDoctors(cancelToken) {
        return http.get("/parent/doctors", { headers: authHeader(), cancelToken: cancelToken });
    }

    /* patient/doctors */
    setPatientDoctor(patientId, doctorId) {
        return http.get("/parent/patient/" + patientId + "/doctor/" + doctorId, { headers: authHeader() });
    }

    /* patients/gamedatas */
    getPatientsGameDatas(page, limit, cancelToken) {
        return http.get("/parent/patients/gamedatas?page=" + page + "&limit=" + limit, { headers: authHeader(), cancelToken: cancelToken });
    }
    /* patients/gamedatas */
    getPatientsGameTimesData(patientId, cancelToken) {
        return http.get("/parent/patients/" + patientId + "/gametime", { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/parent/all-requests */
    getParentRequests(query, cancelToken) {
        let sendQuery = "";
        (query.search || query.type || query.page || query.limit) && (sendQuery += "?");
        query.search && (sendQuery += "&search=" + query.search);
        query.type && (sendQuery += "&type=" + query.type);
        query.page && (sendQuery += "&page=" + query.page);
        query.limit && (sendQuery += "&limit=" + query.limit);
        return http.get("/parent/all-requests" + sendQuery, { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/parent/all-requests/:requestId/confirm */
    parentConfirmReq(requestId, cancelToken) {
        return http.put("/parent/all-requests/" + requestId + "/confirm", {}, { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/parent/all-requests/:requestId/decline */
    parentDeclineReq(requestId, cancelToken) {
        return http.put("/parent/all-requests/" + requestId + "/decline", {}, { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/parent/all-requests/:requestId/markasread */
    parentMarkAsRead(requestId, cancelToken) {
        return http.put("/parent/all-requests/" + requestId + "/markasread", {}, { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/parent/profile */
    getParent(cancelToken) {
        return http.get("/parent/profile", { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/parent/patients-id-name */
    getPatientsIdName(cancelToken) {
        return http.get("/parent/patients-id-name", { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/parent/patients/:patientId/daily-play-game */
    getPatientDailyPlayGamesCount(patientId, range, cancelToken) {
        return http.get("/parent/patients/" + patientId + "/daily-play-game/" + range, { headers: authHeader(), cancelToken: cancelToken });
    }
}

export default new ParentService();
