import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

/* for "axios" request cancelation */
import axios from 'axios';

/* Helpers */
import 'moment/locale/tr';

/* Services */
import DoctorService from "../../../services/doctor.service";
import AuthService from "../../../services/auth.service";

/* Ant Design */
import { Spin, Button, Typography, Form, Input, Select } from 'antd';

/* for "axios" request cancelation check variable */
let source;

const DoctorProfileEdit = (props) => {
    const [doctor, setDoctor] = useState({});
    const [doctorWithoutId, setDoctorWithoutId] = useState({});
    const [loadingDoctor, setLoadingDoctor] = useState(false);
    const [loadingForm, setLoadingForm] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(true);

    const getDoctor = () => {
        setLoadingDoctor(true);
        DoctorService.getDoctor(source.token).then(
            res => {
                setDoctor({ ...res.data });
                delete res.data._id;
                delete res.data.KayitTarihi;
                delete res.data.AktifMi;
                setDoctorWithoutId({ ...res.data });
                setLoadingDoctor(false);
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                if (resMessage === "Cancel") {
                    setLoadingDoctor(false);
                } else {
                    setDoctor({});
                    setLoadingDoctor(false);
                    toast.error(resMessage);
                }
            }
        )
    }

    const onFormChange = (changedValues, values) => {
        JSON.stringify(values) === JSON.stringify(doctorWithoutId) ?
            setButtonDisable(true)
            :
            setButtonDisable(false);
        /*TODO --> Orjinal Data değiştiğinde eğer sayfadan çıkılmaya çalışılıyor ise modal ile 
                    "Kaydetmeden Çıkmak İstediğinize Emin Misiniz?" diye sorarak çıkış yapılmalıdır.
        */
    }

    const onFinish = (values) => {
        setLoadingForm(true);

        AuthService.doctorEdit(doctor._id, values)
            .then(
                (response) => {
                    getDoctor();
                    toast.success(response.data.message, { autoClose: 3000 });
                    setButtonDisable(true);
                    setLoadingForm(false);
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(resMessage);
                    setLoadingForm(false);
                }
            )
    }

    useEffect(() => {
        source = axios.CancelToken.source();

        setLoadingDoctor(true);
        getDoctor();

        return () => {
            if (source) {
                source.cancel();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {(!loadingDoctor && doctor) ?
                <div>
                    <div style={{ textAlign: 'center', marginBottom: '5vh' }}>
                        <Typography.Title level={4}>
                            {doctor ? doctor.AdSoyad + " Profili Düzenle" : "Profil Düzenle"}
                        </Typography.Title>
                    </div>
                    <Form
                        name="doctorEdit"
                        labelCol={{
                            xs: { offset: 0 },
                            sm: { offset: 0, span: 6 },
                            md: { offset: 1, span: 6 },
                            lg: { offset: 4, span: 4 },
                        }}
                        wrapperCol={{
                            xs: { offset: 0 },
                            sm: { offset: 0, span: 12 },
                            md: { offset: 0, span: 10 },
                            lg: { offset: 0, span: 8 },
                        }}
                        initialValues={doctor}
                        onFinish={onFinish}
                        onValuesChange={(changedValues, values) => onFormChange(changedValues, values)}
                        autoComplete="off"
                    >
                        {/* TCNo */}
                        <Form.Item
                            label="T.C. Numarası"
                            name="TCNo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Lütfen T.C. Kimlik Numaranızı Giriniz!',
                                },
                                {
                                    pattern: /^(?:\d*)$/,
                                    message: "Sadece Numara Girilmelidir!",
                                },
                                {
                                    pattern: /^[\d]{11,11}$/,
                                    message: "11 Numara Girilmelidir!",
                                },
                            ]}
                        >
                            <Input placeholder="Lütfen T.C. Kimlik Numaranızı Giriniz!"
                                maxLength={11}
                                disabled={true}
                            />
                        </Form.Item>

                        {/* AdSoyad */}
                        <Form.Item
                            label="Ad ve Soyad"
                            name="AdSoyad"
                            rules={[
                                {
                                    required: true,
                                    message: 'Lütfen Adınızı ve Soyadınızı Giriniz!',
                                    min: 4,
                                    max: 128,
                                },
                            ]}
                        >
                            <Input
                                placeholder="Lütfen Adınızı ve Soyadınızı Giriniz!"
                                maxLength={128}
                            />
                        </Form.Item>

                        {/* Cinsiyet */}
                        <Form.Item
                            label="Cinsiyet"
                            name="Cinsiyet"
                            rules={[
                                {
                                    required: true,
                                    message: 'Lütfen Cinsiyet Seçiniz!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Lütfen Cinsiyetinizi Seçiniz!"
                                allowClear
                            >
                                <Select.Option value="Kadın">Kadın</Select.Option>
                                <Select.Option value="Erkek">Erkek</Select.Option>
                                <Select.Option value="Belirtmek İstemiyorum">Belirtmek İstemiyorum</Select.Option>
                            </Select>
                        </Form.Item>

                        {/* Eposta */}
                        <Form.Item
                            label="E-Posta"
                            name="Eposta"
                            rules={[
                                {
                                    required: true,
                                    type: "email",
                                    message: 'Lütfen E-Postanızı Yazınız!',
                                },
                            ]}
                        >
                            <Input
                                placeholder="Lütfen E-Postanızı Yazınız!"
                                disabled={true}
                            />
                        </Form.Item>

                        {/* Adres */}
                        <Form.Item
                            label="Adres"
                            name="Adres"
                            rules={[
                                {
                                    required: true,
                                    message: 'Lütfen Adresinizi Giriniz!',
                                    min: 8,
                                    max: 256
                                },
                            ]}
                        >
                            <Input placeholder="Lütfen Adresinizi Giriniz!" />
                        </Form.Item>

                        {/* Telefon */}
                        <Form.Item
                            label="Telefon Numarası"
                        >
                            <Input.Group compact>
                                <Input
                                    style={{ width: '10%' }}
                                    defaultValue="+90"
                                    disabled
                                />
                                <Form.Item
                                    name="Telefon"
                                    rules={[
                                        {
                                            required: true,
                                            min: 10,
                                            max: 10,
                                            message: 'Lütfen Telefon Numaranızı Giriniz!',
                                        },
                                    ]}
                                >
                                    <Input style={{ width: '100%' }} placeholder="5554443322" maxLength={10} />
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>

                        {/* Submit */}
                        <Form.Item
                            wrapperCol={{
                                xs: { offset: 0 },
                                sm: { offset: 6, span: 12 },
                                md: { offset: 7, span: 10 },
                                lg: { offset: 8, span: 8 },
                            }}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                block
                                loading={loadingForm}
                                disabled={buttonDisable}
                            >
                                Kaydet
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                :
                <div style={{ textAlign: 'center' }}>
                    <Spin />
                </div>
            }
        </div >
    )
}


const mapState = (state) => ({
});

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(DoctorProfileEdit);