import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';

/* for "axios" request cancelation */
import axios from 'axios';

/* Helpers */
import 'moment/locale/tr';

/* Services */
import DoctorService from "../../../services/doctor.service";

/* Ant Design */
import { Spin, Descriptions, Badge, Typography, Button } from 'antd';

/* for "axios" request cancelation check variable */
let source;

const DoctorProfile = (props) => {
    const [doctor, setDoctor] = useState({});
    const [loadingDoctor, setLoadingDoctor] = useState(false);

    const getDoctor = () => {
        setLoadingDoctor(true);
        DoctorService.getDoctor(source.token).then(
            res => {
                setLoadingDoctor(false);
                res.data.Telefon && (res.data.Telefon = "+90" + res.data.Telefon);
                setDoctor(res.data);
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                if (resMessage === "Cancel") {
                    setLoadingDoctor(false);
                } else {
                    setDoctor({});
                    setLoadingDoctor(false);
                    toast.error(resMessage);
                }
            }
        )
    }

    useEffect(() => {
        source = axios.CancelToken.source();

        setLoadingDoctor(true);
        getDoctor();

        return () => {
            if (source) {
                source.cancel();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {!loadingDoctor ?
                <div>
                    <div style={{ textAlign: 'center' }}>
                        <Typography.Title level={4}>
                            {doctor ? doctor.AdSoyad + " Profili" : "Profil"}
                        </Typography.Title>
                    </div>
                    {doctor &&
                        <div style={{ textAlign: 'end' }}>
                            <Button
                                block
                                type="primary"
                                onClick={
                                    () => {
                                        props.history.push("/doctor/profile/" + doctor._id + "/edit");
                                    }
                                }
                                style={{ marginTop: '5vh', width: 200 }}
                            >
                                Düzenle
                            </Button>
                        </div>
                    }
                    <Descriptions
                        title="Kullanıcı Bilgileri"
                        layout="vertical"
                        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        bordered
                    >
                        <Descriptions.Item label="Ad - Soyad" span={1}>{doctor.AdSoyad}</Descriptions.Item>
                        <Descriptions.Item label="Cinsiyet" span={1}>{doctor.Cinsiyet}</Descriptions.Item>
                        <Descriptions.Item label="Şifre" span={1}>
                            <Button
                                onClick={
                                    () => {
                                        props.history.push("/doctor/profile/password");
                                    }
                                }
                                danger
                            >
                                Değiştir
                            </Button>
                        </Descriptions.Item>
                    </Descriptions>
                    <br />
                    <Descriptions
                        title="İletişim Bilgileri"
                        layout="vertical"
                        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        bordered
                    >
                        {doctor.Eposta &&
                            <Descriptions.Item label="E-Posta" span={1}>{doctor.Eposta}</Descriptions.Item>
                        }
                        {doctor.Adres &&
                            <Descriptions.Item label="Adres" span={1}>{doctor.Adres}</Descriptions.Item>
                        }
                        {doctor.Telefon &&
                            <Descriptions.Item label="Telefon" span={1}>{[doctor.Telefon.slice(0, 3), " ", doctor.Telefon.slice(3, 6), " ", doctor.Telefon.slice(6, 9), " ", doctor.Telefon.slice(9, 11), " ", doctor.Telefon.slice(11, 13)].join('')}</Descriptions.Item>
                        }
                    </Descriptions>
                    <br />
                    <Descriptions
                        title="Hesap Bilgileri"
                        layout="vertical"
                        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        bordered
                    >
                        <Descriptions.Item label="Kayıt Tarihi" span={1}>
                            {moment(new Date(doctor.KayitTarihi)).format('LL')}
                        </Descriptions.Item>
                        <Descriptions.Item label="Hesap Durumu" span={1}>
                            {doctor.AktifMi ?
                                <Badge status="success" text="Aktif" />
                                :
                                <Badge status="error" text="Pasif" />
                            }
                        </Descriptions.Item>
                    </Descriptions>
                </div>
                :
                <div style={{ textAlign: 'center' }}>
                    <Spin />
                </div>
            }
        </div >
    )
}


const mapState = (state) => ({
});

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(DoctorProfile);