import React, { Component } from "react";
import { connect } from "react-redux";
import moment from 'moment';
import Highlighter from "react-highlight-words";
import { toast } from 'react-toastify';

/* for "axios" request cancelation */
import axios from 'axios';

/* Helpers */
import { history } from '../../../helpers/history';

/* Services */
import ParentService from "../../../services/parent.service";

/* Ant Design */
import { Typography, Spin, Table, Button, Popconfirm, Menu, Dropdown, Row, Col, Input, Space } from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';

/* for "axios" request cancelation check variable */
let source;

class Home extends Component {

    constructor(props) {
        super(props);
        this.getPatients = this.getPatients.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

        history.listen((location) => {
            props.patientDispatch.clearPatient();
        });

        this.state = {
            filteredData: [],
        }
    }

    componentDidMount() {
        /* Component Mount olurken source'a token iletilir */
        source = axios.CancelToken.source();

        this.getPatients();
    }

    /* for Search */
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`${dataIndex} Arayın`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Ara
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Temizle
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filtrele
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    /* for Search */
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    /* for Search Reset */
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    patientColumns = [
        /* TCNo */
        {
            title: 'T.C. Numarası',
            dataIndex: 'TCNo',
            key: 'TCNo',
            ...this.getColumnSearchProps('TCNo'),
            responsive: ["xs", "sm", "md", "lg"]
        },
        /* AdSoyad */
        {
            title: 'Ad - Soyad',
            dataIndex: 'AdSoyad',
            key: 'AdSoyad',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.AdSoyad.localeCompare(b.AdSoyad),
            sortDirections: ['ascend', 'descend', 'ascend'],
            ...this.getColumnSearchProps('AdSoyad'),
            responsive: ["xs", "sm", "md", "lg"]
        },
        /* Cinsiyet */
        {
            title: 'Cinsiyet',
            dataIndex: 'Cinsiyet',
            key: 'Cinsiyet',
            filters: [
                {
                    text: 'Kadın',
                    value: 'Kadın',
                },
                {
                    text: 'Erkek',
                    value: 'Erkek',
                },
                {
                    text: 'Diğer',
                    value: 'Diğer',
                },
            ],
            onFilter: (value, record) => record.Cinsiyet.indexOf(value) === 0,
            responsive: ["md", "lg"]
        },
        /* DogumTarihi */
        {
            title: 'Doğum Tarihi',
            dataIndex: 'DogumTarihi',
            key: 'DogumTarihi',
            sorter: (a, b) => new Date(a.DogumTarihi) - new Date(b.DogumTarihi),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (DogumTarihi) => (
                moment(new Date(DogumTarihi)).format('LL')
            ),
            responsive: ["md", "lg"]
        },
        /* Age */
        {
            title: 'Yaş',
            dataIndex: 'Age',
            key: 'Age',
            sorter: (a, b) => a.Age - b.Age,
            render: (Age) => (
                ((Math.floor(Age / 12) !== 0) && (Math.floor(Age / 12) + " Yıl ")) + Math.floor(Age) % 12 + " Ay"
            ),
            sortDirections: ['ascend', 'descend', 'ascend'],
            responsive: ["lg"]
        },
        /* Telefon */
        {
            title: 'Telefon',
            dataIndex: 'Telefon',
            key: 'Telefon',
            render: (Telefon) => (
                Telefon = [Telefon.slice(0, 3), " ", Telefon.slice(3, 6), " ", Telefon.slice(6, 9), " ", Telefon.slice(9, 11), " ", Telefon.slice(11, 13)].join('')
            ),
            responsive: ["lg"]
        },
        /* Yakinlik */
        {
            title: 'Yakınlık',
            dataIndex: 'Yakinlik',
            key: 'Yakinlik',
            filters: [
                {
                    text: 'Anne',
                    value: 'Anne',
                },
                {
                    text: 'Baba',
                    value: 'Baba',
                },
                {
                    text: 'Kardeş',
                    value: 'Kardeş',
                },
                {
                    text: 'Diğer',
                    value: 'Diğer',
                },
            ],
            onFilter: (value, record) => record.Yakinlik.indexOf(value) === 0,
            responsive: ["md", "lg"]
        },
        /* KayitTarihi */
        {
            title: 'Kayıt Tarihi',
            dataIndex: 'KayitTarihi',
            key: 'KayitTarihi',
            sorter: (a, b) => new Date(a.KayitTarihi) - new Date(b.KayitTarihi),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (KayitTarihi) => (
                moment(new Date(KayitTarihi)).format('LL')
            ),
            responsive: ["lg"]
        },
        /* Actions */
        {
            title: 'İşlemler',
            dataIndex: '',
            key: 'x',
            render: (text, record) => (
                <Row gutter={{ xs: 32, sm: 24, md: 16, lg: 8 }}>
                    {/* Doktor Görüntüle && Ata */}
                    <Col className="gutter-row">
                        {
                            (record.hasOwnProperty('DoktorID') && record.DoktorID !== undefined && record.DoktorID !== null && record.DoktorID !== "") ?
                                /* Doktor Görüntüle */
                                <Button
                                    block
                                    type="primary"
                                    ghost
                                    style={{ whiteSpace: "normal", height: 'auto', marginBottom: '10px' }}
                                    onClick={
                                        () => {
                                            this.props.history.push("/parent/patients/" + record.key + "/doctor");
                                        }
                                    }
                                >
                                    Doktor
                                </Button>
                                :
                                /* Doktor Ata */
                                <Button
                                    block
                                    type="primary"
                                    ghost
                                    style={{ whiteSpace: "normal", height: 'auto', marginBottom: '10px' }}
                                    onClick={
                                        () => {
                                            this.props.history.push("/parent/patients/" + record.key + "/doctors");
                                        }
                                    }
                                    danger
                                >
                                    Doktor
                                </Button>
                        }
                    </Col>
                    {/* Reçete Görüntüle */}
                    <Col className="gutter-row">
                        < Button
                            block
                            type="primary"
                            ghost
                            style={{ whiteSpace: "normal", height: 'auto', marginBottom: '10px' }}
                            onClick={
                                () => {
                                    this.props.history.push("/parent/patients/" + record.key + "/prescriptions");
                                }
                            }
                        >
                            Reçeteler
                        </Button >
                    </Col>
                    {/* Daha Fazla */}
                    <Col className="gutter-row">
                        < Dropdown
                            trigger={['click']}
                            overlay={
                                <Menu>
                                    {/* Düzenle */}
                                    <Menu.Item key="1">
                                        <Button
                                            block
                                            type="primary"
                                            onClick={
                                                () => {
                                                    this.props.history.push("/parent/patients/" + record.key + "/edit");
                                                }
                                            }
                                        >
                                            Düzenle
                                        </Button>
                                    </Menu.Item>
                                    {/* Sil */}
                                    < Menu.Item key="2" >
                                        <Popconfirm title="Silmek istediğinize emin misiniz?" onConfirm={() => this.handleDelete(record.key)}>
                                            <Button type="primary" block danger>Sil</Button>
                                        </Popconfirm>
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Button
                                block
                                type="primary"
                                ghost
                                style={{ whiteSpace: "normal", height: 'auto', marginBottom: '10px' }}
                                onClick={e => e.preventDefault()}
                            >
                                Daha Fazla <DownOutlined />
                            </Button>
                        </Dropdown>
                    </Col>
                </Row>
            ),
            responsive: ["xs", "sm", "md", "lg"]
        },
    ];

    getPatients() {
        //Eski rematch state verilerini temizlemek için
        this.props.patientDispatch.clearPatients();
        this.props.patientDispatch.setLoading(true);

        ParentService.getParentPatients(source.token).then(
            (res) => {
                let age = null;
                //Tabloda her nesnenin bir key value olması gerekiyor. Bu yüzden gelen objenin id'sini key olarak atadım.
                const patients = res.data.map((patient) => {
                    age = moment().diff(patient.DogumTarihi, 'months', false);
                    return {
                        ...patient,
                        key: patient._id,
                        DogumTarihi: moment(patient.DogumTarihi).toString(),
                        KayitTarihi: moment(patient.KayitTarihi).toString(),
                        Age: age === 0 ? age + 1 : age,
                        Telefon: "+90" + patient.Telefon
                    }
                });
                this.props.patientDispatch.setPatients(patients);
                this.setState({ filteredData: patients })
                this.props.patientDispatch.setLoading(false);
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                if (resMessage !== 'Cancel') {
                    toast.error(resMessage);
                }
                this.props.patientDispatch.setLoading(false);
            }
        );
    }

    handleDelete(_id) {
        this.props.patientDispatch.setLoading(true);

        ParentService.deleteParentPatient(_id).then(
            (res) => {
                this.setState({
                    filteredData: this.state.filteredData.filter(item => item.key !== _id)
                });
                toast.success(res.data.message);
                this.props.patientDispatch.setLoading(false);
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                toast.error(resMessage);
                this.props.patientDispatch.setLoading(false);
            })
    }

    componentWillUnmount() {
        if (source) {
            source.cancel();
        }
    };

    render() {
        return (
            <div>
                <Typography.Title level={1} style={{ textAlign: "center" }}> Ebeveyni Olduğunuz Hastaların Listesi</Typography.Title>
                {/* Add Patient */}
                <div>
                    <Button
                        type="primary"
                        size="large"
                        style={{ float: 'right', marginBottom: 20 }}
                        onClick={() => {
                            this.props.history.push('/parent/patients/add');
                        }}>
                        + Ekle
                    </Button>
                </div>
                <div style={{ clear: 'both' }}></div>
                {/* Patients' Table */}
                {
                    !this.props.patient.loading ?
                        <div>
                            {(this.state.filteredData.length !== 0) && (
                                <Table
                                    className='parentPatientsTable'
                                    columns={this.patientColumns}
                                    dataSource={this.state.filteredData}
                                    pagination={{ pageSize: 10, hideOnSinglePage: true }}
                                />
                            )}
                        </div>
                        :
                        <div style={{ textAlign: 'center' }}>
                            <Spin />
                        </div>
                }
            </div>
        )
    }
}

const mapState = (state) => ({
    patient: state.patient
})

const mapDispatch = (dispatch) => ({
    patientDispatch: dispatch.patient,
})

export default connect(mapState, mapDispatch)(Home);