export const prescriptionDoctor = {
    state: {
        prescriptions: [],
        patientPrescription: {},
        patient: {},
        loading: false
    },
    reducers: {
        setLoading(state, loading) {
            return {
                ...state,
                loading: loading
            }
        },
        setPrescription(state, payload) {
            return {
                ...state,
                patientPrescription: {
                    ...state.patientPrescription,
                    ...payload
                }
            }
        },
        setPrescriptionPatient(state, payload) {
            return {
                ...state,
                patient: {
                    ...payload
                }
            }
        },
        setPrescriptions(state, payload) {
            state.prescriptions = payload;
            return { ...state }
        },
        clearPrescriptions(state) {
            return {
                ...state,
                prescriptions: [],
                patientPrescription: {},
                patient: {},
                loading: false
            }
        },
    },
};