import React from 'react';
import { Route } from 'react-router-dom';

/* Doctor's Components */
import doctorHome from '../components/doctor.components/home.component';
import searchPatients from '../components/doctor.components/patient.components/search.component';
import Actions from '../components/doctor.components/patient.components/actions.component';
import StoreGames from '../components/doctor.components/store.components/games.component/games.component';
import StoreGameDetail from '../components/doctor.components/store.components/games.component/detail.component';
import doctorPatientPrescriptions from '../components/doctor.components/patient.components/prescription.components/prescriptions.component';
import doctorPrescription from '../components/doctor.components/patient.components/prescription.components/prescription.component';
import doctorReqs from '../components/doctor.components/reqs.component';
import doctorFormerPatients from '../components/doctor.components/formerPatient.components/formerPatients.component';
import doctorFormerPatientPrescriptions from '../components/doctor.components/formerPatient.components/prescriptions.component.js';
import doctorFormerPatientPrescription from '../components/doctor.components/formerPatient.components/prescription.component.js';
import myPatients from '../components/doctor.components/patient.components/patients.component';
import doctorPatientOpinions from '../components/doctor.components/patient.components/opinion.components/opinions.component';
import doctorPatientOpinionAdd from '../components/doctor.components/patient.components/opinion.components/add';
import doctorPatientDiagnosis from '../components/doctor.components/patient.components/diagnose.components/diagnosis.component';
import doctorPatientDiagnoseAdd from '../components/doctor.components/patient.components/diagnose.components/add';
import addPrescription from '../components/doctor.components/patient.components/prescription.components/add';
import doctorPrescriptions from '../components/doctor.components/prescriptions/prescriptions';
import addPatient from '../components/doctor.components/patient.components/addPatient.component';
import doctorProfile from '../components/doctor.components/profile/profile';
import doctorProfileEdit from '../components/doctor.components/profile/edit';
import doctorProfilePassword from '../components/doctor.components/profile/password';
import doctorPatientActivityHistory from '../components/doctor.components/patient.components/activityHistory.component';

const DoktorRoutes = [
    <Route exact path="/doctor" component={doctorHome} key="doctorHome" />,
    // <Route exact path="/doctor/patients" component={doctorHome} key="doctorHome" />,
    <Route exact path="/doctor/patients/actions" component={Actions} key="Actions" />,
    <Route exact path="/doctor/patients/actions/search" component={searchPatients} key="searchPatients" />,
    <Route exact path="/doctor/patients/actions/add" component={addPatient} key="addPatient" />,

    /* Search Query ile Routing Yapılıyor */
    //Hastalarım
    <Route exact path="/doctor/patients/actions/my-patients" component={myPatients} key="myPatients" />,
    <Route exact path="/doctor/patients/actions/my-patients/:patientId/prescriptions" component={doctorPatientPrescriptions} key="doctorPatientPrescriptions" />,
    <Route exact path="/doctor/patients/actions/my-patients/:patientId/prescriptions/add" component={addPrescription} key="addPrescription" />,
    <Route exact path="/doctor/patients/actions/my-patients/:patientId/prescriptions/:prescriptionId" component={doctorPrescription} key="doctorPrescription" />,
    <Route exact path="/doctor/patients/actions/my-patients/:patientId/opinions" component={doctorPatientOpinions} key="doctorPatientOpinions" />,
    <Route exact path="/doctor/patients/actions/my-patients/:patientId/opinions/add" component={doctorPatientOpinionAdd} key="doctorPatientOpinionAdd" />,
    <Route exact path="/doctor/patients/actions/my-patients/:patientId/diagnosis" component={doctorPatientDiagnosis} key="doctorPatientDiagnosis" />,
    <Route exact path="/doctor/patients/actions/my-patients/:patientId/diagnosis/add" component={doctorPatientDiagnoseAdd} key="doctorPatientDiagnoseAdd" />,
    <Route exact path="/doctor/patients/actions/my-patients/:patientId/activity-history" component={doctorPatientActivityHistory} key="doctorPatientActivityHistory" />,

    //Eski Hastalarım
    <Route exact path="/doctor/patients/actions/my-old-patients" component={doctorFormerPatients} key="doctorFormerPatients" />,
    <Route exact path="/doctor/patients/actions/my-old-patients/:patientId/prescriptions" component={doctorFormerPatientPrescriptions} key="doctorFormerPatientPrescriptions" />,
    <Route exact path="/doctor/patients/actions/my-old-patients/:patientId/prescriptions/:prescriptionId" component={doctorFormerPatientPrescription} key="doctorFormerPatientPrescription" />,

    //Oyunlar
    <Route exact path="/doctor/store/games" component={StoreGames} key="StoreGames" />,
    <Route exact path="/doctor/store/games/detail" component={StoreGameDetail} key="StoreGameDetail" />,
    /* *** */

    // <Route exact path="/doctor/patients/actions/prescriptions/all" component={doctorHome} key="doctorHome" />,
    <Route exact path="/doctor/patients/actions/prescriptions/search" component={doctorPrescriptions} key="doctorPrescriptions" />,
    <Route exact path="/doctor/requests" component={doctorReqs} key="doctorReqs" />,
    <Route exact path="/doctor/profile" component={doctorProfile} key="doctorProfile" />,
    <Route exact path="/doctor/profile/:doctorId/edit" component={doctorProfileEdit} key="doctorProfileEdit" />,
    <Route exact path="/doctor/profile/password" component={doctorProfilePassword} key="doctorProfilePassword" />,
];

export default DoktorRoutes;