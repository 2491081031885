import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';
import moment from 'moment';
import Highlighter from "react-highlight-words";

/* for "axios" request cancelation */
import axios from 'axios';

/* Components */
import ModalComponent from '../../common.components/modal';

/* Helpers */
import 'moment/locale/tr';

/* Services */
import DoctorService from "../../../services/doctor.service";

/* Ant Design */
import { Typography, AutoComplete, Space, Table, Row, Col, Dropdown, Menu, Button, Popconfirm, Divider } from 'antd';
import { DownOutlined } from '@ant-design/icons';

/* for "axios" request cancelation check variable */
let source;

const SearchPatients = (props) => {
    const [patientLoading, setPatientLoading] = useState(false);
    const [userHasReqs, setUserHasReqs] = useState(null);

    const [searchInput, setSearchInput] = useState(null);
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchedDataSet, setSearchedDataSet] = useState([]);
    const [searchValue, setSearchValue] = useState(null);
    const [searchSelectedData, setSearchSelectedData] = useState([]);

    const [assignPatientLoading, setAssignPatientLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const patientColumns = [
        /* TCNo */
        {
            title: 'T.C. Numarası',
            dataIndex: 'TCNo',
            key: 'TCNo',
            responsive: ["xs", "sm", "md", "lg"]
        },
        /* AdSoyad */
        {
            title: 'Ad - Soyad',
            dataIndex: 'AdSoyad',
            key: 'AdSoyad',
            responsive: ["xs", "sm", "md", "lg"]
        },
        /* Cinsiyet */
        {
            title: 'Cinsiyet',
            dataIndex: 'Cinsiyet',
            key: 'Cinsiyet',
            responsive: ["md", "lg"]
        },
        /* Age */
        {
            title: 'Yaş',
            dataIndex: 'Age',
            render: (Age) => (
                ((Math.floor(Age / 12) !== 0) && (Math.floor(Age / 12) + " Yıl ")) + Math.floor(Age) % 12 + " Ay"
            ),
            key: 'Age',
            responsive: ["lg"]
        },
        /* Ebeveyn AdSoyad */
        {
            title: 'Ebeveyn Ad - Soyad',
            dataIndex: 'EbeveynAdSoyad',
            key: 'EbeveynAdSoyad',
            responsive: ["md", "lg"]
        },
        /* Yakinlik */
        {
            title: 'Yakınlık',
            dataIndex: 'Yakinlik',
            key: 'Yakinlik',
            responsive: ["md", "lg"]
        },
        /* Telefon */
        {
            title: 'Telefon',
            dataIndex: 'Telefon',
            key: 'Telefon',
            render: (Telefon) => (
                Telefon = [Telefon.slice(0, 3), " ", Telefon.slice(3, 6), " ", Telefon.slice(6, 9), " ", Telefon.slice(9, 11), " ", Telefon.slice(11, 13)].join('')
            ),
            responsive: ["lg"]
        },
        /* Actions */
        {
            title: 'İşlemler',
            dataIndex: '',
            key: 'x',
            render: (text, record) => (
                <div>
                    {record.DoktorID &&
                        <Row gutter={{ xs: 32, sm: 24, md: 16, lg: 8 }}>
                            {/* Reçete Görüntüle */}
                            <Col className="gutter-row">
                                < Button
                                    block
                                    type="primary"
                                    ghost
                                    style={{ whiteSpace: "normal", height: 'auto', marginBottom: '10px' }}
                                    onClick={
                                        () => {
                                            props.history.push("/doctor/patients/actions/my-patients/" + record.key + "/prescriptions");
                                        }
                                    }
                                >
                                    Reçeteler
                                </Button >
                            </Col>
                            {/* Rapor Görüntüle */}
                            <Col className="gutter-row">
                                < Button
                                    block
                                    type="primary"
                                    ghost
                                    style={{ whiteSpace: "normal", height: 'auto', marginBottom: '10px' }}
                                    onClick={
                                        () => {
                                            props.history.push("/doctor/patients/actions/my-patients/" + record.key + "/reports");
                                        }
                                    }
                                >
                                    Raporlar
                                </Button >
                            </Col>
                            {/* Daha Fazla */}
                            <Col className="gutter-row">
                                < Dropdown
                                    trigger={['click']}
                                    overlay={
                                        <Menu>
                                            {/* Hasta İlişki Sil */}
                                            < Menu.Item key="2" >
                                                <Popconfirm title="Silmek istediğinize emin misiniz?" onConfirm={() => handleDelete(record.key)}>
                                                    <Button type="primary" block danger>Hasta İlişiğini Kes!</Button>
                                                </Popconfirm>
                                            </Menu.Item>
                                        </Menu>
                                    }
                                >
                                    <Button
                                        block
                                        type="primary"
                                        ghost
                                        style={{ whiteSpace: "normal", height: 'auto', marginBottom: '10px' }}
                                        onClick={e => e.preventDefault()}
                                    >
                                        Daha Fazla <DownOutlined />
                                    </Button>
                                </Dropdown>
                            </Col>
                        </Row>
                    }
                    {((typeof record.DoktorID === undefined) || (record.DoktorID === null) || (record.DoktorID === '') || !record.DoktorID) &&
                        <Row gutter={{ xs: 32, sm: 24, md: 16, lg: 8 }}>
                            {/* Bağlantı Ata */}
                            <Col className="gutter-row">
                                <Button
                                    block
                                    type="primary"
                                    ghost
                                    style={{ whiteSpace: "normal", height: 'auto', marginBottom: '10px' }}
                                    onClick={() => setVisible(record.key)}
                                    loading={assignPatientLoading === record.key}
                                    disabled={assignPatientLoading || (userHasReqs === record.key)}
                                >
                                    Doktoru Ol
                                </Button>
                            </Col>
                        </Row>
                    }
                </div>
            ),
            responsive: ["xs", "sm", "md", "lg"]
        },
    ];


    useEffect(() => {
        source = axios.CancelToken.source();

        const query = new URLSearchParams(props.location.search);
        const patientId = query.get('q');
        if (patientId) {
            getPatient(patientId, source.token);
        }

        return () => {
            if (source) {
                source.cancel();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPatient = (patientId, cancelToken) => {
        DoctorService.getPatient(patientId, cancelToken)
            .then(
                res => {
                    let arr = [];
                    let modifiedPatient = res.data;

                    modifiedPatient.key = res.data._id;
                    modifiedPatient.Age = moment().diff(res.data.DogumTarihi, 'months', false);
                    modifiedPatient.Yakinlik = res.data.Yakinlik;
                    modifiedPatient.EbeveynAdSoyad = res.data.EbeveynID.AdSoyad;
                    modifiedPatient.Telefon = "+90" + res.data.EbeveynID.Telefon;

                    arr.push(modifiedPatient);

                    if (res.data.DoktorID === null || !("DoktorID" in res.data)) {
                        const promise2 = new Promise((resolve, reject) => {
                            DoctorService.getpatientDoctorAssignReqs(modifiedPatient._id).then(
                                res => {
                                    resolve(setUserHasReqs(modifiedPatient._id));
                                },
                                err => {
                                    resolve(setUserHasReqs(null));
                                }
                            )
                        });

                        Promise
                            .all([promise2])
                            .then(() => {
                                setSearchSelectedData(arr);
                                setPatientLoading(false);
                            });
                    } else {
                        setSearchSelectedData(arr);
                        setPatientLoading(false);
                    }
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (resMessage !== 'Cancel') {
                        toast.error(resMessage);
                    }
                    setPatientLoading(true);
                }
            )
    }

    /* for AutoComplete */
    const onSelect = (option) => {
        setPatientLoading(true);

        props.history.push({
            pathname: '',
            search: '?q=' + option.value
        });

        getPatient(option.value, source.token);
    };

    /* for AutoComplete */
    const onSearch = (searchText) => {
        setSearchInput(searchText);
        if (searchText.length >= 3) {
            setSearchLoading(true);

            searchText = searchText?.replace(/^i/gi, 'İ');
            searchText = searchText?.replace(/\si/gi, ' İ');
            searchText = searchText?.replace(" ", "+");

            DoctorService.getFilteredPatients(searchText, source.token)
                .then(
                    res => {
                        if (res.data.length >= 1) {
                            let modifyData = res.data.map(patient => {
                                return {
                                    label:
                                        <p>
                                            <b>Ad - Soyad:&nbsp;</b>
                                            <Highlighter
                                                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                                                searchWords={[searchText.replace('+', ' ')]}
                                                autoEscape
                                                textToHighlight={patient.AdSoyad ? patient.AdSoyad.toString() : ''}
                                            />&nbsp;-&nbsp;
                                            <b>T.C. No:&nbsp;</b>
                                            <Highlighter
                                                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                                                searchWords={[searchText.replace('+', ' ')]}
                                                autoEscape
                                                textToHighlight={patient.TCNo ? patient.TCNo.toString() : ''}
                                            />
                                        </p>,
                                    value: patient._id,
                                    adsoyad: patient.AdSoyad
                                }
                            })
                            setSearchedDataSet(modifyData);
                            setSearchLoading(false);
                        }
                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        if ((resMessage !== 'Hasta Bulunamadı!') && (resMessage !== 'Cancel')) {
                            toast.error(resMessage);
                        }
                        setSearchedDataSet([]);
                        setSearchLoading(false);
                    }
                )
        }
        else {
            setSearchedDataSet([]);
            setSearchLoading(false);
        }
    };

    /* for AutoComplete */
    const onChange = (option) => {
        setSearchValue(option);
    };

    /* for Modal */
    const callMyMethod = (patientId) => {
        setModalVisible(false);
        setAssignPatientLoading(patientId);
        assignDoctor(patientId);
    }

    /* for Modal */
    const setVisible = (payload) => {
        setModalVisible(payload);
    }

    const assignDoctor = (patientId) => {
        DoctorService.setDoctorPatient(patientId).then(
            res => {
                toast.success(res.data.message);

                setAssignPatientLoading(false);
                setUserHasReqs(patientId);
                setModalVisible(false);
            },
            err => {
                const resMessage =
                    (err.response &&
                        err.response.data &&
                        err.response.data.message) ||
                    err.message ||
                    err.toString();

                toast.error(resMessage);
                setAssignPatientLoading(false);
            }
        )
    }

    const handleDelete = (patientId) => {
        DoctorService.deleteDoctorPatient(patientId).then(
            (res) => {
                setSearchValue(null);
                setSearchedDataSet([]);
                setSearchSelectedData([]);
                props.history.push({
                    pathname: ''
                });

                toast.success(res.data.message);
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                toast.error(resMessage);
            })
    }

    return (
        <div>
            <Typography.Title level={1} style={{ textAlign: "center" }}>Hasta Arama</Typography.Title>
            <Space direction="horizontal" align="center" style={{ width: '100%', justifyContent: 'center' }}>
                <AutoComplete
                    mode="tags"
                    value={searchValue && searchValue?.adsoyad}
                    options={searchedDataSet}
                    onSelect={(val, option) => onSelect(option)}
                    onSearch={debounce(onSearch, 300)}
                    onChange={(val, option) => onChange(option)}
                    style={{
                        width: 400,
                    }}
                    notFoundContent={
                        searchLoading ?
                            <Typography.Text style={{ color: '#1890ff' }}>Aranıyor!</Typography.Text>
                            :
                            (searchValue && searchInput?.length >= 3 && <Typography.Text style={{ color: '#f5222d' }}><b>Hasta Bulunamadı!</b></Typography.Text>)
                    }
                    placeholder="İsim veya T.C. Kimlik Numarası Giriniz..."
                    size='large'
                    loading={searchLoading}
                    defaultActiveFirstOption={true}
                    autoFocus={true}
                    allowClear
                />
            </Space>
            {((searchSelectedData.length > 0) || patientLoading) &&
                <div>
                    <Divider />
                    <Table
                        className='doctorPatientSearchTable'
                        columns={patientColumns}
                        dataSource={searchSelectedData}
                        pagination={{ pageSize: 10, hideOnSinglePage: true }}
                        loading={patientLoading}
                    />
                    {modalVisible !== false &&
                        <ModalComponent
                            titleText="Doktor Atama İşlemi"
                            bodyText="Doktor atama talebiniz, ebeveyn onayına sunulacaktır. Devam etmek istiyor musunuz?"
                            visible={modalVisible}
                            callMethod={callMyMethod}
                            setVisible={setVisible}
                        />
                    }
                </div>
            }
        </div>
    )
}

const mapState = (state) => ({
});

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(SearchPatients);