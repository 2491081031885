import React, { Component } from "react";
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import moment from 'moment';

/* for "axios" request cancelation */
import axios from 'axios';

/* Services */
import DoctorService from "../../../services/doctor.service";

/* Ant Design */
import { Typography, Table, Row, Col, Button } from 'antd';

/* for "axios" request cancelation check variable */
let source;

class FormerPatients extends Component {
    constructor(props) {
        super(props);
        this.getFormerPatients = this.getFormerPatients.bind(this);

        this.state = {
            formerPatients: [],
            patientsLoading: false,
        };
    }

    componentDidMount() {
        source = axios.CancelToken.source();

        this.getFormerPatients(source.token);
    }

    getFormerPatients(cancelToken) {
        this.setState({ patientsLoading: true });
        DoctorService.getFormerPatients(cancelToken)
            .then(
                res => {
                    let addedPatients = [];
                    let newPatient = {};
                    res.data.forEach(patient => {
                        newPatient = [];
                        newPatient.key = patient.HastaID._id;
                        newPatient.TCNo = patient.HastaID.TCNo;
                        newPatient.AdSoyad = patient.HastaID.AdSoyad;
                        newPatient.Cinsiyet = patient.HastaID.Cinsiyet;
                        newPatient.Age = moment().diff(patient.HastaID.DogumTarihi, 'months', false);
                        newPatient.Telefon = "+90" + patient.HastaID.Telefon;
                        newPatient.Yakinlik = patient.HastaID.Yakinlik;
                        newPatient.EbeveynAdSoyad = patient.HastaID.EbeveynID.AdSoyad;
                        addedPatients.push(newPatient);
                    });
                    this.setState({
                        formerPatients: addedPatients,
                        patientsLoading: false
                    });
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (resMessage !== 'Cancel') {
                        toast.error(resMessage, { autoClose: 2000 });
                    }
                    this.clearTimer = setTimeout(() => {
                        this.props.history.goBack();
                    }, 2000);
                }
            )
    }

    formerPatientsColumns = [
        /* TCNo */
        {
            title: 'T.C. Numarası',
            dataIndex: 'TCNo',
            key: 'TCNo',
            responsive: ["xs", "sm", "md", "lg"]
        },
        /* AdSoyad */
        {
            title: 'Ad - Soyad',
            dataIndex: 'AdSoyad',
            key: 'AdSoyad',
            responsive: ["xs", "sm", "md", "lg"]
        },
        /* Cinsiyet */
        {
            title: 'Cinsiyet',
            dataIndex: 'Cinsiyet',
            key: 'Cinsiyet',
            responsive: ["md", "lg"]
        },
        /* Age */
        {
            title: 'Yaş',
            dataIndex: 'Age', render: (Age) => (
                ((Math.floor(Age / 12) !== 0) && (Math.floor(Age / 12) + " Yıl ")) + Math.floor(Age) % 12 + " Ay"
            ),
            key: 'Age',
            responsive: ["lg"]
        },
        /* Ebeveyn AdSoyad */
        {
            title: 'Ebeveyn Ad - Soyad',
            dataIndex: 'EbeveynAdSoyad',
            key: 'EbeveynAdSoyad',
            responsive: ["md", "lg"]
        },
        /* Yakinlik */
        {
            title: 'Yakınlık',
            dataIndex: 'Yakinlik',
            key: 'Yakinlik',
            responsive: ["md", "lg"]
        },
        /* Telefon */
        {
            title: 'Telefon',
            dataIndex: 'Telefon',
            key: 'Telefon',
            render: (Telefon) => (
                Telefon = [Telefon.slice(0, 3), " ", Telefon.slice(3, 6), " ", Telefon.slice(6, 9), " ", Telefon.slice(9, 11), " ", Telefon.slice(11, 13)].join('')
            ),
            responsive: ["lg"]
        },
        /* Actions */
        {
            title: 'İşlemler',
            dataIndex: '',
            key: 'x',
            render: (text, record) => (
                <div>
                    <Row gutter={{ xs: 32, sm: 24, md: 16, lg: 8 }}>
                        {/* Reçete Görüntüle */}
                        <Col className="gutter-row">
                            <Button
                                block
                                type="primary"
                                ghost
                                style={{ whiteSpace: "normal", height: 'auto', marginBottom: '10px' }}
                                onClick={
                                    () => {
                                        this.props.history.push("/doctor/patients/actions/my-old-patients/" + record.key + "/prescriptions");
                                    }
                                }
                            >
                                Reçeteler
                            </Button>
                        </Col>
                        {/* Rapor Görüntüle */}
                        <Col className="gutter-row">
                            <Button
                                block
                                type="primary"
                                ghost
                                style={{ whiteSpace: "normal", height: 'auto', marginBottom: '10px' }}
                                onClick={
                                    () => {
                                        this.props.history.push("/doctor/patients/actions/my-old-patients/" + record.key + "/reports");
                                    }
                                }
                            >
                                Raporlar
                            </Button>
                        </Col>
                    </Row>
                </div>
            ),
            responsive: ["xs", "sm", "md", "lg"]
        },
    ];

    componentWillUnmount() {
        if (source) {
            source.cancel();
        }
    }

    render() {
        return (
            <div>
                <Typography.Title level={1} style={{ textAlign: "center" }}>Eski Hastalarım</Typography.Title>
                <div>
                    <Table
                        className='doctorFormerPatientsTable'
                        columns={this.formerPatientsColumns}
                        dataSource={this.state.formerPatients}
                        pagination={{ pageSize: 10, hideOnSinglePage: true }}
                        loading={this.state.patientsLoading}
                    />
                </div>
            </div>
        );
    }
}

const mapState = (state) => ({
    doctor: state.doctor,
});
const mapDispatch = (dispatch) => ({
    doctorDispatch: dispatch.doctor
});

export default connect(mapState, mapDispatch)(FormerPatients)