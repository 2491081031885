import React, { useState, useEffect } from "react";

import { Typography, Modal } from 'antd';


const ModalComponent = ({ titleText, bodyText, visible, setVisible, callMethod }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [recordId, setRecordId] = useState(null);

    useEffect(() => {
        if (visible === false) {
            setModalVisible(false);
        } else if (visible === true) {
            setModalVisible(true);
        } else if (visible !== false && visible !== true) {
            setModalVisible(true);
            setRecordId(visible);
        }
    }, [visible]);

    const handleOk = (recordId) => {
        setConfirmLoading(true);
        callMethod(recordId);
    }

    const handleCancel = () => {
        setModalVisible(false);
        setVisible(false);
        setRecordId(null);
    }


    return (
        <Modal
            title={
                <div>
                    <Typography.Text type="success">{titleText}</Typography.Text>
                </div>
            }
            visible={modalVisible}
            onOk={(e) => handleOk(recordId)}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
        >
            <p>{bodyText}</p>
        </Modal>
    )
}

export default ModalComponent;