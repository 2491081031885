/* Services */
import AuthService from '../services/auth.service'

let routes = [];

if (AuthService.getCurrentParent()) {
    routes = [
        /* Ebeveyn */
        { path: "/parent", breadcrumb: "Anasayfa" },
        { path: "/parent/profile", breadcrumb: "Ebeveyn Profili" },
        { path: "/parent/patients", breadcrumb: "Hastalar" },
        { path: "/parent/patients/add", breadcrumb: "Ekle" },
        { path: "/parent/patients/gamedatas", breadcrumb: "Oyun Verileri" },
        { path: "/parent/patients/prescriptions", breadcrumb: null },
        { path: "/parent/patients/prescriptions/:prescriptionId", breadcrumb: "Hastaya ait Reçete" },
        { path: "/parent/patients/:_id", breadcrumb: null },
        { path: "/parent/patients/:_id/edit", breadcrumb: "Düzenle" },
        { path: "/parent/patients/:patientId", breadcrumb: null },
        { path: "/parent/patients/:patientId/doctor", breadcrumb: "Doktor" },
        { path: "/parent/patients/:patientId/doctors", breadcrumb: "Doktorlar" },
        { path: "/parent/patients/:patientId/prescriptions", breadcrumb: "Hastaya ait Reçeteler" },
        { path: "/parent/games", breadcrumb: "Oyunlarım" },
        { path: "/parent/games/:gameId", breadcrumb: null },
        { path: "/parent/games/:gameId/detail", breadcrumb: "Oyun Detayı" },
        { path: "/parent/store/games", breadcrumb: "Oyunlar" },
        { path: "/parent/store/games/:gameId", breadcrumb: null },
        { path: "/parent/store/games/:gameId/detail", breadcrumb: "Oyun Detayı" },
        { path: "/parent/store/eyetrackers", breadcrumb: "Göz Takip Cihazları" },
        { path: "/parent/store/eyetrackers/:eyeTrackerId", breadcrumb: null },
        { path: "/parent/store/eyetrackers/:eyeTrackerId/detail", breadcrumb: "Detay" },
        { path: "/parent/requests", breadcrumb: "Talepler" },
    ]
}

else if (AuthService.getCurrentDoctor()) {
    routes = [
        /* Doktor */
        { path: "/doctor", breadcrumb: "Anasayfa" },
        { path: "/doctor/patients/actions", breadcrumb: "Hasta İşlemleri" },
        { path: "/doctor/patients/actions/search", breadcrumb: "Arama" },
        { path: "/doctor/patients/actions/my-patients", breadcrumb: "Hastalarım" },
        { path: "/doctor/patients/actions/my-old-patients", breadcrumb: "Eski Hastalarım" },
        { path: "/doctor/patients/actions/prescriptions/search", breadcrumb: "Reçete Ara" },
        { path: "/doctor/patients/actions/add", breadcrumb: "Yeni Hasta Ekle" },

        { path: "/doctor/requests", breadcrumb: "Talepler" },
        { path: "/doctor/games", breadcrumb: "Oyunlar" },
        { path: "/doctor/profile", breadcrumb: "Doktor Profili" },
        { path: "/doctor/store", breadcrumb: null },
        { path: "/doctor/store/games", breadcrumb: "Oyunlar" },
    ]
}

else {
    routes = [
        /* Ebeveyn */
        { path: "/", breadcrumb: null }
    ]
}

export default routes;