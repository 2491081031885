import React, { Component } from 'react';
import { Switch, Link, withRouter, Route } from "react-router-dom";

/* for Notifications */
import { ToastContainer } from 'react-toastify';

/* Breadcrumbs */
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import routes from './routes/breadcrumb'

/* for NavBar Items Routing Highlights */
import PropTypes from 'prop-types';

/* CSS */
import 'antd/dist/antd.min.css';
import './assets/css/custom.css';
import 'react-toastify/dist/ReactToastify.css';

/* Services */
import AuthService from './services/auth.service'

/* Common Components */
import CommonRoutes from './routes/common.routes';

/* Parent's Routes */
import EbeveynRoutes from './routes/ebeveyn.routes';

/* Doctor's Components */
import DoktorRoutes from './routes/doktor.routes';

/* Helper's Components */
import page404 from './components/helper.components/page404.component';

/* Ant Design */
import { Layout, Menu, Typography, Breadcrumb } from 'antd';
import { AppstoreOutlined, DownOutlined, HomeOutlined } from '@ant-design/icons';
import MainframeComponent from './components/helper.components/mainframe.component';

const { Header, Content, Footer } = Layout;

/* Breadcrumbs with Routes */
const Breadcrumbs = withBreadcrumbs(routes, { disableDefaults: true })(({ breadcrumbs }) => (
  <Breadcrumb style={{ marginTop: '16px', marginBottom: '0' }} separator=">">
    {breadcrumbs.map(({ match, breadcrumb }) =>
      <Breadcrumb.Item key={breadcrumb}>{breadcrumb.props.children === 'Anasayfa' && <HomeOutlined />}<Link to={match.url}>{breadcrumb}</Link></Breadcrumb.Item>
    )}
  </Breadcrumb>
));

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      currentParent: undefined,
      currentDoctor: undefined
    };
  }

  componentDidMount() {
    const parent = AuthService.getCurrentParent();
    const doctor = AuthService.getCurrentDoctor();

    if (parent) {
      this.setState({
        currentParent: parent
      });
    }
    else if (doctor) {
      this.setState({
        currentDoctor: doctor
      })
    }
  }

  logOut() {
    AuthService.logout();
    this.setState({
      currentParent: undefined,
      currentDoctor: undefined,
    });
  }

  /* for NavBar Items Routing Highlights */
  static propTypes = {
    location: PropTypes.object.isRequired
  }

  render() {
    const { currentParent, currentDoctor } = this.state;
    const { location } = this.props;
    let pathArray;
    let thatPath;

    /* Routing Highlight */
    const getMatchedKey = (location) => {
      if (location.pathname) {
        pathArray = location.pathname.split('/');
        /* specified for Parents */
        if (pathArray[1] === 'parent') {
          thatPath = '/' + pathArray[1];
          if (pathArray[2]) {
            thatPath += '/' + pathArray[2]
            if (pathArray[3] === 'gamedatas') {
              thatPath += '/' + pathArray[3];
            }
          }
          return thatPath
        }
        /* specified for Doctors */
        else if (pathArray[1] === 'doctor') {
          return location.pathname
        }
        /* specified for All Users */
        else {
          return location.pathname
        }
      }
    }

    return (
      <Layout style={{ minHeight: "100vh" }}>
        {/* for All Pages' Notifications */}
        <ToastContainer
          autoClose={5000}
          theme="colored"
          position="top-center"
          hideProgressBar={false}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
          progress={undefined}
        />
        {/* Navbar Header for User Types */}
        <Header>
          <div className="logo"></div>
          {/* Navbar for All */}
          {!currentParent && !currentDoctor && (
            <Menu theme="dark" mode="horizontal" selectedKeys={[getMatchedKey(location)]}>
              {/* Homepage for All */}
              <Menu.Item key="/">
                <Link to={"/"}>
                  Anasayfa
                </Link>
              </Menu.Item>
              {/* Login for Parent/Doctor */}
              <Menu.Item key="/logins" style={{ marginLeft: 'auto' }}>   {/* Giriş butonunu marginLeft ile sağa yaslayınca, sağında bulunan Kayıt butonu da otomatik sağa yaslanmış oldu */}
                <Link to={"/logins"}>
                  Giriş
                </Link>
              </Menu.Item>
              {/* Register for Parent/Doctor */}
              <Menu.Item key="/registers">
                <Link to={"/registers"}>
                  Kayıt
                </Link>
              </Menu.Item>
            </Menu>
          )}
          {/* Navbar for Parent */}
          {currentParent && !currentDoctor && (
            <Menu theme="dark" mode="horizontal" selectedKeys={[getMatchedKey(location)]}>
              {/* Anasayfa */}
              <Menu.Item key="/parent">
                <Link to={"/parent"}>
                  Anasayfa
                </Link>
              </Menu.Item>
              {/* Hasta */}
              <Menu.Item key="/parent/patients">
                <Link to={"/parent/patients"}>
                  Hasta
                </Link>
              </Menu.Item>
              {/* Oyunlarım */}
              <Menu.Item key="/parent/games">
                <Link to={"/parent/games"}>
                  Oyunlarım
                </Link>
              </Menu.Item>
              {/* Magaza */}
              <Menu.SubMenu key="/parent/store" title="Mağaza" icon={<AppstoreOutlined />}>
                <Menu.Item key="/parent/store/games">
                  <Link to={"/parent/store/games"}>
                    Oyunlar
                  </Link>
                </Menu.Item>
                <Menu.Item key="/parent/store/eyetrackers">
                  <Link to={"/parent/store/eyetrackers"}>
                    Göz Takip Cihazları
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>
              {/* Talepler */}
              <Menu.Item key="/parent/requests">
                <Link to={"/parent/requests"}>
                  Talepler
                </Link>
              </Menu.Item>
              {/* Hastalara ait Göz Verileri */}
              <Menu.Item key="/parent/patients/gamedatas">
                <Link to={"/parent/patients/gamedatas"}>
                  Veriler
                </Link>
              </Menu.Item>
              {/* Çıkış */}
              <Menu.SubMenu key="/parent/profile/menu" title={<Typography.Text style={{ color: 'rgba(255, 255, 255, 0.65)' }}>{currentParent.AdSoyad}&nbsp;<DownOutlined style={{ fontSize: '80%' }} /></Typography.Text>} style={{ marginLeft: 'auto' }}>
                <Menu.Item key="/parent/profile">
                  <Link to={"/parent/profile"}>
                    Profil
                  </Link>
                </Menu.Item>
                <Menu.Item key="/logout" style={{ marginLeft: 'auto' }}>
                  <Link to={"/"} onClick={this.logOut}>
                    Çıkış
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>
            </Menu>
          )}
          {/* Navbar for Doctor */}
          {!currentParent && currentDoctor && (
            <Menu theme="dark" mode="horizontal" selectedKeys={[getMatchedKey(location)]}>
              {/* Homepage for Doctor */}
              <Menu.Item key="/doctor">
                <Link to={"/doctor"}>
                  Anasayfa
                </Link>
              </Menu.Item>
              {/* Patient's Actions for Doctor */}
              <Menu.SubMenu key="/doctor/patients" onTitleClick={(e) => { this.props.history.push('/doctor/patients/actions') }} title={<Typography.Text style={{ color: 'rgba(255, 255, 255, 0.65)' }}>Hasta İşlemleri&nbsp;<DownOutlined style={{ fontSize: '80%' }} /></Typography.Text>}>
                <Menu.Item key="/doctor/patients/actions/search">
                  <Link to={"/doctor/patients/actions/search"}>
                    Hasta Arama
                  </Link>
                </Menu.Item>
                <Menu.Item key="/doctor/patients/actions/add">
                  <Link to={"/doctor/patients/actions/add"}>
                    Yeni Hasta Ekle
                  </Link>
                </Menu.Item>
                <Menu.Item key="/doctor/patients/actions/my-patients">
                  <Link to={"/doctor/patients/actions/my-patients"}>
                    Mevcut Hastalarım
                  </Link>
                </Menu.Item>
                <Menu.Item key="/doctor/patients/actions/my-old-patients">
                  <Link to={"/doctor/patients/actions/my-old-patients"}>
                    Eski Hastalarım
                  </Link>
                </Menu.Item>
                <Menu.Item key="/doctor/patients/actions/prescriptions/search">
                  <Link to={"/doctor/patients/actions/prescriptions/search"}>
                    Reçete Ara
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>
              {/* Talepler for Doctor */}
              <Menu.Item key="/doctor/requests">
                <Link to={"/doctor/requests"}>
                  Talepler
                </Link>
              </Menu.Item>
              {/* Games for Doctor */}
              <Menu.Item key="/doctor/store/games">
                <Link to={"/doctor/store/games"}>
                  Oyunlar
                </Link>
              </Menu.Item>
              {/* LogOut for Doctor */}
              <Menu.SubMenu key="/doctor/profile/menu" title={<Typography.Text style={{ color: 'rgba(255, 255, 255, 0.65)' }}>{currentDoctor.AdSoyad}&nbsp;<DownOutlined style={{ fontSize: '80%' }} /></Typography.Text>} style={{ marginLeft: 'auto' }}>
                <Menu.Item key="/doctor/profile">
                  <Link to={"/doctor/profile"}>
                    Profil
                  </Link>
                </Menu.Item>
                <Menu.Item key="/logout" style={{ marginLeft: 'auto' }}>
                  <Link to={"/"} onClick={this.logOut}>
                    Çıkış
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>
            </Menu>
          )}
        </Header>
        {/* Layout Routes for User Types */}
        <Layout>
          <Layout style={{ padding: '0 24px 24px' }}>
            <Breadcrumbs />
            <Content className="site-layout-background"
                     style={!currentParent && !currentDoctor ? { padding: 0, margin: '16px 0', width: '100%', height: '100%', position: 'relative'/* backgroundImage: "linear-gradient(#fadb14, #fff)" */ } : { padding: 24, margin: '16px 0', width: '100%', height: '100%', position: 'relative'/* backgroundImage: "linear-gradient(#fadb14, #fff)" */ }}>
              {/* All User's Routes */}
              {!currentParent && !currentDoctor &&
                <Switch>
                  {CommonRoutes}
                  <Route path="*" component={MainframeComponent} />
                </Switch>
              }
              {/* Parent's Routes */}
              {
                currentParent && !currentDoctor &&
                <Switch>
                  {EbeveynRoutes}
                  <Route path="*" component={MainframeComponent} />
                </Switch>
              }
              {/* Doctor's Routes */}
              {!currentParent && currentDoctor &&
                <Switch>
                  {DoktorRoutes}
                  <Route path="*" component={MainframeComponent} />
                </Switch>
              }
            </Content>
            <Footer style={{ textAlign: 'center' }}>Eyesoft ©{new Date().getFullYear()}</Footer>
          </Layout>
        </Layout>
      </Layout >
    );
  }
}

export default withRouter(App);