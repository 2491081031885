import authHeader from './auth-header';
import http from "../http-common";

class DoctorService {
    /* api/doctor */
    getDoctorBoard() {
        return http.get("/doctor", { headers: authHeader() });
    }

    /* api/doctor/patients/:patientId */
    getPatient(patientId, cancelToken) {
        return http.get('/doctor/patients/' + patientId, { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/patients/search/AdSoyad=:AdSoyad */
    getFilteredPatients(searchString, cancelToken) {
        return http.get('/doctor/patients/search/query=' + searchString, { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/patients/:patientId/delete */
    deleteDoctorPatient(patientId, cancelToken) {
        return http.get('/doctor/patients/' + patientId + '/delete', { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/patients/:patientId/doctor */
    setDoctorPatient(patientId) {
        return http.get("/doctor/patients/" + patientId + "/doctor", { headers: authHeader() });
    }

    /* api/doctor/patients/:patientId/requests */
    getpatientDoctorAssignReqs(patientId) {
        return http.get("/doctor/patients/" + patientId + "/reqs", { headers: authHeader() });
    }

    /* api/doctor/store/games */
    getGames(query, cancelToken) {
        let sendQuery = "";
        query.page && (sendQuery += ('?page=' + query.page));
        query.limit && (sendQuery += ('&limit=' + query.limit));
        query.category && (sendQuery += ('&category=' + query.category));
        query.exerciseType && (sendQuery += ('&exerciseType=' + query.exerciseType));
        query.priceType && (sendQuery += ('&priceType=' + query.priceType));
        return http.get("/doctor/store/games" + sendQuery, { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/store/games/categories-and-exercise-types */
    getGamesCategoriesAndExerciseTypes(cancelToken) {
        return http.get("/doctor/store/games/categories-and-exercise-types", { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/store/games/:gameId/detail */
    getGameDetail(gameId, cancelToken) {
        return http.get("/doctor/store/games/" + gameId + "/detail", { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/patients/:patientId/prescriptions */
    getDoctorPatientPrescriptions(patientId, cancelToken) {
        return http.get("/doctor/patients/" + patientId + "/prescriptions", { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/patients/prescriptions/:prescriptionId */
    getPrescription(prescriptionId, cancelToken) {
        return http.get("/doctor/patients/prescriptions/" + prescriptionId, { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/patients/prescriptions/:prescriptionId/passive */
    passivationPrescription(prescriptionId) {
        return http.put("/doctor/patients/prescriptions/" + prescriptionId + "/passive", {}, { headers: authHeader() });
    }

    /* api/doctor/former-patients */
    getFormerPatients(cancelToken) {
        return http.get('/doctor/former-patients', { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/patients/:patientId/prescriptions */
    getDoctorFormerPatientPrescriptions(patientId, cancelToken) {
        return http.get("/doctor/former-patients/" + patientId + "/prescriptions", { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/patients */
    getPatients(cancelToken) {
        return http.get('/doctor/patients', { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/patients */
    getGorsel(cancelToken) {
        return http.get('/doctor/eye-data', { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/prescription/games */
    getAllGames(cancelToken) {
        return http.get("/doctor/prescription/games", { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/patients/:patientId/prescriptions/add */
    createPrescription(patientId, prescriptionObject) {
        return http.post("/doctor/patients/" + patientId + "/prescriptions/add", prescriptionObject, { headers: authHeader() });
    }

    /* api/doctor/patients/prescriptions */
    getDoctorPrescriptions(query, cancelToken) {
        let sendQuery = "";
        (query.status || query.search || query.sortbydate || query.page || query.limit) && (sendQuery += "?");
        query.status && (sendQuery += "&status=" + query.status);
        query.search && (sendQuery += "&search=" + query.search);
        query.sortbydate && (sendQuery += "&sortbydate=" + query.sortbydate);
        query.page && (sendQuery += "&page=" + query.page);
        query.limit && (sendQuery += "&limit=" + query.limit);
        return http.get("/doctor/all-prescriptions" + sendQuery, { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/all-requests */
    getDoctorRequests(query, cancelToken) {
        let sendQuery = "";
        (query.search || query.type || query.page || query.limit) && (sendQuery += "?");
        query.search && (sendQuery += "&search=" + query.search);
        query.type && (sendQuery += "&type=" + query.type);
        query.page && (sendQuery += "&page=" + query.page);
        query.limit && (sendQuery += "&limit=" + query.limit);
        return http.get("/doctor/all-requests" + sendQuery, { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/all-requests/:requestId/confirm */
    doctorConfirmReq(requestId, cancelToken) {
        return http.put("/doctor/all-requests/" + requestId + "/confirm", {}, { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/all-requests/:requestId/decline */
    doctorDeclineReq(requestId, cancelToken) {
        return http.put("/doctor/all-requests/" + requestId + "/decline", {}, { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/all-requests/:requestId/markasread */
    doctorMarkAsRead(requestId, cancelToken) {
        return http.put("/doctor/all-requests/" + requestId + "/markasread", {}, { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/patients/add */
    getOtherPatients(query, cancelToken) {
        let sendQuery = "";
        (query.search || query.type || query.page || query.limit) && (sendQuery += "?");
        query.search && (sendQuery += "&search=" + query.search);
        query.page && (sendQuery += "&page=" + query.page);
        query.limit && (sendQuery += "&limit=" + query.limit);
        return http.get('/doctor/other-patients' + sendQuery, { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/patients/:patientId/opinions */
    getPatientOpinions(patientId, query, cancelToken) {
        let sendQuery = "";
        (query.search || query.page) && (sendQuery += "?");
        query.search && (sendQuery += "&search=" + query.search);
        query.page && (sendQuery += "&page=" + query.page);
        return http.get('/doctor/patients/' + patientId + '/opinions' + sendQuery, { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/patients/:patientId/opinions/add */
    createOpinion(patientId, opinionForm) {
        return http.post("/doctor/patients/" + patientId + "/opinions/add", opinionForm, { headers: authHeader() });
    }

    /* api/doctor/patients/:patientId/diagnosis */
    getPatientDiagnosis(patientId, query, cancelToken) {
        let sendQuery = "";
        (query.search || query.page) && (sendQuery += "?");
        query.search && (sendQuery += "&search=" + query.search);
        query.page && (sendQuery += "&page=" + query.page);
        return http.get('/doctor/patients/' + patientId + '/diagnosis' + sendQuery, { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/diagnosis/search/query=:Isim */
    getFilteredDiagnosis(searchString, cancelToken) {
        return http.get('/doctor/diagnosis/search/query=' + searchString, { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/diagnosis/:diagnoseId */
    getDiagnose(diagnoseId, cancelToken) {
        return http.get('/doctor/diagnosis/' + diagnoseId, { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/patients/:patientId/diagnosis/add */
    createDiagnose(patientId, diagnoseForm) {
        return http.post("/doctor/patients/" + patientId + "/diagnosis/add", diagnoseForm, { headers: authHeader() });
    }

    /* api/doctor/patients/:patientId/diagnosis/add */
    getDoctor(cancelToken) {
        return http.get("/doctor/profile", { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/patients/count */
    getDoctorPatientsCount(cancelToken) {
        return http.get("/doctor/patients/count/all", { headers: authHeader(), cancelToken: cancelToken });
    }
    /* api/doctor/patients/count */
    getDoctorFormerPatientsCount(cancelToken) {
        return http.get("/doctor/former-patients/count/all", { headers: authHeader(), cancelToken: cancelToken });
    }
    /* api/doctor/patients/count */
    getDoctorActiveReqsCount(cancelToken) {
        return http.get("/doctor/reqs/count/all", { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/diagnosis/count/all */
    getDoctorDiagnosisGraphData(cancelToken) {
        return http.get("/doctor/diagnosis/count/all", { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/diagnosis/count/all */
    getDoctorPatientsAgeRange(cancelToken) {
        return http.get("/doctor/patients/age/all", { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/patients/prescriptions/games/all */
    getDoctorPrescriptionsGames(cancelToken) {
        return http.get("/doctor/patients/prescriptions/games/all", { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/patients/prescriptions/games/all */
    getDoctorsWhoDontPlayGames(cancelToken) {
        return http.get("/doctor/patients/dont-play-games/all", { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/patients/:patientId/daily-play-game */
    getPatientDailyPlayGamesCount(patientId, range, cancelToken) {
        return http.get("/doctor/patients/" + patientId + "/daily-play-game/" + range, { headers: authHeader(), cancelToken: cancelToken });
    }

    /* api/doctor/patients/:patientId/gamedatas */
    getPatientGameDatas(patientId, cancelToken) {
        return http.get("/doctor/patients/" + patientId + "/gamedatas", { headers: authHeader(), cancelToken: cancelToken });
    }
}

export default new DoctorService();
