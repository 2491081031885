import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';

/* for "axios" request cancelation */
import axios from 'axios';

/* Helpers */
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';

/* Services */
import ParentService from "../../../services/parent.service";
import AuthService from "../../../services/auth.service";

/* Ant Design */
import { Spin, Button, Typography, Form, Input, Select, DatePicker } from 'antd';

/* for "axios" request cancelation check variable */
let source;

const ParentProfileEdit = (props) => {
    const [patient, setPatient] = useState({});
    const [patientWithoutId, setPatientWithoutId] = useState({});
    const [loadingPatient, setLoadingPatient] = useState(false);
    const [loadingForm, setLoadingForm] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(true);
    const [equalPassword, setEqualPassword] = useState(true);

    const getPatient = () => {
        setLoadingPatient(true);
        ParentService.getParentPatient(props.match.params._id, source.token).then(
            res => {
                res.data.DogumTarihi && (res.data.DogumTarihi = moment(res.data.DogumTarihi));
                res.data.Sifre = '';
                res.data.verifySifre = '';
                setPatient({ ...res.data });

                delete res.data._id;
                delete res.data.DoktorID;
                setPatientWithoutId({ ...res.data });

                setLoadingPatient(false);
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                if (resMessage === "Cancel") {
                    setLoadingPatient(false);
                } else {
                    setPatient({});
                    setLoadingPatient(false);
                    toast.error(resMessage);
                }
            }
        )
    }

    const onFormChange = (changedValues, values) => {
        const matches = (obj, source) =>
            Object.keys(source).every(key => obj.hasOwnProperty(key) && obj[key] === source[key]);

        if (values.Sifre === values.verifySifre) {
            setEqualPassword(true)
        } else {
            setEqualPassword(false);
        }

        //"Sifre" ve "verifySifre" minimum 8 karekterden oluşmalı ve birbirlerine eşit olmalılar!
        let verifyPass = ((values['Sifre'].length >= 8 && values['verifySifre'].length >= 8)
            ||
            (values['Sifre'].length === 0 && values['verifySifre'].length === 0))
            &&
            (values.Sifre === values.verifySifre);

        matches(values, patientWithoutId) || !verifyPass ?
            setButtonDisable(true)
            :
            setButtonDisable(false);
    }

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }

    const onFinish = (values) => {
        setLoadingForm(true);
        values.DoktorID = patient.DoktorID;
        values._id = patient._id;
        delete values.verifySifre;

        AuthService.patientEdit(patient._id, values)
            .then(
                (response) => {
                    getPatient();
                    toast.success(response.successMessage, { autoClose: 3000 });
                    setButtonDisable(true);
                    setLoadingForm(false);
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(resMessage);
                    setLoadingForm(false);
                }
            )
    }

    useEffect(() => {
        source = axios.CancelToken.source();

        setLoadingPatient(true);
        getPatient();

        return () => {
            if (source) {
                source.cancel();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {(!loadingPatient && patient) ?
                <div>
                    <div style={{ textAlign: 'center', marginBottom: '5vh' }}>
                        <Typography.Title level={4}>
                            {patient ? patient.AdSoyad + " Düzenle" : "Hasta Düzenle"}
                        </Typography.Title>
                    </div>
                    <Form
                        name="editPatient"
                        labelCol={{
                            xs: { offset: 0 },
                            sm: { offset: 0, span: 6 },
                            md: { offset: 1, span: 6 },
                            lg: { offset: 4, span: 4 },
                        }}
                        wrapperCol={{
                            xs: { offset: 0 },
                            sm: { offset: 0, span: 12 },
                            md: { offset: 0, span: 10 },
                            lg: { offset: 0, span: 8 },
                        }}
                        initialValues={patient}
                        onFinish={onFinish}
                        onValuesChange={(changedValues, values) => onFormChange(changedValues, values)}
                        autoComplete="off"
                    >
                        {/* TCNo */}
                        <Form.Item
                            label="T.C. Numarası"
                            name="TCNo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Lütfen Hastanın T.C. Kimlik Numarasını Giriniz!',
                                    min: 11,
                                    max: 11,
                                },
                            ]}
                        >
                            <Input placeholder="Lütfen Hastanın T.C. Kimlik Numarasını Giriniz!"
                                maxLength={11}
                                disabled={true}
                            />
                        </Form.Item>

                        {/* New Password */}
                        <Form.Item
                            label="Yeni Şifre"
                            name="Sifre"
                            rules={[
                                {
                                    message: 'Lütfen Yeni Şifrenizi Giriniz!',
                                    min: 8,
                                    max: 16,
                                },
                            ]}
                        >
                            <Input.Password
                                placeholder="Lütfen Yeni Şifrenizi Giriniz!"
                                maxLength={16}
                            />
                        </Form.Item>

                        {/* New Password Verify */}
                        <Form.Item
                            label="Yeni Şifre Doğrula"
                            name="verifySifre"
                            validateStatus={equalPassword === true ? "success" : "error"}
                            rules={[
                                {
                                    message: 'Lütfen Yeni Şifrenizi Doğrulamak için Tekrar Giriniz!',
                                    min: 4,
                                    max: 16,
                                },
                            ]}
                        >
                            <Input.Password
                                placeholder="Lütfen Yeni Şifrenizi Doğrulamak için Tekrar Giriniz!"
                                maxLength={16}
                            />
                        </Form.Item>

                        {/* AdSoyad */}
                        <Form.Item
                            label="Ad ve Soyad"
                            name="AdSoyad"
                            rules={[
                                {
                                    required: true,
                                    message: 'Lütfen Hastanın Adını ve Soyadını Giriniz!',
                                    min: 4,
                                    max: 128,
                                },
                            ]}
                        >
                            <Input
                                placeholder="Lütfen Hastanın Adını ve Soyadını Giriniz!"
                                maxLength={128}
                            />
                        </Form.Item>

                        {/* DogumTarihi */}
                        <Form.Item
                            label="Doğum Tarihi"
                            name="DogumTarihi"
                            rules={[
                                {
                                    required: true,
                                    message: 'Lütfen Doğum Tarihinizi Seçiniz!',
                                },
                            ]}
                        >
                            <DatePicker
                                locale={locale}
                                format={'DD/MM/YYYY'}
                                disabledDate={disabledDate}
                            />
                        </Form.Item>

                        {/* Cinsiyet */}
                        <Form.Item
                            label="Cinsiyet"
                            name="Cinsiyet"
                            rules={[
                                {
                                    required: true,
                                    message: 'Lütfen Hastanın Cinsiyetinizi Seçiniz!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Lütfen Hastanın Cinsiyetinizi Seçiniz!"
                                allowClear
                            >
                                <Select.Option value="Kadın">Kadın</Select.Option>
                                <Select.Option value="Erkek">Erkek</Select.Option>
                                <Select.Option value="Belirtmek İstemiyorum">Belirtmek İstemiyorum</Select.Option>
                            </Select>
                        </Form.Item>

                        {/* Yakinlik */}
                        <Form.Item
                            label="Yakınlık"
                            name="Yakinlik"
                            rules={[
                                {
                                    required: true,
                                    message: 'Lütfen Yakınlık Seçiniz!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Lütfen Yakınlık Seçiniz!"
                                allowClear
                            >
                                <Select.Option value="Anne">Anne</Select.Option>
                                <Select.Option value="Baba">Baba</Select.Option>
                                <Select.Option value="Kardeş">Kardeş</Select.Option>
                                <Select.Option value="Diğer">Diğer</Select.Option>
                            </Select>
                        </Form.Item>

                        {/* Adres */}
                        <Form.Item
                            label="Adres"
                            name="Adres"
                            rules={[
                                {
                                    required: true,
                                    message: 'Lütfen Adresinizi Giriniz!',
                                    min: 8,
                                    max: 256
                                },
                            ]}
                        >
                            <Input placeholder="Lütfen Adresinizi Giriniz!" />
                        </Form.Item>

                        {/* Telefon */}
                        <Form.Item
                            label="Telefon Numarası"
                        >
                            <Input.Group compact>
                                <Input
                                    style={{ width: '10%' }}
                                    defaultValue="+90"
                                    disabled
                                />
                                <Form.Item
                                    name="Telefon"
                                    rules={[
                                        {
                                            required: true,
                                            min: 10,
                                            max: 10,
                                            message: 'Lütfen Telefon Numaranızı Giriniz!',
                                        },
                                    ]}
                                >
                                    <Input style={{ width: '100%' }} placeholder="5554443322" maxLength={10} />
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>

                        {/* Submit */}
                        <Form.Item
                            wrapperCol={{
                                xs: { offset: 0 },
                                sm: { offset: 6, span: 12 },
                                md: { offset: 7, span: 10 },
                                lg: { offset: 8, span: 8 },
                            }}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                block
                                loading={loadingForm}
                                disabled={buttonDisable}
                            >
                                Düzenle!
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                :
                <div style={{ textAlign: 'center' }}>
                    <Spin />
                </div>
            }
        </div >
    )
}


const mapState = (state) => ({
});

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(ParentProfileEdit);