import React, { Component } from "react";
import { connect } from 'react-redux';
import Highlighter from "react-highlight-words";
import { toast } from 'react-toastify';

/* for "axios" request cancelation */
import axios from 'axios';

/* Helpers */
import { history } from '../../../helpers/history';

/* Services */
import ParentService from "../../../services/parent.service";

/* Common Components */
import ModalComponent from '../../common.components/modal';

/* Ant Design */
import { Typography, Table, Row, Col, Button, Tag, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

/* for "axios" request cancelation check variable */
let source;

class PatientAssignDoctor extends Component {
    constructor(props) {
        super(props);
        this.getPatient = this.getPatient.bind(this);
        this.doctorAssignReqs = this.doctorAssignReqs.bind(this);
        this.getDoctors = this.getDoctors.bind(this);
        this.assignDoctor = this.assignDoctor.bind(this);
        this.callMyMethod = this.callMyMethod.bind(this);
        this.setVisible = this.setVisible.bind(this);

        this.state = {
            secLoading: false,
            modalVisible: false,
            modalConfirmLoading: false,
            waitingDoctorReq: []
        };

        history.listen((location) => {
            props.patientDispatch.clearPatient();
            props.doctorDispatch.clearDoctor();
        });
    }

    componentDidMount() {
        /* Component Mount olurken source'a token iletilir */
        source = axios.CancelToken.source();

        this.props.doctorDispatch.clearDoctor();
        this.props.doctorDispatch.setLoading(true);

        this.getPatient(this.props.match.params.patientId);
    }

    /* for Search */
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`${dataIndex} Arayın`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Ara
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Temizle
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filtrele
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    /* for Search */
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    /* for Search Reset */
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    /* Table Columns */
    doctorColumns = [
        /* AdSoyad */
        {
            title: 'Ad - Soyad',
            dataIndex: 'AdSoyad',
            key: 'AdSoyad',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.AdSoyad.localeCompare(b.AdSoyad),
            sortDirections: ['ascend', 'descend', 'ascend'],
            responsive: ["xs", "sm", "md", "lg"],
            ...this.getColumnSearchProps('AdSoyad'),
        },
        /* Cinsiyet */
        {
            title: 'Cinsiyet',
            dataIndex: 'Cinsiyet',
            key: 'Cinsiyet',
            filters: [
                {
                    text: 'Kadın',
                    value: 'Kadın',
                },
                {
                    text: 'Erkek',
                    value: 'Erkek',
                },
                {
                    text: 'Diğer',
                    value: 'Diğer',
                },
            ],
            onFilter: (value, record) => record.Cinsiyet.indexOf(value) === 0,
            responsive: ["md", "lg"]
        },
        /* E-Posta */
        {
            title: 'E-Posta',
            dataIndex: 'Eposta',
            key: 'Eposta',
            responsive: ["lg"],
            ...this.getColumnSearchProps('Eposta'),
        },
        /* Actions */
        {
            title: 'İşlemler',
            dataIndex: '',
            key: 'x',
            render: (text, record) => (
                <Row gutter={{ xs: 32, sm: 24, md: 16, lg: 8 }}>
                    {/* Doktor Ata */}
                    <Col className="gutter-row">
                        {
                            (record._id !== undefined && record._id !== null && record.DoktorID !== "") ?
                                /* Doktor Seç */
                                <div>
                                    <Button
                                        block
                                        type="primary"
                                        ghost
                                        style={{ whiteSpace: "normal", height: 'auto', marginBottom: '10px' }}
                                        onClick={
                                            () => {
                                                // this.assignDoctor(record.key)
                                                this.setState({ modalVisible: record.key })
                                            }
                                        }
                                        loading={this.state.secLoading === record.key}
                                        disabled={this.state.secLoading !== false ? true : false}
                                    >
                                        Seç
                                    </Button>
                                </div>
                                :
                                /* Doktor Ata */
                                <Tag color="red">Seçilemez!</Tag>
                        }
                    </Col>
                </Row>
            ),
            responsive: ["xs", "sm", "md", "lg"]
        },
    ];

    /* Patient Infos */
    getPatient(patientId) {
        ParentService.getParentPatient(patientId, source.token).then(
            res => {
                if (!res.data.DoktorID) {
                    this.doctorAssignReqs(this.props.match.params.patientId);
                    this.props.patientDispatch.setPatient(res.data);
                } else {
                    toast.error("Zaten Doktorunuz Mevcut!");
                    this.clearTimer = setTimeout(() => {
                        this.props.history.goBack();
                    }, 2000);
                }
            },
            err => {
                const resMessage =
                    (err.response &&
                        err.response.data &&
                        err.response.data.message) ||
                    err.message ||
                    err.toString();

                /* Request cancel edildiğinde message olarak 'Cancel' dönüyor. Hata bildirimi verilmesini engellemek için */
                if (resMessage !== 'Cancel') {
                    toast.error(resMessage);
                }
            }
        )
    }

    /* if Doctor Assign Request */
    doctorAssignReqs(patientId) {
        ParentService.getpatientDoctorAssignReqs(patientId).then(
            res => {
                if (res.data?.length > 0) {
                    this.setState({ waitingDoctorReq: res.data });
                }
                this.getDoctors();
            },
            err => {
                const resMessage =
                    (err.response &&
                        err.response.data &&
                        err.response.data.message) ||
                    err.message ||
                    err.toString();

                toast.error(resMessage, { autoClose: 3000 });

                this.clearTimer = setTimeout(() => {
                    this.props.history.goBack();
                }, 3000);
                this.props.doctorDispatch.setLoading(false);
            }
        )
    }

    /* else get Doctors */
    getDoctors() {
        ParentService.getDoctors(source.token).then(
            res => {
                const doctors = res.data.map((doctor) => {
                    return {
                        ...doctor,
                        key: doctor._id,
                    }
                })
                this.props.doctorDispatch.setDoctors(doctors);
                this.props.doctorDispatch.setLoading(false);
            },
            err => {
                const resMessage =
                    (err.response &&
                        err.response.data &&
                        err.response.data.message) ||
                    err.message ||
                    err.toString();

                /* Request cancel edildiğinde message olarak 'Cancel' dönüyor. Hata bildirimi verilmesini engellemek için */
                if (resMessage !== 'Cancel') {
                    toast.error(resMessage);
                }

                this.props.doctorDispatch.setLoading(false);
            }
        )
    }

    /* Assign Doctor after Selection Modal Confirm */
    assignDoctor(doctorId) {
        this.setState({ secLoading: doctorId });

        ParentService.setPatientDoctor(this.props.match.params.patientId, doctorId).then(
            res => {
                toast.success(res.data.successMessage, { autoClose: 3000 });
                this.clearTimer = setTimeout(() => {
                    this.props.history.push("/parent/patients");
                    clearTimeout(this.clearTimer);
                }, 3000);
                // this.setState({ secLoading: false });
            },
            err => {
                const resMessage =
                    (err.response &&
                        err.response.data &&
                        err.response.data.message) ||
                    err.message ||
                    err.toString();


                toast.error(resMessage);
                this.setState({ secLoading: false });
            }
        )
    }

    callMyMethod(doctorId) {
        this.setState({ modalVisible: false });
        this.assignDoctor(doctorId);
    }

    setVisible(payload) {
        this.setState({ modalVisible: payload });
    }

    componentWillUnmount() {
        /* Component Unmount edilirken cancel triggerı tetiklenir */
        if (source) {
            source.cancel();
        }

        this.props.patientDispatch.clearPatient();
        this.props.doctorDispatch.clearDoctor();

        clearTimeout(this.clearTimer);
    }

    render() {
        return (
            <div>
                {this.props.patient.userPatient ?
                    <Typography.Title level={1} style={{ textAlign: "center" }}><b>"{this.props.patient.userPatient.AdSoyad}"</b> için Doktor Seçimi</Typography.Title>
                    :
                    <Typography.Title level={1} style={{ textAlign: "center" }}>Doktor Seçimi</Typography.Title>
                }
                {this.state.waitingDoctorReq.length > 0 &&
                    <Typography.Title level={4} style={{ textAlign: "right", color: "red" }}>{this.state.waitingDoctorReq.length} adet Doktor İsteği Mevcut!</Typography.Title>
                }
                <Table
                    columns={this.doctorColumns}
                    dataSource={this.props.doctor.doctors ? this.props.doctor.doctors : null}
                    pagination={{ pageSize: 10, hideOnSinglePage: true }}
                    loading={this.props.doctor.loading}
                />
                {this.state.modalVisible !== false &&
                    <ModalComponent
                        titleText="Doktor Atama İşlemi"
                        bodyText="Doktor atama talebiniz, doktor onayına sunulacaktır. Devam etmek istiyor musunuz?"
                        visible={this.state.modalVisible}
                        callMethod={this.callMyMethod}
                        setVisible={this.setVisible}
                    />
                }
            </div>
        )
    }
}

const mapState = (state) => ({
    patient: state.patient,
    doctor: state.doctor,
});

const mapDispatch = (dispatch) => ({
    patientDispatch: dispatch.patient,
    doctorDispatch: dispatch.doctor,
});

export default connect(mapState, mapDispatch)(PatientAssignDoctor);