import React, { Component } from "react";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

/* for "axios" request cancelation */
import axios from 'axios';

/* Helpers */
import { history } from '../../../helpers/history';

/* Services */
import ParentService from "../../../services/parent.service";

/* Ant Design */
import { Typography, Spin, Card, List, Row, Col, Button, Modal } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

/* for "axios" request cancelation check variable */
let source;

class PatientDoctor extends Component {
    constructor(props) {
        super(props);
        this.getPatient = this.getPatient.bind(this);
        this.deleteDoctor = this.deleteDoctor.bind(this);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);

        this.state = {
            deleteButton: false,
            modalVisible: false,
            modalConfirmLoading: false
        };

        history.listen((location) => {
            props.patientDispatch.clearPatient();
            props.doctorDispatch.clearDoctor();
        });
    }

    componentDidMount() {
        /* Component Mount olurken source'a token iletilir */
        source = axios.CancelToken.source();

        this.props.patientDispatch.clearPatient();
        this.props.doctorDispatch.clearDoctor();

        this.getPatient();
    }

    getPatient() {
        this.props.doctorDispatch.setLoading(true);

        ParentService.getParentPatientDoctor(this.props.match.params.patientId, source.token).then(
            (res) => {
                if (res.data.hasOwnProperty('DoktorID') && res.data.DoktorID !== undefined && res.data.DoktorID !== null && res.data.DoktorID !== "") {
                    const patient = res.data;
                    patient.Telefon = [res.data.DoktorID.Telefon.slice(0, 3), " ", res.data.DoktorID.Telefon.slice(3, 6), " ", res.data.DoktorID.Telefon.slice(6, 8), " ", res.data.DoktorID.Telefon.slice(8, 10)].join('');
                    this.props.doctor.userDoctor = patient.DoktorID;
                    delete patient.DoktorID;
                    this.props.patient.userPatient = patient;
                    this.props.doctorDispatch.setLoading(false);
                } else {
                    toast.error("Kayıt Bulunamadı!");
                    this.props.doctorDispatch.setLoading(false);
                }
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                /* Request cancel edildiğinde message olarak 'Cancel' dönüyor. Hata bildirimi verilmesini engellemek için */
                if (resMessage !== 'Cancel') {
                    toast.error(resMessage);
                }
                this.props.doctorDispatch.setLoading(false);
            }
        )
    }

    handleCancel() {
        this.setState({ modalVisible: false })
    }

    handleOk() {
        this.setState({ modalConfirmLoading: true });
        this.deleteDoctor();
        this.setState({ modalConfirmLoading: true, modalVisible: false });
    }

    deleteDoctor() {
        this.setState({ deleteButton: true });
        ParentService.doctorDelete(this.props.patient.userPatient._id).then(
            (response) => {
                toast.success(response.data.successMessage, { autoClose: 3000 });
                this.clearTimer = setTimeout(() => {
                    this.props.history.push("/parent/patients");
                }, 3000);
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                toast.error(resMessage);
                this.setState({ deleteButton: false });
            }
        )
    }

    componentWillUnmount() {
        /* Component Unmount edilirken cancel triggerı tetiklenir */
        if (source) {
            source.cancel();
        }

        this.props.patientDispatch.clearPatient();
        this.props.doctorDispatch.clearDoctor();

        clearTimeout(this.clearTimer);
    }

    render() {
        return (
            <div>
                {!this.props.doctor.loading &&
                    <div>
                        <Card style={{ marginTop: 16 }} loading={this.props.doctor.loading}>
                            <Card.Meta
                                title={
                                    <div>
                                        <Typography.Title level={3} style={{ textAlign: 'center' }}>
                                            <Typography.Text style={{ fontWeight: 'bold' }}>"{this.props.userPatient.AdSoyad}"</Typography.Text> için Doktor Bilgisi
                                        </Typography.Title>
                                        <Button type="primary" loading={this.state.deleteButton} onClick={() => this.setState({ modalVisible: true })} htmlType="button" danger style={{ float: 'right', marginBottom: 10 }}>
                                            Doktoru Sil
                                        </Button>
                                        <Modal
                                            title={
                                                <div>
                                                    <Typography.Text type="danger"><WarningOutlined /> Doktoru Sil!</Typography.Text>
                                                </div>
                                            }
                                            visible={this.state.modalVisible}
                                            onOk={() => this.handleOk()}
                                            confirmLoading={this.state.modalConfirmLoading}
                                            onCancel={() => this.handleCancel()}
                                        >
                                            <p>
                                                <b>"{this.props.userDoctor.AdSoyad}"</b> isimli doktor ile ilişiği kesmek istediğinize emin misiniz?
                                                <br />
                                                Silme işlemi bitiminde doktor bilgilendirilecektir.
                                            </p>
                                        </Modal>
                                    </div>
                                }
                            />
                            <br />
                            <List size="larger" bordered>
                                {/* İsim - Adres */}
                                <List.Item>
                                    <Row gutter={[8, 32]} style={{ width: '100%' }}>
                                        <Col span={12}>
                                            <Typography.Text style={{ fontWeight: 'bold' }}>İsim: </Typography.Text>
                                            <Typography.Text>{this.props.userDoctor.AdSoyad}</Typography.Text>
                                        </Col>
                                        <Col span={12}>
                                            <Typography.Text style={{ fontWeight: 'bold' }}>Adres: </Typography.Text>
                                            <Typography.Text>{this.props.userDoctor.Adres}</Typography.Text>
                                        </Col>
                                    </Row>
                                </List.Item>
                                {/* Cinsiyet - Telefon */}
                                <List.Item>
                                    <Row gutter={[8, 32]} style={{ width: '100%' }}>
                                        <Col span={12}>
                                            <Typography.Text style={{ fontWeight: 'bold' }}>Cinsiyet: </Typography.Text>
                                            <Typography.Text>{this.props.userDoctor.Cinsiyet}</Typography.Text>
                                        </Col>
                                        <Col span={12}>
                                            <Typography.Text style={{ fontWeight: 'bold' }}>Telefon: </Typography.Text>
                                            <Typography.Text>+90 {this.props.userDoctor.Telefon}</Typography.Text>
                                        </Col>
                                    </Row>
                                </List.Item>
                                {/* Eposta */}
                                <List.Item>
                                    <Row gutter={[8, 32]} style={{ width: '100%' }}>
                                        <Col span={12}>
                                        </Col>
                                        <Col span={12}>
                                            <Typography.Text style={{ fontWeight: 'bold' }}>E-Posta: </Typography.Text>
                                            <Typography.Text>{this.props.userDoctor.Eposta}</Typography.Text>
                                        </Col>
                                    </Row>
                                </List.Item>
                            </List>
                        </Card>
                    </div >
                }
                {
                    this.props.doctor.loading &&
                    <div style={{ textAlign: 'center' }}>
                        <Spin />
                    </div>
                }
            </div >
        )
    }
}

const mapState = (state) => ({
    patient: state.patient,
    userPatient: state.patient.userPatient,
    doctor: state.doctor,
    userDoctor: state.doctor.userDoctor,
});

const mapDispatch = (dispatch) => ({
    patientDispatch: dispatch.patient,
    doctorDispatch: dispatch.doctor,
});

export default connect(mapState, mapDispatch)(PatientDoctor);