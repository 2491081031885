import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";

/* for Rematch */
import { Provider } from 'react-redux';
import store from './store';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

/* for Antd Language */
import { ConfigProvider } from 'antd';
import tr_TR from 'antd/es/locale/tr_TR';

ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <ConfigProvider locale={tr_TR}>
        <App />
      </ConfigProvider>
    </Provider>
  </BrowserRouter>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
