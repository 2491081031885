import React, { Component } from "react";
import { connect } from "react-redux";

/* Ant Design */
import { Typography } from 'antd';

class countComp extends Component {
    render() {
        return (
            <div>
                <Typography.Title level={1} style={{ textAlign: "center" }}>Rematch Test with Count!</Typography.Title>

                Count State'inin Değeri: &nbsp; {this.props.deger} <br />
                User: &nbsp; {JSON.stringify(this.props.user)} <br />
                User TCNo: &nbsp; {this.props.userTCNo} <br />
                User Sifre: &nbsp; {this.props.userSifre} <br />
                <button onClick={() => this.props.countDispatch.setDeger(3)}>countDispatch ile Artırma</button>
                <button onClick={() => this.props.countDispatch.setDegerAsync(5)}>countDispatch ile Artırma(ASYNC)</button>
                <button onClick={() => this.props.countIncrement(7)}>countIncrement ile Artırma</button>
                <button onClick={this.props.countIncrementAsync}>countIncrement ile Artırma(ASYNC)</button>
            </div>
        );
    }
}

const mapState = (state) => ({
    deger: state.count.deger,
    user: state.count.user,
    userTCNo: state.count.user.TCNo,
    userSifre: state.count.user.Sifre,
});

const mapDispatch = (dispatch) => ({
    countDispatch: dispatch.count,
    countIncrement: (input) => dispatch.count.setDeger(input),
    countIncrementAsync: () => dispatch.count.setDegerAsync(10),
});

export default connect(mapState, mapDispatch)(countComp);
