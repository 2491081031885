export const count = {
    state: {
        deger: 2,
        user: {
            TCNo: "12345678902",
            Sifre: "eren1234",
        }
    }, // initial state
    reducers: {
        // handle state changes with pure functions
        /* increment(state, payload) {
            return {...state.deger, payload};
        }, */
        setDeger(state, deger){
            return{
                ...state,
                deger: state.deger + deger 
            }
        }
    },
    effects: (dispatch) => ({
        // handle state changes with impure functions.
        // use async/await for async actions
        async setDegerAsync(payload, rootState) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            dispatch.count.setDeger(payload);
        },
    }),
};