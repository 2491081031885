import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';

/* for "axios" request cancelation */
import axios from 'axios';

/* Helpers */
import { history } from '../../../../helpers/history';
import 'moment/locale/tr';

/* Services */
import DoctorService from "../../../../services/doctor.service";

/* Ant Design */
import { Button, Input, Typography, Form, Select, Spin, Space, Card, Tag, Slider, Row, Col, InputNumber, Tooltip, Modal } from 'antd';
import { MinusCircleOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

/* for "axios" request cancelation check variable */
let source;
let clearTimer;

const AddPrescription = (props) => {
    const [patient, setPatient] = useState({});
    const [patientLoading, setPatientLoading] = useState(false);
    const [allGames, setAllGames] = useState([]);
    const [allGamesLoading, setAllGamesLoading] = useState(false);
    const [sliderHaftaInputValues, setSliderHaftaInputValues] = useState([]);
    const [sliderHaftadaKacGunInputValues, setSliderHaftadaKacGunInputValues] = useState([]);
    const [sliderGundeKacKezInputValues, setSliderGundeKacKezInputValues] = useState([]);
    const [prescription, setPrescription] = useState({ Oyunlar: [], ReceteAciklama: "" });
    const [addPrescriptionLoading, setAddPrescriptionLoading] = useState(false);

    let { patientId } = useParams();

    useEffect(() => {
        source = axios.CancelToken.source();

        if (patientId) {
            getPatient(patientId);
            getAllGames();
        } else {
            toast.error("Hasta Bulunamadı!", { autoClose: 2000 });
        }

        return () => {
            if (source) {
                source.cancel();
            }
            clearTimer && clearTimeout(clearTimer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPatient = (patientId) => {
        setPatientLoading(true);

        DoctorService.getPatient(patientId, source.token)
            .then(
                (res) => {
                    setPatient({ ...res.data });
                    setPatientLoading(false);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (resMessage !== 'Cancel') {
                        toast.error(resMessage, { autoClose: 3000 });
                        setPatientLoading(false);
                    }
                }
            )
    }

    const getAllGames = () => {
        setAllGamesLoading(true);

        DoctorService.getAllGames(source.token)
            .then(
                (res) => {
                    let modifiedGames = res.data?.map(element => {
                        element.Selected = false;
                        return element;
                    });
                    setAllGames([...modifiedGames]);
                    setAllGamesLoading(false);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (resMessage !== 'Cancel') {
                        toast.error(resMessage, { autoClose: 3000 });
                        setAllGamesLoading(false);
                    }
                }
            )
    }

    const onChangeHafta = (value, key) => {
        let arr = sliderHaftaInputValues;
        arr[key] = value;
        setSliderHaftaInputValues(arr);
    }

    const onChangeHaftadaKacGun = (value, key) => {
        let arr = sliderHaftadaKacGunInputValues;
        arr[key] = value;
        setSliderHaftadaKacGunInputValues(arr);
    }

    const onChangeGundeKacKez = (value, key) => {
        let arr = sliderGundeKacKezInputValues;
        arr[key] = value;
        setSliderGundeKacKezInputValues(arr);
    }

    const onFinish = (values) => {
        if (values.games?.length > 0) {
            setAddPrescriptionLoading(true);
            values.games = values.games.filter(game => game);
            setPrescription({ Oyunlar: values.games, ReceteAciklama: values.ReceteAciklama });

            DoctorService.createPrescription(patientId, { Oyunlar: values.games, ReceteAciklama: values.ReceteAciklama })
                .then(
                    (res) => {
                        toast.success(res.data.message, { autoClose: 3000 });
                        clearTimer = setTimeout(() => {
                            props.history.push("/doctor/patients/actions/my-patients/" + patientId + "/prescriptions");
                        }, 3000);
                    },
                    (error) => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        if (resMessage !== 'Cancel') {
                            toast.error(resMessage, { autoClose: 3000 });
                            setAddPrescriptionLoading(false);
                        }
                    }
                )
        } else {
            toast.error("En Az 1 Oyun Eklemelisiniz!", { autoClose: 3000 });
        }
    }

    const showPromiseConfirm = (values) => {
        Modal.confirm({
            title: 'Onay',
            icon: <ExclamationCircleOutlined />,
            content: 'Oluşturulan reçete tekrar düzenlenemez! Kaydetmek istediğinize emin misiniz?',
            onOk: () => {
                return onFinish(values);
            },
            onCancel() { },
        });
    }

    const onChangeFormDatas = (changedValues, allValues) => {
        if (allValues.games) {
            allValues.games = allValues.games.filter(game => game);
            if (allValues.games.length > 0) {
                let checkedAllGames = allGames.map(allGame => {
                    if (allValues.games.some(allValueGame => allValueGame.OyunID === allGame._id)) {
                        allGame.Selected = true;
                    } else {
                        allGame.Selected = false;
                    }

                    return allGame;
                });

                setAllGames([...checkedAllGames]);
            }
        }
    }

    return (
        <div>
            <Typography.Title style={{ textAlign: 'center' }}>
                Reçete Ekle
            </Typography.Title>
            <Spin spinning={patientLoading}>
                {!patientLoading &&
                    <Form
                        name="addPrescription"
                        onValuesChange={onChangeFormDatas}
                        onFinish={showPromiseConfirm}
                        labelCol={{
                            xs: { offset: 0 },
                            sm: { offset: 0, span: 6 },
                            md: { offset: 1, span: 6 },
                            lg: { offset: 4, span: 4 },
                        }}
                        wrapperCol={{
                            xs: { offset: 0 },
                            sm: { offset: 0, span: 12 },
                            md: { offset: 0, span: 10 },
                            lg: { offset: 0, span: 8 },
                        }}
                        autoComplete="off"
                    >
                        {/* AdSoyad */}
                        <Form.Item
                            label="Hasta İsmi"
                            name="AdSoyad"
                            initialValue={patient?.AdSoyad}
                        >
                            <Input disabled />
                        </Form.Item>

                        {/* AddGame */}
                        <Form.Item
                            label="Oyun Ekle"
                        >
                            <Form.List name="games">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                                            <Card
                                                hoverable
                                                style={{
                                                    backgroundColor: '#f0f0f0',
                                                    borderRadius: "15px",
                                                    borderWidth: 2,
                                                    borderColor: '#f0f0f0',
                                                    overflow: 'hidden',
                                                    marginBottom: '2vh'
                                                }}
                                                key={key}
                                            >
                                                <Typography.Title level={4}>#{name + 1}. Oyun</Typography.Title>
                                                {/* Oyun Seçimi */}
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'OyunID']}
                                                    fieldKey={[fieldKey, 'OyunID']}
                                                    label="Oyun Seçiniz"
                                                    rules={[{ required: true, message: 'Oyun Eksik!' }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        disabled={!allGames}
                                                        placeholder="Lütfen Oyun Seçiniz!"
                                                        defaultActiveFirstOption={true}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            || option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        style={{ width: '100%' }}
                                                    >
                                                        {allGames.map(game => (
                                                            <Select.Option
                                                                key={game.OyunAdi}
                                                                value={game._id}
                                                                disabled={game.Selected === true}
                                                            >
                                                                <Typography.Text><b>Oyun Adı:</b>&nbsp;{game.OyunAdi}</Typography.Text>
                                                                <br />
                                                                <Tag
                                                                    color="volcano"
                                                                    style={{ whiteSpace: "normal", height: 'auto', marginBottom: '10px' }}
                                                                >
                                                                    {game.KategoriID.KategoriAdi}
                                                                </Tag>
                                                                <Tag
                                                                    color="magenta"
                                                                    style={{ whiteSpace: "normal", height: 'auto', marginBottom: '10px' }}
                                                                >
                                                                    {game.EgzersizTipiID.EgzersizTipiAdi}
                                                                </Tag>
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                {/* Oyun Açıklaması */}
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'OyunAciklama']}
                                                    fieldKey={[fieldKey, 'OyunAciklama']}
                                                    label="Oyun Açıklaması"
                                                    rules={[{ required: true, message: 'Lütfen Oyun Açıklaması Giriniz!' }]}
                                                >
                                                    <Input.TextArea placeholder="Oyun Açıklaması Giriniz!" maxLength={256} />
                                                </Form.Item>
                                                {/* Hafta */}
                                                <Row>
                                                    <Tooltip placement="topLeft" title="Kaç Hafta Oynanacağını Belirtiniz!">
                                                        <Col span={12}>
                                                            <Form.Item
                                                                {...restField}
                                                                label="Hafta"
                                                                name={[name, 'Hafta']}
                                                                fieldKey={[fieldKey, 'Hafta']}
                                                                initialValue={1}
                                                                rules={[{ required: true, message: 'Lütfen Hafta Giriniz!' }]}
                                                            >
                                                                <Slider
                                                                    min={1}
                                                                    max={52}
                                                                    value={sliderHaftaInputValues[key] ? parseInt(sliderHaftaInputValues[key]) : 1}
                                                                    onChange={(value) => onChangeHafta(value, key)}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Tooltip>
                                                    <Col span={4}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'Hafta']}
                                                            fieldKey={[fieldKey, 'Hafta']}
                                                        >
                                                            <InputNumber
                                                                min={1}
                                                                max={52}
                                                                value={sliderHaftaInputValues[key] ? parseInt(sliderHaftaInputValues[key]) : 1}
                                                                onChange={(value) => onChangeHafta(value, key)}
                                                                style={{ margin: '0 16px' }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                {/* Haftada Kaç Gün */}
                                                <Row>
                                                    <Tooltip placement="topLeft" title="Haftada Kaç Gün Oynanacağını Belirtiniz!">
                                                        <Col span={12}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'HaftadaKacGun']}
                                                                fieldKey={[fieldKey, 'HaftadaKacGun']}
                                                                label="Haftada Kaç Gün"
                                                                initialValue={1}
                                                                rules={[{ required: true, message: 'Lütfen Haftada Kaç Gün Oynanacağını Giriniz!' }]}
                                                            >
                                                                <Slider
                                                                    min={1}
                                                                    max={7}
                                                                    value={sliderHaftadaKacGunInputValues[key] ? parseInt(sliderHaftadaKacGunInputValues[key]) : 1}
                                                                    onChange={(value) => onChangeHaftadaKacGun(value, key)}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Tooltip>
                                                    <Col span={4}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'HaftadaKacGun']}
                                                            fieldKey={[fieldKey, 'HaftadaKacGun']}
                                                        >
                                                            <InputNumber
                                                                min={1}
                                                                max={7}
                                                                value={sliderHaftadaKacGunInputValues[key] ? parseInt(sliderHaftadaKacGunInputValues[key]) : 1}
                                                                onChange={(value) => onChangeHaftadaKacGun(value, key)}
                                                                style={{ margin: '0 16px' }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                {/* Günde Kaç Kez */}
                                                <Row>
                                                    <Tooltip placement="topLeft" title="Günde Kaç Kez Oynanacağını Belirtiniz!">
                                                        <Col span={12}>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[name, 'GundeKacKez']}
                                                                fieldKey={[fieldKey, 'GundeKacKez']}
                                                                label="Günde Kaç Kez"
                                                                initialValue={1}
                                                                rules={[{ required: true, message: 'Lütfen Günde Kaç Kez Oynanacağını Giriniz!' }]}
                                                            >
                                                                <Slider
                                                                    min={1}
                                                                    max={20}
                                                                    value={sliderGundeKacKezInputValues[key] ? parseInt(sliderGundeKacKezInputValues[key]) : 1}
                                                                    onChange={(value) => onChangeGundeKacKez(value, key)}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Tooltip>
                                                    <Col span={4}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'GundeKacKez']}
                                                            fieldKey={[fieldKey, 'GundeKacKez']}
                                                        >
                                                            <InputNumber
                                                                min={1}
                                                                max={20}
                                                                value={sliderGundeKacKezInputValues[key] ? parseInt(sliderGundeKacKezInputValues[key]) : 1}
                                                                onChange={(value) => onChangeGundeKacKez(value, key)}
                                                                style={{ margin: '0 16px' }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                {/* Oyun Süresi */}
                                                <Tooltip placement="topLeft" title="Her Oturumda Kaç Dakika Oynanacağını Belirtiniz!">
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'OyunSuresi']}
                                                        fieldKey={[fieldKey, 'OyunSuresi']}
                                                        label="Oyun Süresi (dk)"
                                                        rules={[{ required: true, message: 'Lütfen Oyun Süresi Giriniz!' }]}
                                                    >
                                                        <InputNumber min={1} max={1440} />
                                                    </Form.Item>
                                                </Tooltip>
                                                <Space style={{ width: '100%', justifyContent: 'center' }}>
                                                    <Button onClick={() => remove(name)} danger>
                                                        <MinusCircleOutlined />&nbsp;Kaldır
                                                    </Button>
                                                </Space>
                                            </Card>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                Oyun Ekle
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Form.Item>

                        {/* ReceteAciklama */}
                        <Form.Item
                            label="Reçete Açıklaması"
                            name="ReceteAciklama"
                            rules={[
                                {
                                    required: true,
                                    message: 'Lütfen Reçete Açıklaması Giriniz!',
                                    min: 4,
                                    max: 256,
                                },
                            ]}
                        >
                            <Input.TextArea placeholder="Reçete Açıklaması Giriniz!" maxLength={256}></Input.TextArea>
                        </Form.Item>

                        {/* Submit */}
                        <Form.Item
                            wrapperCol={{
                                xs: { offset: 0 },
                                sm: { offset: 6, span: 12 },
                                md: { offset: 7, span: 10 },
                                lg: { offset: 8, span: 8 },
                            }}
                        >
                            <Button type="primary" htmlType="submit" block loading={addPrescriptionLoading}>
                                Reçeteyi Oluştur!
                            </Button>
                        </Form.Item>
                    </Form>
                }
            </Spin>
        </div>
    )
}

export default AddPrescription;