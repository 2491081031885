import React, { Component } from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';

/* for "axios" request cancelation */
import axios from 'axios';

/* Helpers */
import { history } from '../../../helpers/history';
import 'moment/locale/tr';

/* Services */
import DoctorService from "../../../services/doctor.service";

/* Ant Design */
import { Typography, Table, Button, Row, Col, Tag, Popconfirm } from 'antd';

/* for "axios" request cancelation check variable */
let source;

class doctorFormerPatientPrescriptions extends Component {
    constructor(props) {
        super(props);
        this.getPrescriptions = this.getPrescriptions.bind(this);
        this.passivationPrescription = this.passivationPrescription.bind(this);

        this.state = {
            tableLoading: false
        }

        history.listen((location) => {
            props.patientDispatch.clearPatient();
            props.doctorDispatch.clearDoctor();
            props.prescriptionDispatch.clearPrescriptions();
        });
    }

    componentDidMount() {
        /* Component Mount olurken source'a token iletilir */
        source = axios.CancelToken.source();

        this.props.patientDispatch.clearPatient();
        this.props.doctorDispatch.clearDoctor();

        /* const query = new URLSearchParams(this.props.location.search);
        const patient = query.get('patient');
        if (patient) {
            this.getPrescriptions(patient, source.token);
        } else {
            this.props.history.goBack();
        } */
        let patientId = this.props.match.params.patientId;
        if (patientId) {
            this.getPrescriptions(patientId, source.token);
        } else {
            toast.error("Reçeteler Bulunamadı!", { autoClose: 2000 });
            this.clearTimer = setTimeout(() => {
                this.props.history.goBack();
            }, 2000);
        }
    }

    prescriptionColumns = [
        /* Doktor AdSoyad */
        {
            title: 'Doktor',
            dataIndex: 'DoktorAdSoyad',
            key: 'DoktorAdSoyad',
            sorter: (a, b) => a.DoktorAdSoyad.localeCompare(b.DoktorAdSoyad),
            sortDirections: ['descend', 'ascend'],
            render: (DoktorAdSoyad) => {
                if (DoktorAdSoyad === "Başka Bir Doktor Tarafından Yazılmıştır!") {
                    return <Typography.Text style={{ color: 'blue' }}>{DoktorAdSoyad}</Typography.Text>
                } else {
                    return <Typography.Text>{DoktorAdSoyad}</Typography.Text>
                }
            },
            responsive: ["xs", "sm", "md", "lg"]
        },
        /* KayitTarihi */
        {
            title: 'Reçete Tarihi',
            dataIndex: 'ReceteTarihi',
            key: 'ReceteTarihi',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => new Date(a.ReceteTarihi) - new Date(b.ReceteTarihi),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (ReceteTarihi) => (
                moment(new Date(ReceteTarihi)).format('LL')
            ),
            responsive: ["lg"]
        },
        /* Reçete Açıklama */
        {
            title: 'Reçete Açıklama',
            dataIndex: 'ReceteAciklama',
            key: 'ReceteAciklama',
            responsive: ["xs", "sm", "md", "lg"]
        },
        /* Durum */
        {
            title: 'Durum',
            dataIndex: 'AktifMi',
            key: 'Durum',
            sorter: (a, b) => a.AktifMi - b.AktifMi,
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (AktifMi) => (
                AktifMi === true ?
                    <Tag color={'success'}>
                        Aktif
                    </Tag>
                    :
                    <Tag color={'error'}>
                        Pasif
                    </Tag>
            ),
            responsive: ["xs", "sm", "md", "lg"]
        },
        /* PasifTarihi */
        {
            title: 'Pasif Tarihi',
            dataIndex: 'PasifTarihi',
            key: 'PasifTarihi',
            sorter: (a, b) => new Date(a.PasifTarihi) - new Date(b.PasifTarihi),
            sortDirections: ['ascend', 'descend', 'ascend'],
            render: (PasifTarihi) => (
                PasifTarihi <= (new Date('1923-10-29')) ?
                    <Typography.Text>-</Typography.Text>
                    :
                    PasifTarihi = moment(new Date(PasifTarihi)).format('LL')
            ),
            responsive: ["lg"]
        },
        /* Actions */
        {
            title: 'İşlemler',
            dataIndex: '',
            key: 'x',
            render: (text, record) => (
                <Row gutter={{ xs: 32, sm: 24, md: 16, lg: 8 }}>
                    {/* Reçete Görüntüle */}
                    <Col className="gutter-row">
                        <Button
                            block
                            type="primary"
                            ghost
                            style={{ whiteSpace: "normal", height: 'auto', marginBottom: '10px' }}
                            onClick={
                                () => {
                                    this.props.history.push("/doctor/patients/actions/my-old-patients/" + this.props.match.params.patientId + "/prescriptions/" + record.key);
                                }
                            }
                        >
                            Görüntüle
                        </Button>
                    </Col>
                    {/* Aktif Reçete Pasifleştir */}
                    {((record.AktifMi === true) && record.DoktorID) &&
                        <Col className="gutter-row">
                            <Popconfirm title="Reçeteyi pasifleştirmek istediğinize emin misiniz?" onConfirm={() => this.passivationPrescription(record.key)}>
                                <Button type="primary" block danger>Pasifleştir</Button>
                            </Popconfirm>
                        </Col>
                    }
                </Row>
            ),
            responsive: ["xs", "sm", "md", "lg"]
        },
    ];

    getPrescriptions(patientId, cancelToken) {
        this.props.prescriptionDispatch.clearPrescriptions();
        this.setState({ tableLoading: true });

        DoctorService.getDoctorFormerPatientPrescriptions(patientId, cancelToken)
            .then(
                (res) => {
                    const prescriptions = res.data.map((prescription) => {
                        //Reçete aktif olduğunda "PasifTarihi = null" olacağı için Invalid date yerine geçmiş bir tarih uygulandı
                        moment(prescription.PasifTarihi).toString() === 'Invalid date' ?
                            prescription.PasifTarihi = new Date('1923-10-29')
                            :
                            prescription.PasifTarihi = new Date(prescription.PasifTarihi);

                        return {
                            ...prescription,
                            key: prescription._id,
                            DoktorAdSoyad: ((prescription.DoktorID !== null) ?
                                prescription.DoktorID.AdSoyad
                                :
                                "Başka Bir Doktor Tarafından Yazılmıştır!"
                            ),
                            ReceteTarihi: moment(prescription.ReceteTarihi).toString(),
                            PasifTarihi: prescription.PasifTarihi,
                        }
                    });
                    this.props.prescriptionDispatch.setPrescriptions(prescriptions);

                    this.setState({ tableLoading: false });
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    /* Request cancel edildiğinde message olarak 'Cancel' dönüyor. Hata bildirimi verilmesini engellemek için */
                    if (resMessage !== 'Cancel') {
                        toast.error(resMessage, { autoClose: 3000 });
                    }
                    this.setState({ tableLoading: false });
                    this.clearTimer = setTimeout(() => {
                        this.props.history.goBack();
                    }, 3000);
                })
    }

    passivationPrescription(prescriptionId) {
        this.setState({ tableLoading: true });

        DoctorService.passivationPrescription(prescriptionId)
            .then(
                (res) => {
                    toast.success(res.data.message, { autoClose: 3000 });
                    this.getPrescriptions();
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    /* Request cancel edildiğinde message olarak 'Cancel' dönüyor. Hata bildirimi verilmesini engellemek için */
                    if (resMessage !== 'Cancel') {
                        toast.error(resMessage, { autoClose: 3000 });
                    }
                    this.setState({ tableLoading: false });
                    this.getPrescriptions();
                })
    }


    componentWillUnmount() {
        /* Component Unmount edilirken cancel triggerı tetiklenir */
        if (source) {
            source.cancel();
        }

        clearTimeout(this.clearTimer);
    }

    render() {
        return (
            <div>
                <div>
                    {this.props.prescriptions.length !== 0 &&
                        <Typography.Title style={{ textAlign: 'center' }}>
                            "{this.props.prescriptions[0]?.HastaID.AdSoyad}" ait Reçeteler
                        </Typography.Title>
                    }
                    <Table
                        columns={this.prescriptionColumns}
                        dataSource={this.props.prescriptions && this.props.prescriptions}
                        pagination={{ pageSize: 10, hideOnSinglePage: true }}
                        loading={this.state.tableLoading}
                    />
                </div>
            </div>
        )
    }
}

const mapState = (state) => ({
    patient: state.patient,
    doctor: state.doctor,
    prescription: state.prescription,
    prescriptions: state.prescription.prescriptions,
});

const mapDispatch = (dispatch) => ({
    patientDispatch: dispatch.patient,
    doctorDispatch: dispatch.doctor,
    prescriptionDispatch: dispatch.prescription,
});

export default connect(mapState, mapDispatch)(doctorFormerPatientPrescriptions);