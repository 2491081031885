/* Services */
import ParentService from "../../services/parent.service";

export const doctor = {
    state: {
        doctors: [],
        userDoctor: {
            TCNo: "",
            Sifre: "",
            AdSoyad: null,
            Cinsiyet: null,
            Eposta: null,
            Adres: null,
            Telefon: null,
        },
        accessToken: "",
        loading: false
    },
    reducers: {
        setTCNo(state, TCNo) {
            state.userDoctor.TCNo = TCNo;
            return { ...state }
        },
        setSifre(state, Sifre) {
            state.userDoctor.Sifre = Sifre;
            return { ...state }
        },
        setAdSoyad(state, AdSoyad) {
            state.userDoctor.AdSoyad = AdSoyad;
            return { ...state }
        },
        setCinsiyet(state, Cinsiyet) {
            state.userDoctor.Cinsiyet = Cinsiyet;
            return { ...state }
        },
        setEposta(state, Eposta) {
            state.userDoctor.Eposta = Eposta;
            return { ...state }
        },
        setAdres(state, Adres) {
            state.userDoctor.Adres = Adres;
            return { ...state }
        },
        setTelefon(state, Telefon) {
            state.userDoctor.Telefon = Telefon;
            return { ...state }
        },
        setLoading(state, loading) {
            state.loading = loading;
            return { ...state }
        },
        setCurrentDoctor(state, payload) {
            state.accessToken = payload.accessToken;
            delete payload.accessToken;
            state.userDoctor = payload;
            return { ...state }
        },
        setParentPatientDoctor(state, payload) {
            state.userDoctor = payload;
            console.log(state.userDoctor);
            return { ...state }
        },
        setDoctors(state, payload) {
            state.doctors = payload;
            return { ...state }
        },
        clearDoctor(state) {
            return {
                ...state,
                doctors: [],
                userDoctor: {
                    TCNo: "",
                    Sifre: "",
                    AdSoyad: null,
                    Cinsiyet: null,
                    Eposta: null,
                    Adres: null,
                    Telefon: null,
                },
                accessToken: "",
                loading: false
            }
        },

    },
    effects: (dispatch) => ({
        async getParentPatientDoctor(payload, rootState) {
            dispatch.doctor.setLoading(true);
            try {
                const response = await ParentService.getParentPatientDoctor(payload);
                const doctor = await response.data;
                console.log("getParentPatientDoctor: " + JSON.stringify(doctor));
                dispatch.doctor.setParentPatientDoctor(doctor);
                dispatch.doctor.setLoading(false);
            } catch (error) {
                dispatch.doctor.setLoading(false);
            }
        }
    })
};