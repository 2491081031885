import React, { Component } from "react";

import { Result } from 'antd';

export default class loadingComp extends Component {
    render() {
        return (
            <Result
                status="success"
                title="Yönlendiriliyor!"
            />
        );
    }
}