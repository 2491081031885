import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';

/* for "axios" request cancelation */
import axios from 'axios';

/* Helpers */
// import { history } from '../../../../helpers/history';
import 'moment/locale/tr';

/* Services */
import DoctorService from "../../../../services/doctor.service";

/* Ant Design */
import { Button, Input, Typography, Form, Spin } from 'antd';

/* for "axios" request cancelation check variable */
let source;
let clearTimer;


const AddOpinion = (props) => {
    const [patient, setPatient] = useState();
    const [loadingPatient, setLoadingPatient] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    let { patientId } = useParams();

    useEffect(() => {
        source = axios.CancelToken.source();

        setLoadingPatient(true);
        getPatient(patientId, source.token);

        return () => {
            if (source) {
                source.cancel();
            }
            clearTimer && clearTimeout(clearTimer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPatient = (patientId, cancelToken) => {
        if (!patientId) {
            toast.error({ message: "Hasta Bulunamadı!" });
        }
        setLoadingPatient(true);
        DoctorService.getPatient(patientId, cancelToken)
            .then(
                res => {
                    setPatient(res.data);
                    setLoadingPatient(false);
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (resMessage !== "Cancel") {
                        toast.error(resMessage);
                        setLoadingPatient(false);
                    }
                }
            )
    }

    const onFinish = (values) => {
        setLoadingSubmit(true);

        DoctorService.createOpinion(patientId, values)
            .then(
                (res) => {
                    toast.success(res.data.message, { autoClose: 2000 });
                    clearTimer = setTimeout(() => {
                        props.history.push("/doctor/patients/actions/my-patients/" + patientId + "/opinions");
                    }, 2000);
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (resMessage !== 'Cancel') {
                        toast.error(resMessage, { autoClose: 3000 });
                        setLoadingSubmit(false);
                    }
                }
            )
    }

    return (
        <div>
            <Typography.Title style={{ textAlign: 'center' }}>
                {patient && '"' + patient.AdSoyad + '" için '}Görüş Ekle
            </Typography.Title>
            {!loadingPatient ?
                <Form
                    name="AddOpinion"
                    onFinish={onFinish}
                    labelCol={{
                        xs: { offset: 0 },
                        sm: { offset: 0, span: 6 },
                        md: { offset: 1, span: 6 },
                        lg: { offset: 4, span: 4 },
                    }}
                    wrapperCol={{
                        xs: { offset: 0 },
                        sm: { offset: 0, span: 12 },
                        md: { offset: 0, span: 10 },
                        lg: { offset: 0, span: 8 },
                    }}
                    autoComplete="off"
                >
                    {/* GorusAciklama */}
                    <Form.Item
                        label="Görüş Açıklaması"
                        name="Aciklama"
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen Görüş Açıklaması Giriniz!',
                                min: 4,
                                max: 1024,
                            },
                        ]}
                    >
                        <Input.TextArea placeholder="Görüş Açıklaması Giriniz!" maxLength={1024}></Input.TextArea>
                    </Form.Item>

                    {/* Submit */}
                    <Form.Item
                        wrapperCol={{
                            xs: { offset: 0 },
                            sm: { offset: 6, span: 12 },
                            md: { offset: 7, span: 10 },
                            lg: { offset: 8, span: 8 },
                        }}
                    >
                        <Button type="primary" htmlType="submit" block loading={loadingSubmit}>
                            Görüşü Kaydet!
                        </Button>
                    </Form.Item>
                </Form>
                :
                <div style={{ textAlign: 'center' }}>
                    <Spin />
                </div>
            }
        </div>
    )
}

const mapState = (state) => ({
});

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(AddOpinion);