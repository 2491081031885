import React, { Component } from "react";
import { connect } from 'react-redux';

/* Helpers */
/* import { history } from '../../../helpers/history';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR'; */

/* Services */
import AuthService from "../../../../services/auth.service";

/* Ant Design */
import { Typography } from 'antd';
import { CloseSquareOutlined } from '@ant-design/icons';

class StoreEyeTrackerDetail extends Component {
    componentDidMount() {
    }

    render() {
        return (
            <div>
                <p>Ebeveyn: {AuthService.getCurrentParent()._id}</p>
                <Typography.Title level={3}>Mağaza: Göz Takip Cihazı Detayı Yapılacaklar</Typography.Title>
                <Typography.Text><CloseSquareOutlined /> Bu sayfadaki eyetracker detayı gösterilirken, backend'de yine de bu eyetracker satın alınmamışsa / kiralanmamışsa diye kontrol etmem gerekiyor</Typography.Text>
                <br />
            </div>
        )
    }
}

const mapState = (state) => ({
    parent: state.parent,
    // eyetracker: state.eyetracker,
});

const mapDispatch = (dispatch) => ({
    parentDispatch: dispatch.parent,
    // eyetrackerDispatch: dispatch.eyetracker,
});

export default connect(mapState, mapDispatch)(StoreEyeTrackerDetail);