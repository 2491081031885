import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

/* Helpers */
import 'moment/locale/tr';

/* Services */
import AuthService from "../../services/auth.service";

/* Ant Design */
import { Button, Typography, Form, Input } from 'antd';

let clearTimer;

const ParentChangePassword = (props) => {
    const [loadingForm, setLoadingForm] = useState(true);
    const [formItemDisable, setFormItemDisable] = useState(true);
    const [buttonDisable, setButtonDisable] = useState(true);
    const [equalPassword, setEqualPassword] = useState(true);

    useEffect(() => {
        const query = new URLSearchParams(props.location.search);
        const token = query.get('token');

        if (token) {
            confirmToken(token);
        } else {
            toast.error("Hata Oluştu!", { autoClose: 3000 });
        }

        return () => {
            clearTimeout(clearTimer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const confirmToken = (token) => {
        setFormItemDisable(true);
        setLoadingForm(true);

        AuthService.parentForgotPasswordTokenVerify(token)
            .then(
                response => {
                    setFormItemDisable(false);
                    setLoadingForm(false);
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    toast.error(resMessage);
                    setLoadingForm(false);
                });
    }

    const onFormChange = (changedValues, values) => {
        values.newPassword === values.verifyNewPassword ?
            setEqualPassword(true)
            :
            setEqualPassword(false);

        (
            (values.hasOwnProperty("newPassword") && typeof values["newPassword"] === 'string' && values["newPassword"].length) &&
            (values.hasOwnProperty("verifyNewPassword") && typeof values["verifyNewPassword"] === 'string' && values["verifyNewPassword"].length)
        )
            ?
            setButtonDisable(false)
            :
            setButtonDisable(true);
        /*TODO --> Orjinal Data değiştiğinde eğer sayfadan çıkılmaya çalışılıyor ise modal ile 
                    "Kaydetmeden Çıkmak İstediğinize Emin Misiniz?" diye sorarak çıkış yapılmalıdır.
        */
    }

    const onFinish = (values) => {
        setLoadingForm(true);
        if (values) {
            if (values.newPassword === values.verifyNewPassword) {
                delete values.verifyNewPassword;

                const query = new URLSearchParams(props.location.search);
                const token = query.get('token');

                AuthService.parentForgotChangePassword(token, { Sifre: values.newPassword })
                    .then(
                        (response) => {
                            toast.success(response.data.message, { autoClose: 3000 });
                            setButtonDisable(true);
                            setFormItemDisable(true);
                            setLoadingForm(false);
                            clearTimer = setTimeout(() => {
                                props.history.push("/logins");
                            }, 3000);
                        },
                        error => {
                            const resMessage =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();

                            toast.error(resMessage);
                            setLoadingForm(false);
                        }
                    )
            } else {
                setButtonDisable(true);
                setLoadingForm(false);
            }
        }
    }

    return (
        <div>
            <div style={{ textAlign: 'center', marginBottom: '5vh' }}>
                <Typography.Title level={4}>
                    Şifre Yenile
                </Typography.Title>
            </div>
            <Form
                name="doctorChangePassword"
                labelCol={{
                    xs: { offset: 0 },
                    sm: { offset: 0, span: 6 },
                    md: { offset: 1, span: 6 },
                    lg: { offset: 4, span: 4 },
                }}
                wrapperCol={{
                    xs: { offset: 0 },
                    sm: { offset: 0, span: 12 },
                    md: { offset: 0, span: 10 },
                    lg: { offset: 0, span: 8 },
                }}
                onFinish={onFinish}
                onValuesChange={(changedValues, values) => onFormChange(changedValues, values)}
                autoComplete="off"
            >
                {/* New Password */}
                <Form.Item
                    label="Yeni Şifre"
                    name="newPassword"
                    rules={[
                        {
                            required: true,
                            message: 'Lütfen Yeni Şifrenizi Giriniz!',
                            min: 4,
                            max: 16,
                        },
                    ]}
                >
                    <Input.Password
                        placeholder="Lütfen Yeni Şifrenizi Giriniz!"
                        maxLength={16}
                        disabled={formItemDisable}
                    />
                </Form.Item>

                {/* New Password Verify */}
                <Form.Item
                    label="Yeni Şifre Doğrula"
                    name="verifyNewPassword"
                    validateStatus={equalPassword === true ? "success" : "error"}
                    rules={[
                        {
                            required: true,
                            message: 'Lütfen Yeni Şifrenizi Doğrulamak için Tekrar Giriniz!',
                            min: 4,
                            max: 16,
                        },
                    ]}
                >
                    <Input.Password
                        placeholder="Lütfen Yeni Şifrenizi Doğrulamak için Tekrar Giriniz!"
                        maxLength={16}
                        disabled={formItemDisable}
                    />
                </Form.Item>

                {/* Submit */}
                <Form.Item
                    wrapperCol={{
                        xs: { offset: 0 },
                        sm: { offset: 6, span: 12 },
                        md: { offset: 7, span: 10 },
                        lg: { offset: 8, span: 8 },
                    }}
                >
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                        loading={loadingForm}
                        disabled={buttonDisable}
                    >
                        Kaydet
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}


const mapState = (state) => ({
});

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(ParentChangePassword);