import React, { Component } from "react";

/* Components */
import EbeveynLogin from "../parent.components/login.component"
import DoktorLogin from "../doctor.components/login.component"

/* Ant Design */
import { Tabs } from 'antd';

export default class loginSelection extends Component {
    render() {
        return (
            <div>
                <Tabs defaultActiveKey="1" size={"large"} centered>
                    <Tabs.TabPane tab="Ebeveyn Girişi" key="1">
                        <EbeveynLogin history={this.props.history} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Doktor Girişi" key="2">
                        <DoktorLogin history={this.props.history} />
                    </Tabs.TabPane>
                </Tabs>
            </div>
        );
    }
}