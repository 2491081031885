import React, { useState, useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import moment from 'moment';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';

/* for "axios" request cancelation */
import axios from 'axios';

/* Helpers */
import 'moment/locale/tr';

/* Services */
import DoctorService from "../../../../services/doctor.service";
import AuthService from "../../../../services/auth.service";

/* Ant Design */
import { Typography, Row, Col, Button, Space, Input, Spin, List, Card, Tag } from 'antd';

/* for "axios" request cancelation check variable */
let source;

const DoctorPatientOpinions = (props) => {
    const [opinions, setOpinions] = useState([]);
    const [loadingOpinions, setLoadingOpinions] = useState(false);
    const [search, setSearch] = useState();
    const [page, setPage] = useState();
    const [totalCount, setTotalCount] = useState(0);

    //for Routing Parameters
    let { patientId } = useParams();

    const getExactQuery = useCallback((() => {
        const query = new URLSearchParams(props.location.search);
        const reqSearch = query.get('search');
        const reqPage = query.get('page');
        const reqLimit = query.get('limit');
        const exactQuery = {};

        reqSearch && (exactQuery.search = reqSearch);
        reqPage && (exactQuery.page = reqPage);
        reqLimit && (exactQuery.limit = reqLimit);

        return exactQuery;
    }), [props.location.search]);

    const Search = (value) => {
        setSearch(value);
        setOpinions([]);

        let query = getExactQuery();
        query.page = 1;
        query.search = value;
        getOpinions(query, source.token);
    }

    const getOpinions = (query, cancelToken) => {
        setLoadingOpinions(true);
        DoctorService.getPatientOpinions(
            patientId,
            query,
            cancelToken
        ).then(
            res => {
                let query = '?page=' + res.data.page;
                res.data.search && (query += "&search=" + res.data.search);

                props.history.push({
                    pathname: '',
                    search: query
                });

                //Eğer görüş gelmiş ve toplam görüş sayısı büyük veya eşit ise eski görüşler ile birleştir
                if (res.data.opinions && ([...opinions, ...res.data.opinions].length <= res.data.totalCount)) {
                    setOpinions(firstDatas => [...firstDatas, ...res.data.opinions]);
                }
                //Eğer search var, görüş gelmiş ve gelen sayfa ilk sayfa ise 'opinions' state'ini yeni gelen veri ile değiştir
                if (res.data.search && res.data.opinions && res.data.page === 1) {
                    setOpinions(res.data.opinions);
                }

                setLoadingOpinions(false);
                setSearch(res.data.Search);
                setPage(res.data.Page);
                setTotalCount(res.data.totalCount);
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                if (resMessage === "Cancel" || resMessage === "Gösterilecek Başka Görüş Kalmadı!") {
                    setLoadingOpinions(false);
                } else {
                    setTotalCount(0);
                    setLoadingOpinions(false);
                    toast.error(resMessage);
                }
            }
        )
    }

    useEffect(() => {
        source = axios.CancelToken.source();

        setLoadingOpinions(true);
        getOpinions(getExactQuery, source.token);

        return () => {
            if (source) {
                source.cancel();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getNextGames = () => {
        getOpinions(
            {
                page: parseInt(new URLSearchParams(props.location.search).get('page')) + 1,
                search: new URLSearchParams(props.location.search).get('search')
            },
            source.token
        )
    }

    return (
        <div>
            <div>
                <Typography.Title style={{ textAlign: 'center' }}>
                    Hasta Hakkındaki Görüşler
                </Typography.Title>
                {/* for Search */}
                <Space direction="horizontal" align="center" style={{ width: '100%', justifyContent: 'center' }}>
                    <Input.Search
                        placeholder="Görüş Açıklaması İçinde Arama Yapabilirsiniz."
                        defaultValue={search}
                        onSearch={Search}
                        enterButton
                        allowClear={true}
                        style={{
                            width: 380
                        }}
                        loading={loadingOpinions}
                    />
                </Space>
                <div>
                    <Button
                        type="primary"
                        size="large"
                        style={{
                            float: 'right',
                            marginTop: 20,
                            marginBottom: 20
                        }}
                        onClick={() => {
                            props.history.push('/doctor/patients/actions/my-patients/' + patientId + '/opinions/add');
                        }}>
                        + Ekle
                    </Button>
                </div>
                <div style={{ clear: 'both' }}></div>
                {opinions &&
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={10} md={14} lg={14} xl={14} xxl={6}>
                            <Typography.Title level={5}><b>Hasta İsmi:</b>&nbsp;{opinions.length > 0 && opinions[0].HastaID.AdSoyad}</Typography.Title>
                        </Col>
                        <Col xs={0} sm={2} md={2} lg={2} xl={2} xxl={14}></Col>
                        <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={4}>
                            <Typography.Title level={5} style={{ textAlign: 'end', marginTop: '1vh' }}><b>{opinions.length} / {totalCount}</b> Adet Kayda Ulaşıldı!</Typography.Title>
                        </Col>
                    </Row>
                }
                <InfiniteScroll
                    dataLength={totalCount}
                    next={getNextGames}
                    hasMore={((totalCount > opinions.length) && (opinions.length > 0)) ? true : false}
                    style={{ overflowX: "hidden" }}
                    endMessage={
                        (totalCount === opinions.length) && (opinions.length > 0) &&
                        <p style={{ textAlign: 'center' }}>
                            <b>Listenin Sonuna Ulaştınız!</b>
                        </p>
                    }
                >
                    <List
                        grid={{
                            gutter: 16,
                            xs: 1,
                            sm: 2,
                            md: 4,
                            lg: 4,
                            xl: 6,
                            xxl: 3,
                        }}
                        loading={loadingOpinions}
                        dataSource={opinions && opinions}
                        renderItem={opinion => (
                            <List.Item key={opinion._id}>
                                <Card
                                    title={
                                        <Typography>
                                            <Typography.Title level={5}>{moment(new Date(opinion.KayitTarihi)).format('LL')}</Typography.Title>
                                            {opinion.DoktorID._id === AuthService.getCurrentDoctor()._id ?
                                                <Tag color={"green"}>Sizin Tarafınızdan Oluşturuldu!</Tag>
                                                :
                                                <Tag color={"red"}>Başka Bir Doktor Tarafından Oluşturuldu!</Tag>
                                            }
                                        </Typography>
                                    }
                                >
                                    <Typography.Title level={5}>
                                        Açıklama
                                    </Typography.Title>
                                    <Typography.Text>
                                        {opinion.Aciklama}
                                    </Typography.Text>
                                </Card>
                            </List.Item>
                        )}
                    />
                </InfiniteScroll>
            </div>
        </div >
    )
}


const mapState = (state) => ({
});

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(DoctorPatientOpinions);