import React, { useState, useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import moment from 'moment';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';

/* for "axios" request cancelation */
import axios from 'axios';

/* Helpers */
import { history } from '../../../../helpers/history';
import 'moment/locale/tr';

/* Services */
import DoctorService from "../../../../services/doctor.service";
import AuthService from "../../../../services/auth.service";

/* Ant Design */
import { Typography, Row, Col, Button, Space, Input, Spin, List, Card, Tag, Divider } from 'antd';

/* for "axios" request cancelation check variable */
let source;

const DoctorPatientDiagnosis = (props) => {
    const [diagnosis, setDiagnosis] = useState([]);
    const [loadingDiagnosis, setLoadingDiagnosis] = useState(false);
    const [search, setSearch] = useState();
    const [page, setPage] = useState();
    const [totalCount, setTotalCount] = useState(0);

    //for Routing Parameters
    let { patientId } = useParams();

    const getExactQuery = useCallback((() => {
        const query = new URLSearchParams(props.location.search);
        const reqSearch = query.get('search');
        const reqPage = query.get('page');
        const reqLimit = query.get('limit');
        const exactQuery = {};

        reqSearch && (exactQuery.search = reqSearch);
        reqPage && (exactQuery.page = reqPage);
        reqLimit && (exactQuery.limit = reqLimit);

        return exactQuery;
    }), [props.location.search]);

    const Search = (value) => {
        setDiagnosis([]);
        setSearch(value);

        let query = getExactQuery();
        query.page = 1;
        query.search = value;
        getDiagnosis(query, source.token);
    }

    const getDiagnosis = (query, cancelToken) => {
        setLoadingDiagnosis(true);
        DoctorService.getPatientDiagnosis(
            patientId,
            query,
            cancelToken
        ).then(
            res => {
                let query = '?page=' + res.data.page;
                res.data.search && (query += "&search=" + res.data.search);

                props.history.push({
                    pathname: '',
                    search: query
                });

                //Eğer görüş gelmiş ve toplam görüş sayısı büyük veya eşit ise eski görüşler ile birleştir
                if (res.data.diagnosis && ([...diagnosis, ...res.data.diagnosis].length < res.data.totalCount)) {
                    setDiagnosis(firstDatas => [...firstDatas, ...res.data.diagnosis]);
                }
                //Eğer search var, görüş gelmiş ve gelen sayfa ilk sayfa ise 'diagnosis' state'ini yeni gelen veri ile değiştir
                if (res.data.diagnosis && res.data.page === 1) {
                    setDiagnosis(res.data.diagnosis);
                }
                setLoadingDiagnosis(false);
                setSearch(res.data.Search);
                setPage(res.data.Page);
                setTotalCount(res.data.totalCount);
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                if (resMessage === "Cancel" || resMessage === "Gösterilecek Başka Teşhis Kalmadı!") {
                    setLoadingDiagnosis(false);
                } else {
                    setTotalCount(0);
                    setLoadingDiagnosis(false);
                    toast.error(resMessage);
                }
            }
        )
    }

    useEffect(() => {
        source = axios.CancelToken.source();

        setLoadingDiagnosis(true);
        getDiagnosis(getExactQuery, source.token);

        return () => {
            if (source) {
                source.cancel();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getNextDiagnosis = () => {
        getDiagnosis(
            {
                page: parseInt(new URLSearchParams(props.location.search).get('page')) + 1,
                search: new URLSearchParams(props.location.search).get('search')
            },
            source.token
        )
    }

    let countTeshis = 0;

    return (
        <div>
            <div>
                {/* Title */}
                <Typography.Title style={{ textAlign: 'center' }}>
                    Hastaya ait Teşhisler
                </Typography.Title>
                {/* for Search */}
                <Space direction="horizontal" align="center" style={{ width: '100%', justifyContent: 'center' }}>
                    <Input.Search
                        placeholder="Teşhis Araması Yapabilirsiniz."
                        defaultValue={search}
                        onSearch={Search}
                        enterButton
                        allowClear={true}
                        style={{
                            width: 380
                        }}
                        loading={loadingDiagnosis}
                    />
                </Space>
                {/* Teşhis Ekle */}
                <div>
                    <Button
                        type="primary"
                        size="large"
                        style={{
                            float: 'right',
                            marginTop: 20,
                            marginBottom: 20
                        }}
                        onClick={() => {
                            props.history.push('/doctor/patients/actions/my-patients/' + patientId + '/diagnosis/add');
                        }}>
                        + Ekle
                    </Button>
                </div>
                <div style={{ clear: 'both' }}></div>
                {/* Hasta İsmi + TotalCount */}
                {diagnosis &&
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={10} md={14} lg={14} xl={14} xxl={6}>
                            <Typography.Title level={5}><b>Hasta İsmi:</b>&nbsp;{diagnosis.length > 0 && diagnosis[0].HastaID.AdSoyad}</Typography.Title>
                        </Col>
                        <Col xs={0} sm={2} md={2} lg={2} xl={2} xxl={14}></Col>
                        <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={4}>
                            <Typography.Title level={5} style={{ textAlign: 'end', marginTop: '1vh' }}><b>{diagnosis.length} / {totalCount}</b> Adet Kayda Ulaşıldı!</Typography.Title>
                        </Col>
                    </Row>
                }
                {/* Data List */}
                <InfiniteScroll
                    dataLength={totalCount}
                    next={getNextDiagnosis}
                    hasMore={(totalCount > diagnosis.length) && (diagnosis.length > 0) ? true : false}
                    style={{ overflowX: "hidden" }}
                    endMessage={
                        (totalCount === diagnosis.length) && (diagnosis.length > 0) &&
                        <p style={{ textAlign: 'center' }}>
                            <b>Listenin Sonuna Ulaştınız!</b>
                        </p>
                    }
                >
                    <List
                        grid={{
                            gutter: 16,
                            xs: 1,
                            sm: 2,
                            md: 4,
                            lg: 4,
                            xl: 6,
                            xxl: 3,
                        }}
                        dataSource={diagnosis && diagnosis}
                        renderItem={diagnose => (
                            <List.Item key={diagnose._id}>
                                <p style={{ display: 'none' }}>{countTeshis = 0}</p>
                                <Card
                                    title={
                                        <Typography>
                                            <Typography.Title level={5}>{moment(new Date(diagnose.KayitTarihi)).format('LL')}</Typography.Title>
                                            {diagnose.DoktorID._id === AuthService.getCurrentDoctor()._id ?
                                                <Tag color={"green"}>Sizin Tarafınızdan Oluşturuldu!</Tag>
                                                :
                                                <Tag color={"red"}>Başka Bir Doktor Tarafından Oluşturuldu!</Tag>
                                            }
                                        </Typography>
                                    }
                                >
                                    <Typography.Title level={5}>Teşhisler</Typography.Title>
                                    {diagnose.Teshisler?.map(teshis => {
                                        countTeshis++;
                                        return (
                                            teshis.TeshisID &&
                                            <Typography key={teshis.TeshisID._id}>
                                                <Typography.Title level={5}>
                                                    {countTeshis}.&nbsp;
                                                    <Tag>{teshis.TeshisID.Isim}</Tag>
                                                </Typography.Title>
                                            </Typography>
                                        )
                                    })}
                                    <Divider />
                                    <Typography.Title level={5}>
                                        Açıklama
                                    </Typography.Title>
                                    <Typography.Text>
                                        {diagnose.Aciklama}
                                    </Typography.Text>
                                </Card>
                            </List.Item>
                        )}
                    />
                </InfiniteScroll>
                {(loadingDiagnosis || totalCount !== diagnosis.length) &&
                    <div style={{ textAlign: 'center' }}>
                        <Spin />
                    </div>
                }
            </div>
        </div>
    )
}


const mapState = (state) => ({
});

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(DoctorPatientDiagnosis);