import React, { useState, useEffect, useCallback } from "react";
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import moment from 'moment';

/* for "axios" request cancelation */
import axios from 'axios';

/* Services */
import DoctorService from "../../../services/doctor.service";

/* Ant Design */
import { Typography, Table, Row, Col, Button, Modal, Space, Input, Pagination } from 'antd';

/* for "axios" request cancelation check variable */
let source;

const AddPatient = (props) => {
    const [patients, setPatients] = useState([]);
    const [loadingPatients, setLoadingPatients] = useState(false);
    const [secLoading, setSecLoading] = useState(false);
    const [danger, setDanger] = useState(null);
    const [modalConfirmLoading, setModalConfirmLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [page, setPage] = useState(new URLSearchParams(props.location.search).get('page'));
    const [limit, setLimit] = useState(new URLSearchParams(props.location.search).get('limit'));
    const [search, setSearch] = useState(new URLSearchParams(props.location.search).get('search'));
    const [totalCount, setTotalCount] = useState(0);

    const patientColumns = [
        /* TCNo */
        {
            title: 'T.C. Numarası',
            dataIndex: 'TCNo',
            key: 'TCNo',
            responsive: ["xs", "sm", "md", "lg"]
        },
        /* AdSoyad */
        {
            title: 'Ad - Soyad',
            dataIndex: 'AdSoyad',
            key: 'AdSoyad',
            responsive: ["xs", "sm", "md", "lg"]
        },
        /* Cinsiyet */
        {
            title: 'Cinsiyet',
            dataIndex: 'Cinsiyet',
            key: 'Cinsiyet',
            responsive: ["md", "lg"]
        },
        /* Age */
        {
            title: 'Yaş',
            dataIndex: 'Age',
            render: (Age) => (
                ((Math.floor(Age / 12) !== 0) && (Math.floor(Age / 12) + " Yıl ")) + Math.floor(Age) % 12 + " Ay"
            ),
            key: 'Age',
            responsive: ["lg"]
        },
        /* Ebeveyn AdSoyad */
        {
            title: 'Ebeveyn Ad - Soyad',
            dataIndex: 'EbeveynAdSoyad',
            key: 'EbeveynAdSoyad',
            responsive: ["md", "lg"]
        },
        /* Yakinlik */
        {
            title: 'Yakınlık',
            dataIndex: 'Yakinlik',
            key: 'Yakinlik',
            responsive: ["md", "lg"]
        },
        /* Telefon */
        {
            title: 'Telefon',
            dataIndex: 'Telefon',
            key: 'Telefon',
            render: (Telefon) => (
                Telefon = [Telefon.slice(0, 3), " ", Telefon.slice(3, 6), " ", Telefon.slice(6, 9), " ", Telefon.slice(9, 11), " ", Telefon.slice(11, 13)].join('')
            ),
            responsive: ["lg"]
        },
        /* Actions */
        {
            title: 'İşlemler',
            dataIndex: '',
            key: 'x',
            render: (text, record) => (
                <div>
                    {((record.DoktorID === null || undefined || '') || !record.DoktorID) &&
                        <Row gutter={{ xs: 32, sm: 24, md: 16, lg: 8 }}>
                            {/* Bağlantı Ata */}
                            <Col className="gutter-row">
                                <Button
                                    block
                                    type="primary"
                                    ghost
                                    style={{ whiteSpace: "normal", height: 'auto', marginBottom: '10px' }}
                                    onClick={
                                        () => {
                                            setModalVisible(record.key)
                                        }
                                    }
                                    loading={secLoading === record.key}
                                    disabled={secLoading || (danger === record.key) || record.Requests?.length > 0}
                                >
                                    Doktoru Ol
                                </Button>
                                <Modal
                                    title={
                                        <div>
                                            <Typography.Text type="success">Doktor Atama İşlemi</Typography.Text>
                                        </div>
                                    }
                                    visible={modalVisible === record.key}
                                    onOk={(e) => handleOk(e, record.key)}
                                    confirmLoading={modalConfirmLoading}
                                    onCancel={() => handleCancel()}
                                >
                                    <p>
                                        Doktor atama talebiniz, ebeveyn onayına sunulacaktır.<br />Devam etmek istiyor musunuz?
                                    </p>
                                </Modal>
                            </Col>
                        </Row>
                    }
                </div>
            ),
            responsive: ["xs", "sm", "md", "lg"]
        },
    ];

    const getExactQuery = useCallback((() => {
        const query = new URLSearchParams(props.location.search);
        const reqSearch = query.get('search');
        const reqPage = query.get('page');
        const reqLimit = query.get('limit');
        const exactQuery = {};

        reqSearch && (exactQuery.search = reqSearch);
        reqPage && (exactQuery.page = reqPage);
        reqLimit && (exactQuery.limit = reqLimit);

        return exactQuery;
    }), [props.location.search]);

    const Search = (value) => {
        setSearch(value);

        let query = getExactQuery();
        query.search = value;
        getPatients(query, source.token);
    }

    const onChangePage = (page, limit) => {
        const query = new URLSearchParams(props.location.search);
        const queryPage = query.get('page');
        const queryLimit = query.get('limit');
        if (parseInt(page) < 1) {
            page = 1;
        }
        if (parseInt(limit) < 10) {
            limit = 10;
        }
        if ((parseInt(page) !== parseInt(queryPage)) || (parseInt(limit) !== parseInt(queryLimit))) {
            let query = getExactQuery();
            query.page = page;
            query.limit = limit;
            getPatients(query, source.token);
        }
    }

    const getPatients = useCallback(((query, cancelToken) => {
        setLoadingPatients(true);

        DoctorService.getOtherPatients(query, cancelToken)
            .then(
                res => {
                    let query = '?page=' + res.data.page + '&limit=' + res.data.limit;
                    res.data.search && (query += "&search=" + res.data.search);

                    props.history.push({
                        pathname: '',
                        search: query
                    });

                    res.data.patients?.forEach(patient => {
                        patient.key = patient._id;
                        patient.Age = moment().diff(patient.DogumTarihi, 'months', false);
                        patient.EbeveynAdSoyad = patient.EbeveynID?.AdSoyad;
                        patient.Telefon = "+90" + patient.EbeveynID?.Telefon;
                    });
                    setPatients(res.data.patients);
                    setLoadingPatients(false);
                    setPage(res.data.page);
                    setLimit(res.data.limit);
                    setSearch(res.data.search?.replace('+', ' '));
                    setTotalCount(res.data.totalCount);
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (resMessage !== "Cancel") {
                        toast.error(resMessage);
                        setLoadingPatients(false);
                    }
                }
            )
    }), [props.history]);

    /* Assign Doctor after Selection Modal Confirm */
    const assignDoctor = (patientId) => {
        setSecLoading(true);

        DoctorService.setDoctorPatient(patientId).then(
            res => {
                toast.success(res.data.message);
                setSecLoading(false);
                setDanger(patientId);
                getPatients(getExactQuery(), source.token);
            },
            err => {
                const resMessage =
                    (err.response &&
                        err.response.data &&
                        err.response.data.message) ||
                    err.message ||
                    err.toString();

                toast.error(resMessage);
                setSecLoading(false);
            }
        )
        setModalConfirmLoading(false);
    }

    /* Selection Modal Cancelation */
    const handleCancel = () => {
        if (!modalConfirmLoading) {
            setModalVisible(false);
        }
    }

    /* Selection Modal Confirm */
    const handleOk = (e, patientId) => {
        setModalConfirmLoading(true);
        assignDoctor(patientId);
        setModalVisible(false);
    }

    useEffect(() => {
        source = axios.CancelToken.source();

        setLoadingPatients(true);
        getPatients(getExactQuery, source.token);

        return () => {
            if (source) {
                source.cancel();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Typography.Title style={{ textAlign: "center" }}>Yeni Hasta Ekle</Typography.Title>
            {/* for Search */}
            <Space direction="horizontal" align="center" style={{ width: '100%', justifyContent: 'center' }}>
                <Input.Search
                    placeholder="Hasta İsmi veya T.C. No. ile Arama Yapabilirsiniz."
                    defaultValue={search}
                    onSearch={Search}
                    enterButton
                    allowClear={true}
                    style={{
                        width: 400
                    }}
                    loading={loadingPatients}
                />
            </Space>
            {patients &&
                <Typography.Title level={5} style={{ textAlign: 'end', marginTop: '1vh' }}><b>{patients.length} / {totalCount}</b> Adet Kayda Ulaşıldı!</Typography.Title>
            }
            <Table
                className='doctorPatientsTable'
                columns={patientColumns}
                dataSource={patients}
                pagination={false}
                loading={loadingPatients}
            />
            {/* for Pagination */}
            <Space direction="horizontal" align="end" style={{ width: '100%', justifyContent: 'right' }}>
                <Pagination
                    current={page}
                    defaultCurrent={1}
                    total={totalCount}
                    pageSizeOptions={[10, 20, 50]}
                    showSizeChanger={true}
                    onChange={(page, limit) => onChangePage(page, limit)}
                    style={{ marginTop: '16px' }}
                />
            </Space>
        </div>
    )
}

const mapState = (state) => ({
    doctor: state.doctor,
});
const mapDispatch = (dispatch) => ({
    doctorDispatch: dispatch.doctor
});

export default connect(mapState, mapDispatch)(AddPatient)