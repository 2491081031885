import React, { Component } from "react";
import { connect } from 'react-redux'

/* Services */
import AuthService from "../../../services/auth.service";

/* Ant Design */
import { Typography, Card, Divider, Button, Col, Row } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

const cardOneStyle = {
    textAlign: 'center',
    backgroundColor: '#fafafa'
};

const cardTwoStyle = {
    textAlign: 'center',
    backgroundColor: '#f0f0f0'
};

const buttonStyle = {
    width: '100%',
    height: '5vh',
    borderRadius: '20px',
    boxShadow: 'none',
}

class Actions extends Component {
    componentDidMount() {
        const currentDoctor = AuthService.getCurrentDoctor();
        this.props.doctorDispatch.setCurrentDoctor(currentDoctor);
    }

    render() {
        return (
            <div>
                <Typography.Title level={1} style={{ textAlign: "center" }}>Hasta İşlemleri</Typography.Title>
                <Divider />
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12} lg={12} xl={8} xxl={8}>
                        <Card
                            onClick={(e) => { this.props.history.push('/doctor/patients/actions/search') }}
                            style={cardOneStyle}
                            hoverable
                        >
                            <Card.Meta
                                title={<Typography.Title level={3}>Hasta Ara</Typography.Title>}
                                description="Bağlantılı olduğunuz hastayı arayabilirsiniz."
                            />
                            <Divider />
                            <Button
                                onClick={(e) => { this.props.history.push('/doctor/patients/actions/search') }}
                                size={"large"}
                                style={buttonStyle}
                                icon={<ArrowRightOutlined />}
                            />
                        </Card>
                    </Col>
                    <Col xs={24} md={12} lg={12} xl={8} xxl={8}>
                        <Card
                            onClick={(e) => { this.props.history.push('/doctor/patients/actions/my-patients') }}
                            style={cardTwoStyle}
                            hoverable
                        >
                            <Card.Meta
                                title={<Typography.Title level={3}>Mevcut Hastalarım</Typography.Title>}
                                description="Bağlantılı olduğunuz tüm hastalarınızı görüntüleyebilirsiniz."
                            />
                            <Divider />
                            <Button
                                onClick={(e) => { this.props.history.push('/doctor/patients/actions/my-patients') }}
                                size={"large"}
                                style={buttonStyle}
                                icon={<ArrowRightOutlined />}
                            />
                        </Card>
                    </Col>
                    <Col xs={24} md={12} lg={12} xl={8} xxl={8}>
                        <Card
                            onClick={(e) => { this.props.history.push('/doctor/patients/actions/add') }}
                            style={cardOneStyle}
                            hoverable
                        >
                            <Card.Meta
                                title={<Typography.Title level={3}>Yeni Hasta ile Bağlantı Kur</Typography.Title>}
                                description="Yeni bir hasta ile bağlantı kurabilirsiniz."
                            />
                            <Divider />
                            <Button
                                onClick={(e) => { this.props.history.push('/doctor/patients/actions/add') }}
                                size={"large"}
                                style={buttonStyle}
                                icon={<ArrowRightOutlined />}
                            />
                        </Card>
                    </Col>
                    <Col xs={24} md={12} lg={12} xl={8} xxl={8}>
                        <Card
                            onClick={(e) => { this.props.history.push('/doctor/patients/actions/my-old-patients') }}
                            style={cardTwoStyle}
                            hoverable
                        >
                            <Card.Meta
                                title={<Typography.Title level={3}>Eski Hastalarım</Typography.Title>}
                                description="Bağlantılı olduğunuz tüm eski hastalarınızı görüntüleyebilirsiniz."
                            />
                            <Divider />
                            <Button
                                onClick={(e) => { this.props.history.push('/doctor/patients/actions/my-old-patients') }}
                                size={"large"}
                                style={buttonStyle}
                                icon={<ArrowRightOutlined />}
                            />
                        </Card>
                    </Col>
                    <Col xs={24} md={12} lg={12} xl={8} xxl={8}>
                        <Card
                            onClick={(e) => { this.props.history.push('/doctor/patients/actions/prescriptions/search') }}
                            style={cardOneStyle}
                            hoverable
                        >
                            <Card.Meta
                                title={<Typography.Title level={3}>Reçete Ara</Typography.Title>}
                                description="Bağlantılı olduğunuz tüm hastanıza ait reçeteyi arayabilirsiniz."
                            />
                            <Divider />
                            <Button
                                onClick={(e) => { this.props.history.push('/doctor/patients/actions/prescriptions/search') }}
                                size={"large"}
                                style={buttonStyle}
                                icon={<ArrowRightOutlined />}
                            />
                        </Card>
                    </Col>
                </Row>
            </div >
        );
    }
}

const mapState = (state) => ({
    doctor: state.doctor,
    userDoctor: state.doctor.userDoctor
});
const mapDispatch = (dispatch) => ({
    doctorDispatch: dispatch.doctor
});

export default connect(mapState, mapDispatch)(Actions)