import React from 'react';
import { Route } from 'react-router-dom';

/* Common Components */
import registerSelection from '../components/common.components/registerSelection.component';
import loginSelection from '../components/common.components/loginSelection.component';
import countComp from '../components/common.components/count.component';

import DoctorRegisterVerify from '../components/doctor.components/verify';
import DoctorForgotPassword from '../components/doctor.components/forgotPassword';
import DoctorChangePassword from '../components/doctor.components/changePassword';
import ParentRegisterVerify from '../components/parent.components/verify';
import ParentForgotPassword from '../components/parent.components/forgotPassword';
import ParentChangePassword from '../components/parent.components/changePassword';

/* Helper's Components */
import page404 from '../components/helper.components/page404.component';
import loadingComp from '../components/helper.components/loadingComp.component';

const CommonRoutes = [
    <Route exact path="/count" component={countComp} key="countComp" />,
    <Route exact path="/logins" component={loginSelection} key="loginSelection" />,
    <Route exact path="/registers" component={registerSelection} key="registerSelection" />,
    <Route exact path="/404" component={page404} key="page404" />,
    <Route exact path="/parent" component={loadingComp} key="parent" />,
    <Route exact path="/doctor" component={loadingComp} key="doctor" />,

    <Route exact path="/doctor/auth/verify" component={DoctorRegisterVerify} key="DoctorRegisterVerify" />,
    <Route exact path="/doctor/forgot-password" component={DoctorForgotPassword} key="DoctorForgotPassword" />,
    <Route exact path="/doctor/change-password" component={DoctorChangePassword} key="DoctorChangePassword" />,
    <Route exact path="/parent/auth/verify" component={ParentRegisterVerify} key="ParentRegisterVerify" />,
    <Route exact path="/parent/forgot-password" component={ParentForgotPassword} key="ParentForgotPassword" />,
    <Route exact path="/parent/change-password" component={ParentChangePassword} key="ParentChangePassword" />,
];

export default CommonRoutes;