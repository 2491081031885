import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from 'moment';
import { toast } from 'react-toastify';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

/* for "axios" request cancelation */
import axios from 'axios';

/* Services */
import AuthService from "../../services/auth.service";
import ParentService from "../../services/parent.service";

/* Ant Design */
import { Typography, Spin, Select, Divider, Row, Col, Radio, Skeleton } from 'antd';

let source;

const Home = (props) => {
    const [Parent, setParent] = useState({});
    const [LoadingParent, setLoadingParent] = useState(true);
    const [Patients, setPatients] = useState([]);
    const [LoadingPatients, setLoadingPatients] = useState(true);
    const [SelectedPatient, setSelectedPatient] = useState(null);
    const [SelectedRange, setSelectedRange] = useState(null);
    const [PatientGameCount, setPatientGameCount] = useState({});
    const [LoadingPatientGameCount, setLoadingPatientGameCount] = useState(true);
    const [CalendarRangeValues, setCalendarRangeValues] = React.useState([
        { value: '1', type: 'w', name: '1 Hafta' },
        { value: '1', type: 'm', name: '1 Ay' },
        { value: '3', type: 'm', name: '3 Ay' },
    ]);
    const [CalendarRange, setCalendarRange] = React.useState(CalendarRangeValues[0].value + CalendarRangeValues[0].type);

    useEffect(() => {
        source = axios.CancelToken.source();

        setParent(AuthService.getCurrentParent());
        setLoadingParent(false);
        getPatients();

        return () => {
            if (source) {
                source.cancel();
            }
        }
    }, []);

    const getPatients = () => {
        setLoadingPatients(true);

        ParentService.getPatientsIdName(source.token).then(
            res => {
                setPatients([...res.data]);
                setLoadingPatients(false);
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                if (resMessage === "Cancel") {
                    setLoadingPatients(false);
                } else {
                    setPatients([]);
                    setLoadingPatients(false);
                    toast.error(resMessage);
                }
            });
    };

    useEffect(() => {
        if (SelectedPatient !== Patients[0]?._id) {
            setSelectedPatient(Patients[0]?._id);
        }
    }, [Patients]);

    useEffect(() => {
        if (SelectedPatient) {
            if (CalendarRange) {
                patientDailyPlayGamesCount(SelectedPatient, CalendarRange);
            } else {
                patientDailyPlayGamesCount(SelectedPatient, "1w");
            }
        }
    }, [SelectedPatient, CalendarRange]);

    function onChange(value) {
        setSelectedPatient(value);
    }

    function onSearch(val) {
        console.log('search:', val);
    }

    const patientDailyPlayGamesCount = (patientId, range) => {
        console.log('PatientId:' + patientId + ', Range: ' + range);
        setLoadingPatientGameCount(true);

        ParentService.getPatientDailyPlayGamesCount(patientId, range, source.token).then(
            res => {
                res.data?.map((data, index) => {
                    if (Math.floor(data.TotalPlayedTime / 60) === 0 && data.TotalPlayedTime > 0) {
                        res.data[index].TotalPlayedTime = Math.floor(data.TotalPlayedTime / 60) + 1;
                    } else {
                        res.data[index].TotalPlayedTime = Math.floor(data.TotalPlayedTime / 60);
                    }
                });
                setPatientGameCount([...res.data]);
                setLoadingPatientGameCount(false);
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                if (resMessage === "Cancel") {
                    setLoadingPatientGameCount(false);
                } else {
                    setPatientGameCount({});
                    setLoadingPatientGameCount(false);
                    toast.error(resMessage);
                }
            });
    };

    function handleCalendarSizeChange(e) {
        setCalendarRange(e.target.value);
    }

    return (
        <div>
            <Row gutter={[16, 32]} style={{ width: '100%' }}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Typography.Title level={3} style={{ textAlign: "start" }}>Hasta Aktivite Geçmişi</Typography.Title>
                    <Divider />
                    <Row gutter={[16, 32]} style={{ width: '100%', marginBottom: '2vh' }}>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            {(!LoadingPatients && Patients) &&
                                <div>
                                    <Typography.Title level={5} style={{ textAlign: "start" }}>
                                        Hasta Seçiniz:{' '}
                                        <Select
                                            showSearch
                                            placeholder="Lütfen Hasta Seçiniz..."
                                            optionFilterProp="children"
                                            onChange={onChange}
                                            onSearch={onSearch}
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            defaultValue={Patients[0]?._id}
                                            style={{ minWidth: '25%' }}
                                        >
                                            {Patients?.map(patient =>
                                                <Select.Option key={patient._id} value={patient._id}>{patient.AdSoyad}</Select.Option>
                                            )}
                                        </Select>
                                    </Typography.Title>
                                </div>
                            }
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <div style={{ textAlign: 'end' }}>
                                <Radio.Group value={CalendarRange} onChange={handleCalendarSizeChange}>
                                    {CalendarRangeValues.map(value =>
                                        <Radio.Button key={value.value + value.type} value={value.value + value.type}>{value.name}</Radio.Button>
                                    )}
                                </Radio.Group>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* Haftalık Oyun Oynama Bilgileri */}
            {Patients.length > 0 ?
            <Row gutter={[16, 32]} style={{ width: '100%' }}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Skeleton loading={LoadingPatientGameCount} active>
                        <div>
                            <ResponsiveContainer width={'100%'} height={400}>
                                <AreaChart
                                    width={500}
                                    height={400}
                                    data={PatientGameCount}
                                    margin={{
                                        top: 5,
                                        bottom: 5,
                                    }}
                                >
                                    <defs>
                                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis
                                        dataKey="date"
                                        tickFormatter={(value, index) => {
                                            if (value) {
                                                value = value.split(' ');
                                                return value[0] + ' ' + value[1];
                                            }
                                        }}
                                    />
                                    <YAxis />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip cursor={{ fill: 'transparent' }} />
                                    <Area type="monotone" dataKey="count" name="Toplam Oturum Sayısı" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                                    <Area type="monotone" dataKey="TotalPlayedTime" name="Toplam Oynama Süresi(dk)" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                    </Skeleton>
                </Col>
            </Row> : <div>Hasta Kaydı Bulunamadı</div>
            }
        </div>
    )
}

const mapState = (state) => ({
});

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(Home);