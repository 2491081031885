import React, { useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {appConstants} from "../../utils/constants";

const GoogleRecaptcha = (props) => {
    const recaptchaRef = React.useRef();

    useEffect(() => {
        if (props.reset === true) {
            recaptchaRef.current.reset();
            props.changeResetRecaptcha(false);
            props.changeDisableSubmitButton(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.reset]);

    const onChange = (value) => {
        if (value !== null) {
            props.changeDisableSubmitButton(false);
        } else {
            props.changeDisableSubmitButton(true);
        }
    }

    return (
        <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={appConstants.GOOGLE_RECAPTCHA}
            onChange={onChange}
            hl="tr"
            style={{ float: 'left' }}
        />
    )
}

export default GoogleRecaptcha;