export const game = {
    state: {
        games: [],
        gameObject: {},
        loading: false
    },
    reducers: {
        setLoading(state, loading) {
            return {
                ...state,
                loading: loading
            }
        },
        setGameObject(state, payload) {
            return {
                ...state,
                gameObject: {
                    ...state.gameObject,
                    ...payload
                }
            }
        },
        setGames(state, payload) {
            state.games = payload;
            return { ...state }
        },
        clearGames(state) {
            return {
                ...state,
                games: [],
                gameObject: {},
                loading: false
            }
        },
    },
};