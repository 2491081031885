import React, { Component } from "react";
import { connect } from 'react-redux'
import axios from 'axios';
import { toast } from 'react-toastify';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, LabelList } from 'recharts';

/* Services */
import AuthService from "../../services/auth.service";
import DoctorService from "../../services/doctor.service";

/* Ant Design */
import { Typography, Spin, Card, Skeleton, Row, Col, Divider, List, Avatar } from 'antd';
import { SettingOutlined, UserOutlined } from '@ant-design/icons';
import Button from "antd/es/button";

/* for "axios" request cancelation check variable */
let source;

class Home extends Component {
    constructor(props) {
        super(props);
        this.getPatientCount = this.getPatientCount.bind(this);
        this.getFormerPatientCount = this.getFormerPatientCount.bind(this);
        this.getActiveReqsCount = this.getActiveReqsCount.bind(this);
        this.getDiagnosisGraphData = this.getDiagnosisGraphData.bind(this);
        this.getPatientsAgeRange = this.getPatientsAgeRange.bind(this);
        this.getPrescriptionsGames = this.getPrescriptionsGames.bind(this);
        this.getWhoDontPlayGames = this.getWhoDontPlayGames.bind(this);

        this.state = {
            currentDoctor: null,
            mevcutHasta: 0,
            loadingMevcutHasta: true,
            eskiHasta: 0,
            loadingEskiHasta: true,
            aktifTalepler: 0,
            doktorTalepler: 0,
            ebeveynTalepler: 0,
            loadingAktifTalepler: true,
            teshislerGrafikData: [],
            loadingTeshislerGrafikData: true,
            yasAraligiData: [],
            loadingYasAraligiData: true,
            receteOyunlarData: [],
            loadingReceteOyunlarData: true,
            oynamayanHastalarData: [],
            loadingOynamayanHastalarData: true,
        }
    }

    componentDidMount() {
        source = axios.CancelToken.source();
        const currentDoctor = AuthService.getCurrentDoctor();

        this.setState({ currentDoctor: currentDoctor });
        this.props.doctorDispatch.setCurrentDoctor(currentDoctor);

        this.getDiagnosisGraphData();
        this.getPatientCount();
        this.getFormerPatientCount();
        this.getActiveReqsCount();
        this.getPatientsAgeRange();
        this.getPrescriptionsGames();
        this.getWhoDontPlayGames();
    }

    getPatientCount() {
        DoctorService.getDoctorPatientsCount(source.token)
            .then(
                res => {
                    this.setState({
                        mevcutHasta: res.data.count,
                        loadingMevcutHasta: false,
                    });
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (resMessage !== 'Cancel') {
                        toast.error(resMessage, { autoClose: 3000 });
                        this.setState({ loadingMevcutHasta: false });
                    }
                })
    }

    getFormerPatientCount() {
        DoctorService.getDoctorFormerPatientsCount(source.token)
            .then(
                res => {
                    this.setState({
                        eskiHasta: res.data.count,
                        loadingEskiHasta: false,
                    });
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (resMessage !== 'Cancel') {
                        toast.error(resMessage, { autoClose: 3000 });
                        this.setState({ loadingEskiHasta: false });
                    }
                })
    }

    getActiveReqsCount() {
        DoctorService.getDoctorActiveReqsCount(source.token)
            .then(
                res => {
                    this.setState({
                        aktifTalepler: res.data.count,
                        doktorTalepler: res.data.doctorReqCount,
                        ebeveynTalepler: res.data.parentReqCount,
                        loadingAktifTalepler: false,
                    });
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (resMessage !== 'Cancel') {
                        toast.error(resMessage, { autoClose: 3000 });
                        this.setState({ loadingAktifTalepler: false });
                    }
                })
    }

    getDiagnosisGraphData() {
        DoctorService.getDoctorDiagnosisGraphData(source.token)
            .then(
                res => {
                    this.setState({
                        teshislerGrafikData: [...res.data],
                        loadingTeshislerGrafikData: false,
                    });
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (resMessage !== 'Cancel') {
                        toast.error(resMessage, { autoClose: 3000 });
                        this.setState({ loadingTeshislerGrafikData: false });
                    }
                })
    }

    getPatientsAgeRange() {
        DoctorService.getDoctorPatientsAgeRange(source.token)
            .then(
                res => {
                    this.setState({
                        yasAraligiData: [...res?.data],
                        loadingYasAraligiData: false,
                    });
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (resMessage !== 'Cancel') {
                        toast.error(resMessage, { autoClose: 3000 });
                        this.setState({ loadingYasAraligiData: false });
                    }
                })
    }

    getPrescriptionsGames() {
        DoctorService.getDoctorPrescriptionsGames(source.token)
            .then(
                res => {
                    this.setState({
                        receteOyunlarData: [...res.data],
                        loadingReceteOyunlarData: false,
                    });
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (resMessage !== 'Cancel') {
                        toast.error(resMessage, { autoClose: 3000 });
                        this.setState({ loadingReceteOyunlarData: false });
                    }
                })
    }

    getWhoDontPlayGames() {
        DoctorService.getDoctorsWhoDontPlayGames(source.token)
            .then(
                res => {
                    this.setState({
                        oynamayanHastalarData: [...res.data],
                        loadingOynamayanHastalarData: false,
                    });
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (resMessage !== 'Cancel') {
                        toast.error(resMessage, { autoClose: 3000 });
                        this.setState({ loadingOynamayanHastalarData: false });
                    }
                })
    }

    componentWillUnmount() {
        /* Component Unmount edilirken cancel triggerı tetiklenir */
        if (source) {
            source.cancel();
        }
    }

    render() {
        return (
            <div>
                <Spin spinning={!this.state.currentDoctor}>
                    <div>
                        <Typography.Title level={1} style={{ textAlign: "center" }}>Anasayfa</Typography.Title>
                        <Divider />
                        {/* Hasta Bilgileri */}
                        <Typography.Title level={3} style={{ textAlign: "start" }}>Hasta Bilgileri</Typography.Title>
                        <Row gutter={[16, 32]} style={{ width: '100%' }}>
                            {/* Toplam Hasta */}
                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                <Card
                                    style={{ /* width: 300, */ marginTop: 16 }}
                                    actions={[
                                        <div>
                                            <Typography.Title level={5}>
                                                <Button>
                                                </Button>
                                            </Typography.Title>
                                        </div>,
                                    ]}
                                    hoverable={true}
                                >
                                    <Skeleton loading={this.state.loadingMevcutHasta && this.state.loadingEskiHasta} active>
                                        <Card.Meta
                                            title="Toplam Hasta"
                                            description={<Typography.Title level={2}>{this.state.mevcutHasta + this.state.eskiHasta}</Typography.Title>}
                                        />
                                    </Skeleton>
                                </Card>
                            </Col>
                            {/* Mevcut Hasta */}
                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                <Card
                                    style={{ /* width: 300, */ marginTop: 16 }}
                                    actions={[
                                        <div>
                                            <Typography.Title level={5}>
                                                <Button
                                                    onClick={() => this.props.history.push("/doctor/patients/actions/my-patients")}
                                                    disabled={this.state.mevcutHasta <= 0}
                                                >
                                                    <SettingOutlined key="setting" />
                                                    {' '}Görüntüle
                                                </Button>
                                            </Typography.Title>
                                        </div>,
                                    ]}
                                    hoverable={true}
                                >
                                    <Skeleton loading={this.state.loadingMevcutHasta} active>
                                        <Card.Meta
                                            title="Mevcut Hasta"
                                            description={<Typography.Title level={2}>{this.state.mevcutHasta}</Typography.Title>}
                                        />
                                    </Skeleton>
                                </Card>
                            </Col>
                            {/* Eski Hasta */}
                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                <Card
                                    style={{ /* width: 300, */ marginTop: 16 }}
                                    actions={[
                                        <div>
                                            <Typography.Title level={5}>
                                                <Button
                                                    onClick={() => (this.state.eskiHasta > 0) && this.props.history.push("doctor/patients/actions/my-old-patients")}
                                                    disabled={this.state.eskiHasta <= 0}
                                                >
                                                    <SettingOutlined key="setting" />
                                                    {' '}Görüntüle
                                                </Button>
                                            </Typography.Title>
                                        </div>,
                                    ]}
                                    hoverable={true}
                                >
                                    <Skeleton loading={this.state.loadingEskiHasta} active>
                                        <Card.Meta
                                            title="Eski Hasta"
                                            description={<Typography.Title level={2}>{this.state.eskiHasta}</Typography.Title>}
                                        />
                                    </Skeleton>
                                </Card>
                            </Col>
                        </Row>
                        <Divider />
                        {/* Talep Bilgileri */}
                        <Typography.Title level={3} style={{ textAlign: "start" }}>Talep Bilgileri</Typography.Title>
                        <Row gutter={[16, 32]} style={{ width: '100%' }}>
                            {/* Aktif Talepler */}
                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                <Card
                                    style={{ /* width: 300, */ marginTop: 16 }}
                                    actions={[
                                        <div>
                                            <Typography.Title level={5}>
                                                <Button>
                                                </Button>
                                            </Typography.Title>
                                        </div>,
                                    ]}
                                    hoverable={true}
                                >
                                    <Skeleton loading={this.state.loadingAktifTalepler} active>
                                        <Card.Meta
                                            title="Aktif Talepler"
                                            description={<Typography.Title level={2}>{this.state.aktifTalepler}</Typography.Title>}
                                        />
                                    </Skeleton>
                                </Card>
                            </Col>
                            {/* Doktor Tarafından İletilen Talepler */}
                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                <Card
                                    style={{ /* width: 300, */ marginTop: 16 }}
                                    actions={[
                                        <div>
                                            <Typography.Title level={5}>
                                                <Button>
                                                </Button>
                                            </Typography.Title>
                                        </div>,
                                    ]}
                                    hoverable={true}
                                >
                                    <Skeleton loading={this.state.loadingAktifTalepler} active>
                                        <Card.Meta
                                            title="Doktor Tarafından İletilen Talepler"
                                            description={<Typography.Title level={2}>{this.state.doktorTalepler}</Typography.Title>}
                                        />
                                    </Skeleton>
                                </Card>
                            </Col>
                            {/* Ebeveyn Tarafından İletilen Talepler */}
                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                <Card
                                    style={{ /* width: 300, */ marginTop: 16 }}
                                    actions={[
                                        <div>
                                            <Typography.Title level={5}>
                                                <Button
                                                    onClick={() => (this.state.ebeveynTalepler > 0) && this.props.history.push("doctor/requests")}
                                                    disabled={this.state.ebeveynTalepler <= 0}
                                                >
                                                    <SettingOutlined key="setting" />
                                                    {' '}Görüntüle
                                                </Button>
                                            </Typography.Title>
                                        </div>,
                                    ]}
                                    hoverable={true}
                                >
                                    <Skeleton loading={this.state.loadingAktifTalepler} active>
                                        <Card.Meta
                                            title="Ebeveyn Tarafından İletilen Talepler"
                                            description={<Typography.Title level={2}>{this.state.ebeveynTalepler}</Typography.Title>}
                                        />
                                    </Skeleton>
                                </Card>
                            </Col>
                        </Row>
                        <Divider />
                        {/* Teşhis Bilgileri */}
                        <Row gutter={[16, 32]} style={{ width: '100%' }}>
                            {/* Aktif Talepler */}
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Spin spinning={this.state.loadingTeshislerGrafikData}>
                                    <div>
                                        <Typography.Title level={3} style={{ textAlign: "start" }}>Teşhis Bilgileri</Typography.Title>
                                        <br />
                                        <ResponsiveContainer width={'100%'} height={400}>
                                            <BarChart
                                                width={500}
                                                height={400}
                                                data={this.state.teshislerGrafikData}
                                                margin={{
                                                    top: 5,
                                                    bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis
                                                    dataKey="TeshisAdi"
                                                    tickFormatter={(value, index) => {
                                                        const limit = 20; // maximum character
                                                        if (value.length < limit) return value;
                                                        return `${value.length && value.substring(0, limit)}...`;
                                                    }}
                                                    tick={this.state.teshislerGrafikData.length ? true : false}
                                                />
                                                <YAxis tickCount={3} />
                                                <Tooltip cursor={{ fill: 'transparent' }} />
                                                <Bar dataKey="Count" name="Toplam Sayı" fill="#B72027">
                                                    <LabelList dataKey="TeshisAdi" position="inside" style={{ fill: "white" }} />
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                </Spin>
                            </Col>
                            {/* Yaş Aralığı */}
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Spin spinning={this.state.loadingYasAraligiData}>
                                    <div>
                                        <Typography.Title level={3} style={{ textAlign: "start" }}>Yaş Aralığı Bilgileri</Typography.Title>
                                        <br />
                                        <ResponsiveContainer width={'100%'} height={400}>
                                            <BarChart
                                                width={500}
                                                height={400}
                                                data={this.state.yasAraligiData.length && this.state.yasAraligiData}
                                                margin={{
                                                    top: 5,
                                                    bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis
                                                    dataKey="ageRange"
                                                    tick={this.state.yasAraligiData.length ? true : false}
                                                />
                                                <YAxis tickCount={3} />
                                                <Tooltip cursor={{ fill: 'transparent' }} />
                                                <Bar dataKey="Count" name="Toplam Sayı" fill="#0DA4DB" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                </Spin>
                            </Col>
                        </Row>
                        <Divider />
                        {/* Reçete Bilgileri */}
                        <Row gutter={[16, 32]} style={{ width: '100%' }}>
                            {/* Reçetelerde Yazılan Oyunlar */}
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Spin spinning={this.state.loadingReceteOyunlarData}>
                                    <div>
                                        <Typography.Title level={3} style={{ textAlign: "start" }}>Reçete Bilgileri</Typography.Title>
                                        <br />
                                        <ResponsiveContainer width={'100%'} height={400}>
                                            <BarChart
                                                width={500}
                                                height={400}
                                                data={this.state.receteOyunlarData}
                                                margin={{
                                                    top: 5,
                                                    bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis
                                                    dataKey="gameName"
                                                    tickFormatter={(value, index) => {
                                                        return ''
                                                    }}
                                                    tick={false}
                                                />
                                                <YAxis tickCount={5} />
                                                <Tooltip cursor={{ fill: 'transparent' }} />
                                                <Bar dataKey="Count" name="Toplam Sayı" fill="#B72027">
                                                    <LabelList dataKey="gameName" position="inside" style={{ fill: "white" }} />
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                </Spin>
                            </Col>
                            {/* Oyun Oynayan Hastalar */}
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Spin spinning={this.state.loadingOynamayanHastalarData}>
                                    {this.state.oynamayanHastalarData.length > 0 &&
                                        <div>
                                            <Typography.Title level={3} style={{ textAlign: "start" }}>Aktif Reçetesi Olan ve Son Haftada Oyun Oynamayan Hastalar</Typography.Title>
                                            <div
                                                id="scrollableDiv"
                                                style={{
                                                    height: 365,
                                                    overflow: 'auto',
                                                    padding: '0 16px',
                                                }}
                                            >
                                                <Row gutter={[16, 32]} style={{ width: '100%' }}>
                                                    {this.state.oynamayanHastalarData?.map(item =>
                                                        <Col xs={24} sm={12} md={12} lg={12} xl={8} key={item.patientId}>
                                                            <Card
                                                                style={{ /* width: 300, */ marginTop: 16 }}
                                                                hoverable={true}
                                                            >
                                                                <Card.Meta
                                                                    title={item.patientName}
                                                                />
                                                            </Card>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </div>
                                        </div>
                                    }
                                </Spin>
                            </Col>
                        </Row>
                    </div>
                </Spin>
            </div>
        );
    }
}

const mapState = (state) => ({
    doctor: state.doctor,
    userDoctor: state.doctor.userDoctor
});
const mapDispatch = (dispatch) => ({
    doctorDispatch: dispatch.doctor
});

export default connect(mapState, mapDispatch)(Home)