import authHeader from './auth-header';
import http from "../http-common";

class AuthService {
    /* Parent's Auth */
    parentLogin(TCNo, Sifre) {
        console.log(TCNo, Sifre);
        return http
            .post("/parent/auth/signin", {
                TCNo,
                Sifre
            })
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem("parent", JSON.stringify(response.data));
                }

                return response.data;
            });
    }

    parentRegister(parent) {
        parent.DogumTarihi = Date.parse(parent.DogumTarihi);
        return http.post("/parent/auth/signup", parent);
    }

    confirmParentVerify(token) {
        return http.get("/parent/auth/verify?token=" + token);
    }

    parentChangePassword(values) {
        return http.post("/parent/profile/password", values, { headers: authHeader() });
    }

    parentForgotPasswordSendMail(values) {
        return http.post("/parent/forgot-password", values, { headers: authHeader() });
    }

    parentForgotPasswordTokenVerify(token) {
        return http.get("/parent/forgot-password/verify-token?token=" + token, { headers: authHeader() });
    }

    parentForgotChangePassword(token, password) {
        return http.post("/parent/forgot-password/change?token=" + token, password, { headers: authHeader() });
    }

    parentEdit(parentId, parent) {
        return http.post("/parent/" + parentId + "/edit", parent, { headers: authHeader() });
    }

    getCurrentParent() {
        return JSON.parse(localStorage.getItem("parent"));
    }

    /* Doctor's Auth */
    doctorLogin(TCNo, Sifre) {
        return http
            .post("/doctor/auth/signin", {
                TCNo,
                Sifre
            }, { headers: { 'Content-Type': 'application/json', "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept" } })
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem("doctor", JSON.stringify(response.data));
                }

                return response.data;
            });
    }

    doctorRegister(doctor) {
        return http.post("/doctor/auth/signup", doctor);
    }

    confirmVerify(token) {
        return http.get("/doctor/auth/verify?token=" + token);
    }

    doctorChangePassword(values) {
        return http.post("/doctor/profile/password", values, { headers: authHeader() });
    }

    doctorForgotPasswordSendMail(values) {
        return http.post("/doctor/forgot-password", values, { headers: authHeader() });
    }

    doctorForgotPasswordTokenVerify(token) {
        return http.get("/doctor/forgot-password/verify-token?token=" + token, { headers: authHeader() });
    }

    doctorForgotChangePassword(token, password) {
        return http.post("/doctor/forgot-password/change?token=" + token, password, { headers: authHeader() });
    }

    doctorEdit(doctorId, doctor) {
        return http.post("/doctor/" + doctorId + "/edit", doctor, { headers: authHeader() });
    }

    getCurrentDoctor() {
        return JSON.parse(localStorage.getItem("doctor"));
    }

    /* Patient's Auth */
    patientRegister(patient) {
        return http.post("/patient/auth/signup", patient, { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }

    patientEdit(id, patient) {
        return http.post("/patient/" + id + "/edit", patient, { headers: authHeader() })
            .then(response => {
                return response.data;
            });
    }

    /* LogOut */
    logout() {
        if (localStorage.getItem("parent") !== null) {
            localStorage.removeItem("parent");
        }
        else if (localStorage.getItem("doctor") !== null) {
            localStorage.removeItem("doctor");
        }
    }
}

export default new AuthService();