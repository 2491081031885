import React from 'react';
import { Route } from 'react-router-dom';

/* Parent's Components */
import parentHome from '../components/parent.components/home.component';
import parentPatient from '../components/parent.components/patient.components/home.component';
import parentPatientAdd from '../components/parent.components/patient.components/add.component';
import parentPatientEdit from '../components/parent.components/patient.components/update.component';
import parentPatientDoctor from '../components/parent.components/patient.components/doctor.component';
import parentPatientAssignDoctor from '../components/parent.components/patient.components/assignDoctor.component';
import parentPatientPrescription from '../components/parent.components/patient.components/prescription.component';
import parentPatientPrescriptions from '../components/parent.components/patient.components/prescriptions.component';
import PatientGames from '../components/parent.components/games.components/games.component';
import PatientGameDetail from '../components/parent.components/games.components/detail.component';
import PatientStoreGames from '../components/parent.components/store.components/games.component/games.component';
import PatientStoreGameDetail from '../components/parent.components/store.components/games.component/detail.component';
import PatientStoreEyeTrackers from '../components/parent.components/store.components/eyetrackers.component/eyetrackers.component';
import PatientStoreEyeTrackerDetail from '../components/parent.components/store.components/eyetrackers.component/detail.component';
import PatientsGameDatas from '../components/parent.components/patient.components/patientsGameDatas.component';
import parentRequests from '../components/parent.components/reqs.component';
import parentProfile from '../components/parent.components/profile/profile';
import parentProfileEdit from '../components/parent.components/profile/edit';
import parentProfilePassword from '../components/parent.components/profile/password';

const EbeveynRoutes = [
    <Route exact path="/parent" component={parentHome} key="parentHome" />,
    <Route exact path="/parent/profile" component={parentProfile} key="parentProfile" />,
    <Route exact path="/parent/profile/:parentId/edit" component={parentProfileEdit} key="parentProfileEdit" />,
    <Route exact path="/parent/profile/password" component={parentProfilePassword} key="parentProfilePassword" />,
    <Route exact path="/parent/patients" component={parentPatient} key="parentPatient" />,
    <Route exact path="/parent/patients/add" component={parentPatientAdd} key="parentPatientAdd" />,
    <Route exact path="/parent/patients/:_id/edit" component={parentPatientEdit} key="parentPatientEdit" />,
    <Route exact path="/parent/patients/:patientId/doctor" component={parentPatientDoctor} key="parentPatientDoctor" />,
    <Route exact path="/parent/patients/:patientId/doctors" component={parentPatientAssignDoctor} key="parentPatientAssignDoctor" />,
    <Route exact path="/parent/patients/prescriptions/:prescriptionId" component={parentPatientPrescription} key="parentPatientPrescription" />,
    <Route exact path="/parent/patients/:patientId/prescriptions" component={parentPatientPrescriptions} key="parentPatientPrescriptions" />,
    <Route exact path="/parent/games" component={PatientGames} key="PatientGames" />,
    <Route exact path="/parent/games/:gameId/detail" component={PatientGameDetail} key="PatientGameDetail" />,
    <Route exact path="/parent/store/games" component={PatientStoreGames} key="PatientStoreGames" />,
    <Route exact path="/parent/store/games/:gameId/detail" component={PatientStoreGameDetail} key="PatientStoreGameDetail" />,
    <Route exact path="/parent/store/eyetrackers" component={PatientStoreEyeTrackers} key="PatientStoreEyeTrackers" />,
    <Route exact path="/parent/store/eyetracker/:eyeTrackerId/detail" component={PatientStoreEyeTrackerDetail} key="PatientStoreEyeTrackerDetail" />,
    <Route exact path="/parent/patients/gamedatas" component={PatientsGameDatas} key="PatientsGameDatas" />,
    <Route exact path="/parent/requests" component={parentRequests} key="parentRequests" />,
];

export default EbeveynRoutes;