import React, { Component } from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';

/* for "axios" request cancelation */
import axios from 'axios';

/* Helpers */
import { history } from '../../helpers/history';
import 'moment/locale/tr';

/* Services */
import DoctorService from "../../services/doctor.service";

/* Ant Design */
import { Typography, Input, Space, Dropdown, Menu, Button, Row, Col, Pagination, List, Divider, Popconfirm } from 'antd';
import { DownOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';

/* for "axios" request cancelation check variable */
let source;

const DoktorAta = {
    backgroundColor: '#e6f7ff',
    marginTop: '3vh',
    padding: '5vh 5vh',
    borderRadius: '2vh'
}
const DoktorSil = {
    backgroundColor: '#fff',
    marginTop: '3vh',
    padding: '5vh 5vh',
    borderRadius: '2vh'
}
const DisableReq = {
    backgroundColor: '#dddddd',
    marginTop: '3vh',
    padding: '5vh 5vh',
    borderRadius: '2vh'
}

class doctorReqs extends Component {
    constructor(props) {
        super(props);
        this.getExactQuery = this.getExactQuery.bind(this);
        this.getReqs = this.getReqs.bind(this);
        this.Search = this.Search.bind(this);
        this.setType = this.setType.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.doctorConfirmChoice = this.doctorConfirmChoice.bind(this);
        this.doctorDeclineChoice = this.doctorDeclineChoice.bind(this);
        this.markAsRead = this.markAsRead.bind(this);

        this.state = {
            requests: [],
            page: null,
            limit: null,
            search: new URLSearchParams(this.props.location.search).get('search'),
            type: null,
            totalCount: null,
            reqTypes: ['Tümü', 'Doktor Ata', 'Doktor Sil'],
            reqListLoading: false,
            buttonLoading: false,
        };

        history.listen((location) => {
        });
    }

    getExactQuery() {
        const query = new URLSearchParams(this.props.location.search);
        const reqSearch = query.get('search');
        const reqType = query.get('type');
        const reqPage = query.get('page');
        const reqLimit = query.get('limit');
        const exactQuery = {};

        reqType && (exactQuery.type = reqType);
        reqSearch && (exactQuery.search = reqSearch);
        reqPage && (exactQuery.page = reqPage);
        reqLimit && (exactQuery.limit = reqLimit);

        return exactQuery;
    }

    componentDidMount() {
        /* Component Mount olurken source'a token iletilir */
        source = axios.CancelToken.source();

        this.getReqs(this.getExactQuery(), source.token);
    }

    getReqs(query, cancelToken) {
        this.setState({ reqListLoading: true });
        DoctorService.getDoctorRequests(query, cancelToken)
            .then(
                res => {
                    let query = '?page=' + res.data.page + '&limit=' + res.data.limit;
                    res.data.type && (query += "&type=" + res.data.type);
                    res.data.search && (query += "&search=" + res.data.search);

                    this.props.history.push({
                        pathname: '',
                        search: query
                    });

                    window.scrollTo(0, 0);

                    this.setState({
                        requests: res.data.Requests,
                        page: res.data.page,
                        limit: res.data.limit,
                        search: res.data.search?.replace('+', ' '),
                        type: res.data.type?.replace('+', ' '),
                        totalCount: res.data.totalCount,
                        reqListLoading: false,
                        buttonLoading: false
                    });
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (resMessage !== 'Cancel') {
                        toast.error(resMessage, { autoClose: 3000 });
                        this.setState({ requests: [], reqListLoading: false });
                    }
                }
            )
    }

    Search(value) {
        this.setState({ search: value });
        let query = this.getExactQuery();
        query.search = value;
        this.getReqs(query, source.token);
    }

    setType(e, type) {
        let query = {};
        query.page = this.state.page;
        query.limit = this.state.limit;
        query.search = this.state.search;

        type === 'Doktor Ata' && (query.type = 'Doktor+Ata');
        type === 'Doktor Sil' && (query.type = 'Doktor+Sil');
        type === 'Tümü' ? this.setState({ type: null }) : this.setState({ type: type });

        this.getReqs(query, source.token);
    }

    onChangePage(page, limit) {
        const query = new URLSearchParams(this.props.location.search);
        const queryPage = query.get('page');
        const queryLimit = query.get('limit');
        if (parseInt(page) < 1) {
            page = 1;
        }
        if (parseInt(limit) < 10) {
            limit = 10;
        }
        if ((parseInt(page) !== parseInt(queryPage)) || (parseInt(limit) !== parseInt(queryLimit))) {
            let query = this.getExactQuery();
            query.page = page;
            query.limit = limit;
            this.getReqs(query, source.token);
        }
    }

    doctorConfirmChoice(reqId) {
        this.setState({ buttonLoading: true });
        DoctorService.doctorConfirmReq(reqId, source.token)
            .then(
                res => {
                    toast.success(res.data.message, { autoClose: 3000 });

                    this.getReqs(this.getExactQuery(), source.token);
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (resMessage !== 'Cancel') {
                        toast.error(resMessage, { autoClose: 3000 });
                        //TODO --> setState kaldırılıp yeniden getRequests fonksiyonu çağırılacak!
                        this.setState({ buttonLoading: false });
                    }
                })
    }

    doctorDeclineChoice(reqId) {
        this.setState({ buttonLoading: true });
        DoctorService.doctorDeclineReq(reqId, source.token)
            .then(
                res => {
                    toast.success(res.data.message, { autoClose: 3000 });

                    this.getReqs(this.getExactQuery(), source.token);
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (resMessage !== 'Cancel') {
                        toast.error(resMessage, { autoClose: 3000 });
                        //TODO --> setState kaldırılıp yeniden getRequests fonksiyonu çağırılacak!
                        this.setState({ buttonLoading: false });
                    }
                })
    }

    markAsRead(reqId) {
        this.setState({ buttonLoading: true });
        DoctorService.doctorMarkAsRead(reqId, source.token)
            .then(
                res => {
                    toast.success(res.data.message, { autoClose: 3000 });

                    this.getReqs(this.getExactQuery(), source.token);
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (resMessage !== 'Cancel') {
                        toast.error(resMessage, { autoClose: 3000 });
                        //TODO --> setState kaldırılıp yeniden getRequests fonksiyonu çağırılacak!
                        this.setState({ buttonLoading: false });
                    }
                })
    }

    componentWillUnmount() {
        /* Component Unmount edilirken cancel triggerı tetiklenir */
        if (source) {
            source.cancel();
        }
    }

    render() {
        return (
            <div>
                <Typography.Title style={{ textAlign: 'center' }}>
                    Size İletilen Talepler
                </Typography.Title>
                {/* for Search */}
                <Space direction="horizontal" align="center" style={{ width: '100%', justifyContent: 'center' }}>
                    <Input.Search
                        placeholder="Hasta İsmi veya T.C. No. ile Arama Yapabilirsiniz."
                        defaultValue={this.state.search}
                        onSearch={this.Search}
                        allowClear={true}
                        enterButton
                        style={{
                            width: 400, 
                            marginBottom: '2vh'
                        }}
                        loading={this.state.reqListLoading}
                    />
                </Space>
                {/* for Type Filter and Count */}
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={10} md={14} lg={14} xl={14} xxl={6}>
                        <Dropdown
                            overlay={
                                <Menu selectable={true} selectedKeys={this.state.type ? [this.state.type] : ['Tümü']}>
                                    {this.state.reqTypes.map(type =>
                                        <Menu.Item key={type} onClick={(e) => { this.setType(e, type) }}>
                                            {type}
                                        </Menu.Item>
                                    )}
                                </Menu>}
                            placement="bottomCenter"
                            disabled={this.state.reqListLoading}
                            arrow
                        >
                            <Button style={{ backgroundColor: '#FFF2E8', color: '#d4380d', borderColor: '#ffbb96' }}>Talep Tipi: {this.state.type ? this.state.type : 'Tümü'}<DownOutlined style={{ fontSize: '80%' }} /></Button>
                        </Dropdown>
                    </Col>
                    <Col xs={0} sm={2} md={2} lg={2} xl={2} xxl={14}></Col>
                    {this.state.requests.length > 0 &&
                        <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={4}>
                            <Typography.Title level={5} style={{ textAlign: 'end' }}>
                                <b>{this.state.requests.length}&nbsp;/&nbsp;{this.state.totalCount}</b> Adet İstek Listelendi
                            </Typography.Title>
                        </Col>
                    }
                </Row>
                {/* for Reqs */}
                <List
                    key={"Requests"}
                    className="reqsListItems"
                    loading={this.state.reqListLoading}
                /* loadMore={} */
                >
                    {this.state.requests.length > 0 &&
                        this.state.requests.map(req => (
                            <List.Item
                                key={req._id}
                                style={req.AktifMi ? ((req.TalepTipi === 'Doktor Ata' && DoktorAta) || (req.TalepTipi === 'Doktor Sil' && DoktorSil)) : DisableReq}
                            >
                                <Col xs={24} sm={6} md={6} lg={8} xl={5} xxl={3}>
                                    <Typography.Title level={4}>{req.TalepTipi}</Typography.Title>
                                    <Typography.Title level={5}>{moment(new Date(req?.KayitTarihi)).format('LL')}</Typography.Title>
                                </Col>
                                <Col xs={24} sm={11} md={12} lg={11} xl={15} xxl={18}>
                                    <List.Item.Meta
                                        title={
                                            <Typography>
                                                <Typography.Title level={4}>
                                                    {req.HastaID.AdSoyad}&nbsp;(Yaşı:&nbsp;{moment().diff(req.HastaID.DogumTarihi, 'years', false) + 1})
                                                </Typography.Title>
                                            </Typography>
                                        }
                                        description={
                                            <Typography.Text>
                                                <b>Ebeveyn Adı:</b>&nbsp;
                                                {req.EbeveynID.AdSoyad}
                                            </Typography.Text>
                                        }
                                    />
                                </Col>
                                <Col xs={24} sm={7} md={6} lg={5} xl={4} xxl={3}>
                                    {req.TalepTipi === 'Doktor Ata' &&
                                        <div>
                                            <Popconfirm
                                                title='Talebi "KABUL ETMEK" istediğinize emin misiniz?'
                                                onConfirm={() => this.doctorConfirmChoice(req._id)}
                                                disabled={!req.AktifMi}
                                            >
                                                <Button
                                                    loading={false}
                                                    size="large"
                                                    shape="round"
                                                    disabled={!req.AktifMi}
                                                    hidden={!req.AktifMi}
                                                >
                                                    <CheckOutlined />
                                                </Button>
                                            </Popconfirm>
                                            <Divider type="vertical" />
                                            <Popconfirm
                                                title='Talebi "REDDETMEK" istediğinize emin misiniz?'
                                                onConfirm={() => this.doctorDeclineChoice(req._id)}
                                                disabled={!req.AktifMi}
                                            >
                                                <Button
                                                    loading={false}
                                                    size="large"
                                                    shape="round"
                                                    disabled={!req.AktifMi}
                                                    hidden={!req.AktifMi}
                                                    danger
                                                >
                                                    <CloseOutlined />
                                                </Button>
                                            </Popconfirm>
                                            <Button
                                                size="large"
                                                shape="round"
                                                disabled={!req.AktifMi}
                                                hidden={req.AktifMi}
                                            >
                                                {req.Onay ? <CheckOutlined /> : <CloseOutlined />}
                                                {req.Onay ? 'Kabul Edildi!' : 'Reddedildi!'}
                                            </Button>
                                        </div>
                                    }
                                    {req.TalepTipi === 'Doktor Sil' &&
                                        <div>
                                            <Button
                                                loading={false}
                                                size="large"
                                                shape="round"
                                                disabled={!req.AktifMi}
                                                onClick={() => this.markAsRead(req._id)}
                                            >
                                                <CheckOutlined />Okundu İşaretle
                                            </Button>
                                        </div>
                                    }
                                </Col>
                            </List.Item>
                        ))
                    }
                </List>
                {/* for Pagination */}
                <Space direction="horizontal" align="end" style={{ width: '100%', justifyContent: 'right' }}>
                    <Pagination
                        hideOnSinglePage={true}
                        current={this.state.page}
                        total={this.state.totalCount}
                        pageSizeOptions={[10, 20, 50]}
                        showSizeChanger={true}
                        onChange={(page, limit) => this.onChangePage(page, limit)}
                        style={{ marginTop: '16px' }}
                    />
                </Space>
            </div >
        )
    }
}

const mapState = (state) => ({
});

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(doctorReqs);