import React, { Component } from "react";

/* Components */
import EbeveynRegister from "../parent.components/register.component"
import DoktorRegister from "../doctor.components/register.component"

/* Ant Design */
import { Tabs } from 'antd';

export default class registerSelection extends Component {
    render() {
        return (
            <div>
                <Tabs defaultActiveKey="1" size={"large"} centered>
                    <Tabs.TabPane tab="Ebeveyn Kayıt" key="1">
                        <EbeveynRegister history={this.props.history} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Doktor Kayıt" key="2">
                        <DoktorRegister history={this.props.history} />
                    </Tabs.TabPane>
                </Tabs>
            </div>
        );
    }
}