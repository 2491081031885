import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';
import Highlighter from "react-highlight-words";

/* for "axios" request cancelation */
import axios from 'axios';

/* Helpers */
// import { history } from '../../../../helpers/history';
import 'moment/locale/tr';

/* Services */
import DoctorService from "../../../../services/doctor.service";

/* Ant Design */
import { Button, Input, Typography, Form, Spin, Tag, AutoComplete } from 'antd';

/* for "axios" request cancelation check variable */
let source;
let clearTimer;


const AddDiagnose = (props) => {
    const [patient, setPatient] = useState();
    const [loadingPatient, setLoadingPatient] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const [acValue, setACValue] = useState();
    const [acOptions, setACOptions] = useState([]);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [searchText, setSearchText] = useState();
    const [selectedSearchData, setSelectedSearchData] = useState([]);
    const [loadingDiagnose, setLoadingDiagnose] = useState(false);

    let { patientId } = useParams();

    useEffect(() => {
        source = axios.CancelToken.source();

        setLoadingPatient(true);
        getPatient(patientId, source.token);

        return () => {
            if (source) {
                source.cancel();
            }
            clearTimer && clearTimeout(clearTimer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPatient = (patientId, cancelToken) => {
        if (!patientId) {
            toast.error({ message: "Hasta Bulunamadı!" });
        }
        setLoadingPatient(true);
        DoctorService.getPatient(patientId, cancelToken)
            .then(
                res => {
                    setPatient(res.data);
                    setLoadingPatient(false);
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (resMessage !== "Cancel") {
                        toast.error(resMessage);
                        setLoadingPatient(false);
                    }
                }
            )
    }

    /* for AutoComplete */
    const onSearch = (searchText) => {
        setSearchText(searchText);
        if (searchText.length >= 3) {
            setLoadingSearch(true);

            searchText = searchText?.replace(/^i/gi, 'İ');
            searchText = searchText?.replace(/\si/gi, ' İ');
            searchText = searchText?.replace(" ", "+");

            DoctorService.getFilteredDiagnosis(searchText, source.token)
                .then(
                    res => {
                        if (res.data.length >= 1) {
                            let modifyData = res.data.map(diagnose => {
                                return {
                                    label:
                                        <p>
                                            <b>İsim:&nbsp;</b>
                                            <Highlighter
                                                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                                                searchWords={[searchText.replace('+', ' ')]}
                                                autoEscape
                                                textToHighlight={diagnose.Isim ? diagnose.Isim.toString() : ''}
                                            />&nbsp;-&nbsp;
                                            <b>Kod:&nbsp;</b>
                                            <Highlighter
                                                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                                                searchWords={[searchText.replace('+', ' ')]}
                                                autoEscape
                                                textToHighlight={diagnose.Kod ? diagnose.Kod.toString() : ''}
                                            />
                                        </p>,
                                    value: diagnose._id,
                                    isim: diagnose.Isim
                                }
                            })
                            setACOptions(modifyData);
                            setLoadingSearch(false);
                        }
                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        if ((resMessage !== 'Teşhis Bulunamadı!') && (resMessage !== 'Cancel')) {
                            toast.error(resMessage);
                        }
                        setACOptions([]);
                        setLoadingSearch(false);
                    }
                )
        }
        else {
            setACOptions([]);
            setLoadingSearch(false);
        }
    };

    /* for AutoComplete */
    const onSelect = (option) => {
        setLoadingDiagnose(true);

        getDiagnose(option.value, source.token);
    };

    /* for AutoComplete */
    const onChange = (option) => {
        setACValue(option);
    };

    const deleteTag = (removedTag) => {
        const tags = selectedSearchData.filter(tag => tag._id !== removedTag._id);
        setSelectedSearchData(tags);
    }

    const getDiagnose = (diagnoseId, cancelToken) => {
        DoctorService.getDiagnose(diagnoseId, cancelToken)
            .then(
                res => {
                    if (selectedSearchData.length > 0) {
                        if (selectedSearchData.some(data => data._id === res.data._id)) {
                            toast.warning("Aynı Teşhis Zaten Seçildi!", { autoClose: 2000 });
                        } else {
                            setSelectedSearchData(oldData => [...oldData, res.data]);
                        }
                    } else {
                        let arr = [];
                        arr.push(res.data);
                        setSelectedSearchData(arr);
                    }
                    setACOptions([]);
                    setLoadingDiagnose(false);
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (resMessage !== 'Cancel') {
                        toast.error(resMessage);
                    }
                    setLoadingDiagnose(false);
                }
            )
    }

    const onFinish = (values) => {
        setLoadingSubmit(true);

        if (selectedSearchData.length === 0) {
            toast.warning("Lütfen en az 1 adet Teşhis Seçiniz!", { autoClose: 2000 });
            setLoadingSubmit(false);
        } else if (selectedSearchData.length > 0) {
            values.Teshisler = selectedSearchData;

            DoctorService.createDiagnose(patientId, values)
                .then(
                    (res) => {
                        toast.success(res.data.message, { autoClose: 2000 });
                        clearTimer = setTimeout(() => {
                            props.history.push("/doctor/patients/actions/my-patients/" + patientId + "/diagnosis");
                        }, 2000);
                    },
                    (error) => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        if (resMessage !== 'Cancel') {
                            toast.error(resMessage, { autoClose: 3000 });
                            setLoadingSubmit(false);
                        }
                    }
                )
        }
    }

    return (
        <div>
            <Typography.Title style={{ textAlign: 'center' }}>
                {patient && '"' + patient.AdSoyad + '" için '}Teşhis Ekle
            </Typography.Title>
            {!loadingPatient ?
                <Form
                    name="AddDiagnose"
                    onFinish={onFinish}
                    labelCol={{
                        xs: { offset: 0 },
                        sm: { offset: 0, span: 6 },
                        md: { offset: 1, span: 6 },
                        lg: { offset: 4, span: 4 },
                    }}
                    wrapperCol={{
                        xs: { offset: 0 },
                        sm: { offset: 0, span: 12 },
                        md: { offset: 0, span: 10 },
                        lg: { offset: 0, span: 8 },
                    }}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Teşhis Arama"
                    /* name="Teshisler"
                    rules={[
                        { required: true }
                    ]} */
                    >
                        <AutoComplete
                            mode="tags"
                            value={acValue?.isim}
                            options={acOptions}
                            onSelect={(val, option) => onSelect(option)}
                            onSearch={debounce(onSearch, 300)}
                            onChange={(val, option) => onChange(option)}
                            notFoundContent={
                                loadingSearch ?
                                    <Typography.Text style={{ color: '#1890ff' }}>Aranıyor!</Typography.Text>
                                    :
                                    (acValue && searchText?.length >= 3 && <Typography.Text style={{ color: '#f5222d' }}><b>Teşhis Bulunamadı!</b></Typography.Text>)
                            }
                            placeholder="Teşhis İsmi Giriniz..."
                            size='large'
                            loading={loadingSearch}
                            defaultActiveFirstOption={true}
                            autoFocus={true}
                            allowClear
                        />
                    </Form.Item>
                    {selectedSearchData.length > 0 &&
                        <Form.Item
                            label="Seçilen Teşhisler"
                        >
                            {selectedSearchData.map(diagnose => (
                                <Tag
                                    key={diagnose._id}
                                    closable
                                    onClose={() => deleteTag(diagnose)}
                                >
                                    {diagnose.Isim}
                                </Tag>
                            ))}
                        </Form.Item>
                    }
                    {/* TeshisAciklama */}
                    <Form.Item
                        label="Teşhis Açıklaması"
                        name="Aciklama"
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen Teşhis Açıklaması Giriniz!',
                                min: 4,
                                max: 1024,
                            },
                        ]}
                    >
                        <Input.TextArea placeholder="Teşhis Açıklaması Giriniz!" maxLength={1024}></Input.TextArea>
                    </Form.Item>

                    {/* Submit */}
                    <Form.Item
                        wrapperCol={{
                            xs: { offset: 0 },
                            sm: { offset: 6, span: 12 },
                            md: { offset: 7, span: 10 },
                            lg: { offset: 8, span: 8 },
                        }}
                    >
                        <Button type="primary" htmlType="submit" block loading={loadingSubmit}>
                            Teşhisi Kaydet!
                        </Button>
                    </Form.Item>
                </Form>
                :
                <div style={{ textAlign: 'center' }}>
                    <Spin />
                </div>
            }
        </div>
    )
}

const mapState = (state) => ({
});

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(AddDiagnose);