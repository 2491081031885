import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

/* Components */
import GoogleRecaptcha from '../common.components/recaptcha';

/* Helpers */
import { history } from '../../helpers/history';

/* Services */
import AuthService from "../../services/auth.service";

/* Ant Design */
import { Form, Input, Button } from 'antd';

class parentLogin extends Component {
    constructor(props) {
        super(props);
        this.onFinish = this.onFinish.bind(this);

        //for Recaptcha
        this.changeDisableSubmitButton = this.changeDisableSubmitButton.bind(this);
        this.changeResetRecaptcha = this.changeResetRecaptcha.bind(this);

        //for Recaptcha
        this.state = {
            disableSubmitButton: true,
            resetRecaptcha: false
        };

        history.listen((location) => { });
    }

    onFinish(values) {
        this.props.parentDispatch.setLoading(true);

        AuthService.parentLogin(values.TCNo, values.Sifre).then(
            () => {
                this.props.parentDispatch.setLoading(false);
                this.props.history.push("/parent");
                window.location.reload();
            },
            error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(error.response.data.message);
                toast.error(resMessage);
                this.props.parentDispatch.setLoading(false);
                //for Recaptcha
                this.setState({ resetRecaptcha: true });
            }
        );
    }

    //for Recaptcha
    changeDisableSubmitButton(value) {
        this.setState({ disableSubmitButton: value });
    }

    //for Recaptcha
    changeResetRecaptcha(value) {
        this.setState({ resetRecaptcha: value });
    }

    render() {
        return (
            <div>
                <Form
                    name="parentLogin"
                    onFinish={this.onFinish}
                    labelCol={{
                        xs: { offset: 0 },
                        sm: { offset: 0, span: 6 },
                        md: { offset: 1, span: 6 },
                        lg: { offset: 4, span: 4 },
                    }}
                    wrapperCol={{
                        xs: { offset: 0 },
                        sm: { offset: 0, span: 12 },
                        md: { offset: 0, span: 10 },
                        lg: { offset: 0, span: 8 },
                    }}
                    autoComplete="off"
                >
                    {/* TCNo */}
                    <Form.Item
                        label="T.C. Numarası"
                        name="TCNo"
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen T.C. Kimlik Numaranızı Giriniz!',
                                min: 11,
                                max: 11,
                            },
                        ]}
                    >
                        <Input placeholder="Lütfen T.C. Kimlik Numaranızı Giriniz!" maxLength={11} autoFocus={true} />
                    </Form.Item>

                    {/* Sifre */}
                    <Form.Item
                        label="Şifre"
                        name="Sifre"
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen Şifrenizi Giriniz!',
                                min: 8,
                            },
                        ]}
                    >
                        <Input.Password placeholder="Lütfen Şifrenizi Giriniz!" maxLength={16} />
                    </Form.Item>

                    {/* Şifremi Unuttum */}
                    <Form.Item
                        wrapperCol={{
                            xs: { offset: 0 },
                            sm: { offset: 6, span: 12 },
                            md: { offset: 7, span: 10 },
                            lg: { offset: 8, span: 8 },
                        }}
                    >
                        {/* for Recaptcha */}
                        <GoogleRecaptcha
                            changeResetRecaptcha={this.changeResetRecaptcha}
                            changeDisableSubmitButton={this.changeDisableSubmitButton}
                            reset={this.state.resetRecaptcha}
                        />

                        <Link to={"parent/forgot-password"} style={{ float: 'right' }}>
                            Şifremi Unuttum
                        </Link>
                    </Form.Item>

                    {/* Submit */}
                    <Form.Item
                        wrapperCol={{
                            xs: { offset: 0 },
                            sm: { offset: 6, span: 12 },
                            md: { offset: 7, span: 10 },
                            lg: { offset: 8, span: 8 },
                        }}
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                            loading={this.props.parent.loading}
                            disabled={this.state.disableSubmitButton}
                        >
                            Giriş
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

const mapState = (state) => ({
    parent: state.parent
});

const mapDispatch = (dispatch) => ({
    parentDispatch: dispatch.parent,
});

export default connect(mapState, mapDispatch)(parentLogin);