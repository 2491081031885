export const parent = {
    state: {
        userParent: {
            TCNo: "",
            Sifre: "",
            AdSoyad: null,
            Cinsiyet: null,
            DogumTarihi: null,
            Eposta: null,
            Adres: null,
            Telefon: null,
        },
        accessToken: "",
        loading: false
    },
    reducers: {
        setTCNo(state, TCNo) {
            state.userParent.TCNo = TCNo;
            return { ...state }
        },
        setSifre(state, Sifre) {
            state.userParent.Sifre = Sifre;
            return { ...state }
        },
        setAdSoyad(state, AdSoyad) {
            state.userParent.AdSoyad = AdSoyad;
            return { ...state }
        },
        setCinsiyet(state, Cinsiyet) {
            state.userParent.Cinsiyet = Cinsiyet;
            return { ...state }
        },
        setDogumTarihi(state, DogumTarihi) {
            state.userParent.DogumTarihi = DogumTarihi;
            return { ...state }
        },
        setEposta(state, Eposta) {
            state.userParent.Eposta = Eposta;
            return { ...state }
        },
        setAdres(state, Adres) {
            state.userParent.Adres = Adres;
            return { ...state }
        },
        setTelefon(state, Telefon) {
            state.userParent.Telefon = Telefon;
            return { ...state }
        },
        setLoading(state, loading) {
            return {
                ...state,
                loading: loading
            }
        },
        setCurrentParent(state, payload) {
            state.accessToken = payload.accessToken;
            delete payload.accessToken;
            state.userParent = payload;
            return { ...state }
        },
        clearParent(state) {
            return {
                ...state,
                userParent: {
                    TCNo: "",
                    Sifre: "",
                    AdSoyad: null,
                    Cinsiyet: null,
                    DogumTarihi: null,
                    Eposta: null,
                    Adres: null,
                    Telefon: null,
                },
                accessToken: "",
                loading: false
            }
        }
    },
};