import React, { Component } from "react";
import Iframe from "react-iframe";


class MainframeComponent extends Component {
    render(){
        return(
            <Iframe
                    src="/gozcuWebSite/test.html"
                    width="100%"
                    height="100%"
                    overflow="hidden"
                    display="block"
                    frameBorder="0"
                    allowFullScreen
                    position="absolute"
                    />
        )
    }
}

export default MainframeComponent;