import { init } from "@rematch/core";

/* Common Models */
import { count } from './models/common.models/count';

/* Parent's Models */
import { parent } from './models/parent.models/parent';
import { patient } from './models/parent.models/patient';
import { prescription } from './models/parent.models/prescription';
import { game } from './models/parent.models/game';

/* Doctor's Models */
import { doctor } from './models/doctor.models/doctor';
import { prescriptionDoctor } from './models/doctor.models/prescription';


const store = init({
    models: {
        count,
        doctor,
        parent,
        patient,
        prescription,
        game,
        prescriptionDoctor,
    }
});

export default store;