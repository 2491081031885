import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

/* Helpers */
import 'moment/locale/tr';

/* Services */
import AuthService from "../../services/auth.service";

/* Ant Design */
import { Button, Typography, Form, Input } from 'antd';

let clearTimer;

const DoctorForgotPassword = (props) => {
    const [loadingForm, setLoadingForm] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);

    useEffect(() => {
        return () => {
            clearTimeout(clearTimer);
        }
    }, []);

    const onFinish = (values) => {
        setLoadingForm(true);
        setButtonDisable(true);

        if (values) {
            AuthService.doctorForgotPasswordSendMail(values)
                .then(
                    (response) => {
                        toast.success(response.data.message, { autoClose: 3000 });
                        setLoadingForm(false);
                        clearTimer = setTimeout(() => {
                            props.history.push("/logins");
                        }, 3000);
                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        toast.error(resMessage);
                        setButtonDisable(false);
                        setLoadingForm(false);
                    }
                )
        } else {
            setButtonDisable(false);
            setLoadingForm(false);
        }
    }

    return (
        <div>
            <div style={{ textAlign: 'center', marginBottom: '5vh' }}>
                <Typography.Title level={4}>
                    Şifremi Unuttum
                </Typography.Title>
            </div>
            <Form
                name="doctorForgotPassword"
                labelCol={{
                    xs: { offset: 0 },
                    sm: { offset: 0, span: 6 },
                    md: { offset: 1, span: 6 },
                    lg: { offset: 4, span: 4 },
                }}
                wrapperCol={{
                    xs: { offset: 0 },
                    sm: { offset: 0, span: 12 },
                    md: { offset: 0, span: 10 },
                    lg: { offset: 0, span: 8 },
                }}
                onFinish={onFinish}
                autoComplete="off"
            >
                {/* Old Password */}
                <Form.Item
                    label="E-Posta"
                    name="Eposta"
                    rules={[
                        {
                            required: true,
                            type: "email",
                            message: 'Lütfen Kayıtlı Hesabınıza ait E-Posta Adresini Giriniz!'
                        },
                    ]}
                >
                    <Input
                        type={"email"}
                        placeholder="Lütfen Kayıtlı Hesabınıza ait E-Posta Adresini Giriniz!"
                    />
                </Form.Item>

                {/* Submit */}
                <Form.Item
                    wrapperCol={{
                        xs: { offset: 0 },
                        sm: { offset: 6, span: 12 },
                        md: { offset: 7, span: 10 },
                        lg: { offset: 8, span: 8 },
                    }}
                >
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                        loading={loadingForm}
                        disabled={buttonDisable}
                    >
                        E-Posta Gönder
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}


const mapState = (state) => ({
});

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(DoctorForgotPassword);