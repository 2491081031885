import React, { Component } from "react";

/* Services */
import AuthService from "../../services/auth.service";

import { Result, Button } from 'antd';

export default class page404 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentParent: false,
            currentDoctor: false
        }
    }

    componentDidMount() {
        if (AuthService.getCurrentParent()) {
            this.setState({ currentParent: true });
        } else if (AuthService.getCurrentDoctor()) {
            this.setState({ currentDoctor: true });
        }
    }

    render() {
        const { currentDoctor, currentParent } = this.state;
        return (
            <Result
                status="404"
                title="404"
                subTitle="Üzgünüm, böyle bir sayfa bulunmamaktadır."
                extra={
                    (
                        !currentParent && !currentDoctor &&
                        <Button type="primary" onClick={() => this.props.history.push('/')}>Anasayfaya Dön!</Button>
                    )
                    ||
                    (
                        currentParent && !currentDoctor &&
                        <Button type="primary" onClick={() => this.props.history.push('/parent')}>Anasayfaya Dön!</Button>
                    )
                    ||
                    (
                        !currentParent && currentDoctor &&
                        <Button type="primary" onClick={() => this.props.history.push('/doctor')}>Anasayfaya Dön!</Button>
                    )
                }
            />
        );
    }
}