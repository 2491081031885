import React, { Component } from "react";
import { connect } from 'react-redux';

/* Helpers */
/* import { history } from '../../../helpers/history';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR'; */

/* Services */
import AuthService from "../../../../services/auth.service";

/* Ant Design */
import { Typography } from 'antd';
import { CloseSquareOutlined } from '@ant-design/icons';

class PatientStoreEyeTrackers extends Component {
    componentDidMount() {
    }

    render() {
        return (
            <div>
                <p>Ebeveyn: {AuthService.getCurrentParent()._id}</p>
                <Typography.Title level={3}>Mağaza: Göz Takip Cihazları Yapılacaklar</Typography.Title>
                <Typography.Text><CloseSquareOutlined /> Ebeveynin sahip olduğu oyunlar listelenmeyecek</Typography.Text>
                <br />
                <Typography.Text><CloseSquareOutlined /> Oyunlar Card'lar halinde listelenecek</Typography.Text>
                <br />
                <Typography.Text><CloseSquareOutlined /> Card üzerinde, Oyun Fotoğrafı, İsim, Kategori, Egzersiz Tipi bulunacak</Typography.Text>
                <br />
                <Typography.Text><CloseSquareOutlined /> Card  aksiyonu olarak "Satın Al" butonu bulunacak</Typography.Text>
                <br />
                <Typography.Text><CloseSquareOutlined /> Card  aksiyonu olarak "Detay Görüntüle" butonu ile ayrı bir sayfada oyuna ait tüm detaylar(Açıklama dahil) gösterilecek</Typography.Text>
            </div>
        )
    }
}

const mapState = (state) => ({
    parent: state.parent,
    // eyetracker: state.eyetracker,
});

const mapDispatch = (dispatch) => ({
    parentDispatch: dispatch.parent,
    // eyetrackerDispatch: dispatch.eyetracker,
});

export default connect(mapState, mapDispatch)(PatientStoreEyeTrackers);