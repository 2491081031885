import React, { Component } from "react";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';

/* Components */
import GoogleRecaptcha from '../common.components/recaptcha';

/* Helpers */
import { history } from '../../helpers/history';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';

/* Services */
import AuthService from "../../services/auth.service";

/* Ant Design */
import { Form, Input, Button, Select, DatePicker } from 'antd';

class parentLogin extends Component {
    constructor(props) {
        super(props);
        this.changeDisableSubmitButton = this.changeDisableSubmitButton.bind(this);
        this.disabledDate = this.disabledDate.bind(this);
        this.onFinish = this.onFinish.bind(this);

        this.state = {
            disableSubmitButton: true,
            loadingForm: false
        };

        history.listen((location) => { });
    }

    componentDidMount() {
        this.props.parentDispatch.clearParent();
    }

    changeDisableSubmitButton(value) {
        this.setState({ disableSubmitButton: value });
    }

    disabledDate(current) {
        return current && current > moment().endOf('day');
    }

    onFinish(value) {
        this.setState({ loadingForm: true });

        if (value.Sifre === value.SifreTekrar) {
            delete value.SifreTekrar;

            value.DogumTarihi = value.DogumTarihi.toISOString();

            AuthService.parentRegister(value)
                .then(
                    (res) => {
                        toast.success(res.data.message, { autoClose: 5000 });
                        this.clearTimer = setTimeout(() => {
                            this.props.history.push("/");
                        }, 5000);
                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        toast.error(resMessage);
                        this.setState({ loadingForm: false });
                    }
                )
        } else {
            toast.error("Şifreler Eşleşmiyor!");
            this.setState({ loadingForm: false });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.clearTimer);
    }

    render() {
        return (
            <div>
                <Form
                    name="parentRegister"
                    onFinish={this.onFinish}
                    labelCol={{
                        xs: { offset: 0 },
                        sm: { offset: 0, span: 6 },
                        md: { offset: 1, span: 6 },
                        lg: { offset: 4, span: 4 },
                    }}
                    wrapperCol={{
                        xs: { offset: 0 },
                        sm: { offset: 0, span: 12 },
                        md: { offset: 0, span: 10 },
                        lg: { offset: 0, span: 8 },
                    }}
                    autoComplete="off"
                >
                    {/* TCNo */}
                    <Form.Item
                        label="T.C. Numarası"
                        name="TCNo"
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen T.C. Kimlik Numaranızı Giriniz!',
                                min: 11,
                                max: 11,
                            },
                            {
                                pattern: /^(?:\d*)$/,
                                message: "Sadece Rakam Girilmelidir!",
                            },
                            {
                                pattern: /^[\d]{11,11}$/,
                                message: "11 Rakam Girilmelidir!",
                            },
                        ]}
                    >
                        <Input placeholder="Lütfen T.C. Kimlik Numaranızı Giriniz!" maxLength={11} />
                    </Form.Item>

                    {/* Sifre */}
                    <Form.Item
                        label="Şifre"
                        name="Sifre"
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen Şifrenizi Giriniz!',
                                min: 8,
                                max: 16,
                            },
                        ]}
                    >
                        <Input.Password placeholder="Lütfen Şifrenizi Giriniz!" maxLength={16} />
                    </Form.Item>

                    {/* Sifre Tekrar */}
                    <Form.Item
                        label="Şifre Tekrar"
                        name="SifreTekrar"
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen Şifrenizi Tekrar Giriniz!',
                                min: 8,
                                max: 16,
                            },
                        ]}
                    >
                        <Input.Password placeholder="Lütfen Şifrenizi Tekrar Giriniz!" maxLength={16} />
                    </Form.Item>

                    {/* AdSoyad */}
                    <Form.Item
                        label="Ad ve Soyad"
                        name="AdSoyad"
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen Adınızı ve Soyadınızı Giriniz!',
                                min: 4,
                                max: 128,
                            },
                        ]}
                    >
                        <Input placeholder="Lütfen Adınızı ve Soyadınızı Giriniz!" maxLength={128} />
                    </Form.Item>

                    {/* Cinsiyet */}
                    <Form.Item
                        label="Cinsiyet"
                        name="Cinsiyet"
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen Cinsiyet Seçiniz!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Lütfen Cinsiyetinizi Seçiniz!"
                            allowClear
                        >
                            <Select.Option value="Kadın">Kadın</Select.Option>
                            <Select.Option value="Erkek">Erkek</Select.Option>
                            <Select.Option value="Belirtmek İstemiyorum">Belirtmek İstemiyorum</Select.Option>
                        </Select>
                    </Form.Item>

                    {/* DogumTarihi */}
                    <Form.Item
                        label="Doğum Tarihi"
                        name="DogumTarihi"
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen Doğum Tarihinizi Seçiniz!',
                            },
                        ]}
                    >
                        <DatePicker locale={locale} format={'DD/MM/YYYY'} disabledDate={this.disabledDate} />
                    </Form.Item>

                    {/* Eposta */}
                    <Form.Item
                        label="E-Posta"
                        name="Eposta"
                        rules={[
                            {
                                required: true,
                                type: "email",
                                message: 'Lütfen E-Postanızı Yazınız!',
                            },
                        ]}
                    >
                        <Input placeholder="Lütfen E-Postanızı Yazınız!" />
                    </Form.Item>

                    {/* Adres */}
                    <Form.Item
                        label="Adres"
                        name="Adres"
                        rules={[
                            {
                                required: true,
                                message: 'Lütfen Adresinizi Giriniz!',
                                min: 8,
                                max: 256
                            },
                        ]}
                    >
                        <Input placeholder="Lütfen Adresinizi Giriniz!" />
                    </Form.Item>

                    {/* Telefon */}
                    <Form.Item
                        label="Telefon Numarası"
                        name="Telefon"
                        rules={[
                            {
                                required: true,
                                message: '',
                            },
                        ]}
                    >
                        <Input.Group compact>
                            <Input style={{ width: '20%' }} defaultValue="+90" disabled />
                            <Form.Item
                                name="Telefon"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Lütfen Telefon Numaranızı Giriniz!',
                                    },
                                    {
                                        pattern: /^(?:\d*)$/,
                                        message: "Sadece Rakam Girilmelidir!",
                                    },
                                    {
                                        pattern: /^[\d]{10,10}$/,
                                        message: "10 Rakam Girilmelidir!",
                                    },
                                ]}
                            >
                                <Input placeholder="5554443322" maxLength={10} style={{ width: '100%' }} />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>

                    {/* Google Recaptcha v2 */}
                    <Form.Item
                        wrapperCol={{
                            xs: { offset: 0 },
                            sm: { offset: 6, span: 12 },
                            md: { offset: 7, span: 10 },
                            lg: { offset: 8, span: 8 },
                        }}
                    >
                        <GoogleRecaptcha changeDisableSubmitButton={this.changeDisableSubmitButton} />
                    </Form.Item>

                    {/* Submit */}
                    <Form.Item
                        wrapperCol={{
                            xs: { offset: 0 },
                            sm: { offset: 6, span: 12 },
                            md: { offset: 7, span: 10 },
                            lg: { offset: 8, span: 8 },
                        }}
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                            loading={this.state.loadingForm}
                            disabled={this.state.disableSubmitButton}
                        >
                            Kayıt Ol!
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

const mapState = (state) => ({
    parent: state.parent
});

const mapDispatch = (dispatch) => ({
    parentDispatch: dispatch.parent,
});

export default connect(mapState, mapDispatch)(parentLogin);